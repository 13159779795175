export enum V2AttemptStatus {
  CREDITED = 20235,
  PASSED = 20245,
  RUNNING = 20215,
  FAILED = 20275,
  UNKNOWN = 0,
}


const statusMap: { [key: number]: V2AttemptStatus } = {
  20235: V2AttemptStatus.CREDITED,
  20245: V2AttemptStatus.PASSED,
  20270: V2AttemptStatus.PASSED,
  20215: V2AttemptStatus.RUNNING,
  20275: V2AttemptStatus.FAILED,
};

export function fromStatusId(statusId: number): V2AttemptStatus {
  return statusMap[statusId] || V2AttemptStatus.UNKNOWN;
}

