<div class="navigation-title" #filterNav>
    <div class="pointer" (click)="logoOnClick()">
      <img class="logo-long" id="fhnw-logo-long" alt="fhnw-logo" src="assets/fhnw-logo-long.svg"/>
      <img class="logo-short" id="fhnw-logo-short" alt="fhnw-logo" src="assets/fhnw-logo-short.svg"/>
    </div>
    <div class="navigation-marks">
      <div *ngIf="authService.getRole() === 'admin'"
           class="navigation-point pointer"
           [ngClass]="getCurrentNavigation().includes('admin') ? 'currentNavigation' : ''"
           [routerLink]="['/admin']">Admin
      </div>
      <div class="pointer" style="display: inline-flex" (click)="toggleUserSettingDropdown($event)">
        <span class="user-name">{{this.getStudentVorname() + " " + this.getStudentNachname()}}</span>
        <img alt="dropdown-closed" src="assets/icons/dropdown/closed.svg"/>
      </div>
      <app-logout [isExpanded]="isUserDropdownExpanded"></app-logout>
    </div>
  </div>
