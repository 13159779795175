import {Component} from '@angular/core';
import {ThemeService} from '../services/theme.service';

@Component({
  selector: 'app-arrow',
  templateUrl: './arrow.component.html',
  styleUrls: ['./arrow.component.scss']
})
export class ArrowComponent {

  constructor(
    public themeService: ThemeService
  ) {
  }
}
