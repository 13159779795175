import { Component, OnInit } from '@angular/core';
import { SnackbarService } from '../services/snackbar.service';

@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss']
})
export class SnackbarComponent implements OnInit {

  constructor(
    public snackbarService: SnackbarService
  ) {
  }

  ngOnInit(): void {
  }

  getSnackbarLevelClass(): string {
    switch (this.snackbarService.getSnackbarLevel()) {
      case SnackbarService.MsgLevel.ERROR:
        return 'snackbar-error';
      case SnackbarService.MsgLevel.INFO:
        return 'snackbar-info';
      case SnackbarService.MsgLevel.HINT:
        return 'snackbar-hint';
    }
  }

  closeSnackbar(): void {
    this.snackbarService.close();
  }
}
