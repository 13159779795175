export interface Theme {
  name: string;
  properties: { [p: string]: string };
}

export const light: Theme = {
  name: 'light',
  properties: {
    '--logo-color': 'invert(0%)',
    '--primary-color': '#192231',
    '--secondary-color': '#717D92',
    '--tertiary-color': '#717D92',
    '--accent-blue': '#0A8BEC',
    '--accent-green': '#65b983',
    '--accent-yellow': '#F9C94D',
    '--accent-grey': '#717D92',
    '--passed-color': '#BAF3BB',
    '--failed-color': '#FFE096',
    '--failed-twice-color': '#FFAAAA',
    '--primary-background-color': '#FFFFFF',
    '--primary-background-color-hover': '#FFFFFF80',
    '--primary-background-color-vertiefungen': '#FAFAFB',
    '--secondary-background-color': '#FAFAFB',
    '--border-color': '#BFC6D4',
    '--modul-color': '#FFFFFF',
    '--modul-shadow': 'none',
    '--modul-border-color': '#BFC6D4',
    '--shadow-color': 'none',
    '--dropdown-color': '#E8ECEF',
    '--scrollbar-color': 'rgba(0, 0, 0, 0.5)',
    '--slider-color-inactive': '#BFC6D4',
    '--slider-color-active': '#FFFFFF',
    '--running-secondary-color': '#FFFFFF',
    '--toggle-background-color': '#ededed'
  }
};

export const dark: Theme = {
  name: 'dark',
  properties: {
    '--logo-color': 'invert(100%)',
    '--primary-color': '#FFFFFF',
    '--secondary-color': '#C6D4F0',
    '--tertiary-color': '#EDEDED',
    '--accent-blue': '#0A8BEC',
    '--accent-green': '#BAF3BB',
    '--accent-yellow': '#FFE096',
    '--accent-grey': '#717D92',
    '--passed-color': '#BAF3BB',
    '--failed-color': '#FFE096',
    '--failed-twice-color': '#FFAAAA',
    '--primary-background-color': '#192231',
    '--primary-background-color-hover': '#19223180',
    '--primary-background-color-vertiefungen': '#192231',
    '--secondary-background-color': '#243350',
    '--border-color': '#667084',
    '--modul-color': '#1F2A3F',
    '--modul-shadow': '7px 7px 12px 0 #0D1119',
    '--modul-border-color': 'none',
    '--shadow-color': '#0D1119',
    '--dropdown-color': '#E8ECEF',
    '--scrollbar-color': 'rgba(255, 255, 255, 0.7)',
    '--slider-color-inactive': '#FFFFFF',
    '--slider-color-active': '#FFFFFF',
    '--running-secondary-color': '#FFFFFF',
    '--toggle-background-color': '#243350'
  }
};
