import {
    AfterContentInit,
    Component,
    ElementRef,
    OnDestroy,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { Subject } from "rxjs";
import { IModul } from '../../models/Modul';
import { Modulstatus } from "../../models/Modulstatus";
import { FilterService } from "../../services/filter.service";
import { LocalStorageService } from "../../services/local-storage.service";
import { ModulService } from "../../services/modul.service";
import { NavigationService } from '../../services/navigation.service';
import { ParseService } from '../../services/parse.service';
import { SidenavService } from '../../services/sidenav.service';
import { ThemeService } from '../../services/theme.service';

declare let mermaid: any;

declare global {
  interface Window {
    callBackFn: any;
  }
}

@Component({
  selector: 'app-modul-tree',
  templateUrl: './modul-tree.component.html',
  styleUrls: ['./modul-tree.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModulTreeComponent implements AfterContentInit, OnDestroy {

  @ViewChild('mermaid', {static: true}) mermaidDiv: ElementRef;
  private readonly modulesWithoutDependencies: IModul[];
  private readonly flattenedModuleTree;
  private readonly moduleAbbreviations;
  private readonly dependencyMap: Map<string, Array<string>>;
  private unselectedGraphDefinition?: string;
  private isModuleSelected = false;
  public isLoading = false;

  private isPassedEnabled = false;
  private isFailedEnabled = false;
  private isRunningEnabled = false;

  private unsubscribeAll: Subject<any> = new Subject();

  constructor(
    private parseService: ParseService,
    private themeService: ThemeService,
    private sidenavService: SidenavService,
    public navigationService: NavigationService,
    private modulService: ModulService,
    private filterService: FilterService,
    private localStorageService: LocalStorageService
  ) {
    this.initializeMermaid();
    this.flattenedModuleTree = this.parseService.getFlattenedModuleTree();
    this.dependencyMap = this.setupDependencyMap(this.flattenedModuleTree);
    this.modulesWithoutDependencies = this.getModulesWithoutDependencies();
  }

  /**
   * Determines the class of a module based on its completion status.
   * @param module
   * @param passedEnabled indicates whether the user wants to mark passed
   *   modules green.
   * @param runningEnabled indicates whether the user wants to mark running
   *   modules blue.
   * @param failedEnabled indicates whether the user wants to mark failed
   *   modules orange or red.
   * @private
   * @pure
   */
  private static getModuleClass(module: IModul,
                                passedEnabled: boolean,
                                runningEnabled: boolean,
                                failedEnabled: boolean): string {
    const isPassed = (moduleA: IModul) => moduleA.attempts.some(a => a.status === Modulstatus.PASSED
                                          || a.status === Modulstatus.CREDITED)
                                          || moduleA.angerechnet === true;
    const isLocked = (moduleA: IModul) => moduleA.attempts.some(a => a.status === Modulstatus.LOCKED);
    const isFailed = (moduleA: IModul) => moduleA.attempts.some(a => a.status === Modulstatus.FAILED);
    const isRunning = (moduleA: IModul) => moduleA.attempts.some(a => a.status === Modulstatus.RUNNING);

    if (isPassed(module) && passedEnabled) {
      return ':::passsed';
    } else if (isRunning(module) && runningEnabled) {
      return ':::running';
    } else if (isLocked(module) && failedEnabled) {
      return ':::locked';
    } else if (isFailed(module) && failedEnabled) {
      return ':::failed';
    }
    return ':::normal';
  }

  /**
   * Returns class definitions for the flowchart nodes for a given theme.
   * @param darkThemeEnabled
   * @private
   * @pure
   */
  private static constructClassDefinitions(darkThemeEnabled: boolean): string {
    let borderColor: string;
    let fillColor: string;
    let textColor: string;
    if (darkThemeEnabled) {
      borderColor = 'none';
      fillColor = '#1F2A3F';
      textColor = 'white';
    } else {
      borderColor = '#BFC6D4';
      fillColor = 'white';
      textColor = '#192231';
    }

    const baseClasses = [
      {name: 'normal', fillColor, textColor, borderColor},
      {name: 'failed', fillColor: '#FFE096', textColor: 'black', borderColor},
      {name: 'locked', fillColor: '#FFAAAA', textColor: 'black', borderColor},
      {name: 'passsed', fillColor: '#BAF3BB', textColor: 'black', borderColor},
      {name: 'running', fillColor: '#0A8BEC', textColor: 'white', borderColor},
    ];

    return baseClasses.map(c => {
      return `classDef ${c.name} stroke: ${c.borderColor}, fill:${c.fillColor}, opacity:1, color:${c.textColor}, padding:30px, height:48px, text-align:center, line-height:24px, y:-24px;`;
    }).join('\n');
  }

  /** Returns the abbreviation of all modules where the name or abbreviation contain {@link searchTerm}. This whole function ignores case.
   * @param searchTerm
   * @private
   */
  private getMatchingModulesByTerm(searchTerm: string): Array<string> {
    class DisplayPair {
      name: string;
      abbr: string;
    }
    searchTerm = searchTerm.toLowerCase();
    return this.flattenedModuleTree
               .map((m: IModul) => ({
                 name: m.bezeichnung,
                 abbr: m.abbreviation
               }))
               .filter((dp: DisplayPair) => dp.name && dp.abbr)
               .map((dp: DisplayPair) => ({
                 name: dp.name.toLowerCase(),
                 abbr: dp.abbr.toLowerCase()
               }))
               .filter((dp: DisplayPair) => dp.name.includes(searchTerm) || dp.abbr.includes(searchTerm))
               .map((dp: DisplayPair) => dp.abbr);
  }

  /**
   * Initializes the Mermaid library with specific configurations.
   * @private
   */
  private initializeMermaid(): void {
    mermaid.initialize({
      theme: 'dark',
      startOnLoad: false,
      maxTextSize: 90000,
      securityLevel: 'loose'
    });
  }

  /**
   * Called after Angular has fully initialized the component's view.
   * - sets up the necessary callbacks
   * - initializes the theme listener
   * - renders the initial graph
   * - establishes a subscription to the module search array
   */
  public ngAfterContentInit(): void {
    // Register callbacks
    this.registerNodeClickCallback();
    this.registerThemeChangeCallback();

    // Render the initial graph based on the graph definition and the container element.
    this.recalculateGraph();

    const handleModulSearchChanged = this.debounce(this.recalculateGraph.bind(this), 100);
    this.modulService.modulSearchArray.subscribe(() => {
      handleModulSearchChanged();
    });

    this.localStorageService.config.subscribe(() => {
      const enabledFilters = this.filterService.getEnabledFilters();
      this.isPassedEnabled = enabledFilters.some(filter => filter[0] === 'Bestanden' && filter[1].on);
      this.isFailedEnabled = enabledFilters.some(filter => filter[0] === 'Fehlgeschlagen' && filter[1].on);
      this.isRunningEnabled = enabledFilters.some(filter => filter[0] === 'Laufend' && filter[1].on);

      this.recalculateGraph();
    });
  }

  /**
   * Lifecycle hook that is called when the component is destroyed by Angular.
   */
  ngOnDestroy(): void {
    this.themeService.clearThemeChangedListeners();
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  /**
   * Registers a debounced click callback function for graph nodes.
   * @private
   */
  private registerNodeClickCallback(): void {
    window.callBackFn = this.debounce(this.handleNodeClick.bind(this), 100);
  }

  /**
   * Registers a callback to recalculate the graph when the theme changes.
   * @private
   */
  private registerThemeChangeCallback(): void {
    this.themeService.addThemeChangedListener(() => this.recalculateGraph());
  }

  /**
   * Handles click outside of node to deselect module.
   * @param e The event details.
   */
  handleClick(e: MouseEvent): void {
    const node = (e.target as SVGElement).nodeName;
    if (this.isModuleSelected && 'svg' === node || 'path' === node) {
      this.sidenavService.setSelectedModule([]);
      this.sidenavService.setIsExpanded(false);
      this.isModuleSelected = false;
    }
  }

  /**
   * Handles the click event on a module node.
   *
   * @param moduleAbbr The abbreviation of the clicked module.
   * @private
   */
  private handleNodeClick(moduleAbbr: string): void {
    this.isModuleSelected = true;
    const foundModule = this.flattenedModuleTree.find((m: IModul) => m.abbreviation === moduleAbbr);
    this.sidenavService.setSelectedModule([foundModule]);
    this.sidenavService.setIsExpanded(true);
  }

  /**
   * Recalculates display of graph and renders the new definition. ONLY to be
   * used after first time!
   * @private
   */

  private recalculateGraph(): void {
    const searchTerm = this.modulService.searchInput;
    let dependencyModules = [];
    if (searchTerm) {
      const modulesMatchedBySearch = this.getMatchingModulesByTerm(searchTerm);
      dependencyModules = this.getDependencyModules(modulesMatchedBySearch);
    }
    setTimeout(() => { // Keep the existing timeout to manage visual caching
      let graphDefinition: string;
      if (!searchTerm && this.unselectedGraphDefinition) {
        graphDefinition = this.unselectedGraphDefinition;
      } else {
        graphDefinition = this.createGraphDefinition(this.flattenedModuleTree, dependencyModules);
      }
      this.renderGraph(graphDefinition, this.mermaidDiv.nativeElement);
    }, 1);
  }

  /**
   * Renders graph definition into given DOM element.
   * @param graphDef String representation of the mermaid code.
   * @param container DOM element that will wrap the SVG element.
   * @private
   */
  private renderGraph(graphDef: string, container: HTMLElement): void {
    mermaid.render('graphDiv', graphDef).then(({svg, bindFunctions}) => {
      container.innerHTML = svg;
      bindFunctions?.(container);
      this.isLoading = false;
      const svgElement = container.querySelector('svg');
      svgElement.style.width = svgElement.style.maxWidth;
    });

  }

  /**
   * Compiles a list of modules that are suggested before attending the module
   * 'moduleAbbr'.
   * @param startingAbbreviations The abbreviation for which the dependency
   *   modules are searched.
   * @returns List of modules that should be visited before
   *   {@link startingAbbreviations}.
   * @private
   */
  private getDependencyModules(startingAbbreviations: Array<string>): Array<string> {
    let finalDeps = [];
    let searchDeps = startingAbbreviations;

    while (searchDeps.length > 0) {
      let nextSearchDeps = [];
      for (const moduleAbbr of searchDeps) {
        if (this.dependencyMap.has(moduleAbbr)) {
          nextSearchDeps = [...nextSearchDeps, ...this.dependencyMap.get(moduleAbbr)];
        }
      }
      finalDeps = [...finalDeps, ...searchDeps];
      searchDeps = nextSearchDeps;
    }

    return finalDeps;
  }

  /**
   * Constructs a map that can be used to get all dependency modules of a
   * module given its abbreviation.
   * @param flattenedModulTree Module tree data structure from Moduleteppich.
   * @returns Map datastructure.
   * @private
   * @pure
   */
  private setupDependencyMap(flattenedModulTree: IModul[]): Map<string, Array<string>> {
    const depMap: Map<string, Array<string>> = new Map();
    flattenedModulTree.forEach(group => {
      group.modules?.forEach(module => {
        if (module.dependingModules?.length > 0) {
          const dependencyModuleAbbreviations = module.dependingModules.map(depModule => depModule.abbreviation);
          depMap.set(module.abbreviation, dependencyModuleAbbreviations);
        } else {
          depMap.set(module.abbreviation, []);
        }
      });
    });

    return depMap;
  }

  /**
   * Rearranges module tree built for Modulteppich to graph definition for
   * mermaid.
   * @param flattenedModulTree Module tree data structure from Modulteppich.
   * @param reducedModuleList List of modules to which the graph should be
   *   reduced.
   * @returns Graph definition for mermaid.
   * @private
   */
  private createGraphDefinition(flattenedModulTree: IModul[], reducedModuleList: Array<string> = []): string {
    const connections: string[] = [];
    const linkStyles: string[] = [];

    let lineId = 0; // Initialize a variable to keep track of line IDs to allow individual styling.
    const linkColor = this.themeService.isDark() ? 'white' : '#BFC6D4';

    flattenedModulTree.forEach(group => {
      group.modules?.forEach(module => {
        if (reducedModuleList.length !== 0 && !reducedModuleList.includes(module.abbreviation)) {
          return;
        }
        const moduleClass = ModulTreeComponent.getModuleClass(module,
                                                              this.isPassedEnabled,
                                                              this.isRunningEnabled,
                                                              this.isFailedEnabled);
        const moduleAbbr = module.abbreviation;

        if (reducedModuleList.length === 1 && reducedModuleList.includes(moduleAbbr)) {
          const connection = `${moduleAbbr}${moduleClass}`;
          connections.push(connection);
        }

        module.dependingModules?.forEach(dependentModule => {
          if (reducedModuleList.length !== 0 && !reducedModuleList.includes(dependentModule.abbreviation)) {
            return;
          }
          const dependentModuleAbbr = dependentModule.abbreviation;
          const dependentModuleClass = ModulTreeComponent.getModuleClass(
            dependentModule,
            this.isPassedEnabled,
            this.isRunningEnabled,
            this.isFailedEnabled);
          const connection =
            `${dependentModuleAbbr}${dependentModuleClass}
            -->
            ${moduleAbbr}${moduleClass}`;
          connections.push(connection);

          const linkStyle = `
          linkStyle ${lineId++} stroke:${linkColor}, stroke-width:2px`;
          linkStyles.push(linkStyle);

          // Additional features like click callbacks can be added here
          connections.push(`click ${dependentModuleAbbr} callBackFn`);
          connections.push(`click ${moduleAbbr} callBackFn`);
        });
      });

    });

    const classDefinitions = ModulTreeComponent.constructClassDefinitions(this.themeService.isDark());
    return `flowchart BT \n${classDefinitions}\n${connections.join('\n')}\n${linkStyles.join('\n')}`;
  }

  /**
   * Compiles list of modules that aren't and don't have dependencies.
   * @private
   */
  private getModulesWithoutDependencies(): IModul[] {
    const modules: IModul[] = [];
    const addModuleIfNoDependencies = (module: IModul) => {
      if (!module.dependingModules || module.dependingModules.length === 0) {
        modules.push(module);
      }
    };

    this.parseService.getModuleTree().filter(group => group.bezeichnung === 'Fachausbildung')
      .flatMap(group => group.modules || [])
      .flatMap(subgroup => subgroup.modules || [])
      .flatMap(subsubGroup => subsubGroup.modules || [])
      .forEach(module => {
        addModuleIfNoDependencies(module.modules?.[0] || module);
      });

    return modules;
  }


  /**
   * Debounces the provided function, ensuring that it is not called more
   * frequently than the specified delay.
   *
   * @param func The function to debounce.
   * @param delay The minimum delay between successive calls, in milliseconds.
   * @returns the function that may be called multiple times within the given
   *   delay, but only execute the code of func once.
   * @private
   */
  private debounce(func: () => void, delay: number): (() => void) {
    let debounceTimeout: number;
    return function(): void {
      const context = this;
      clearTimeout(debounceTimeout);
      // @ts-ignore
      debounceTimeout = setTimeout(() => func.apply(context, arguments), delay);
    };
  }
}
