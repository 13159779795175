<div class="admin-search-container">
  <form [formGroup]="getStudentForm()" (submit)="fillQueryParamsWithStudentForm();disableFilters();">
    <div class="admin-search-container-title">
      <span>Studierende suchen</span>
    </div>
    <div class="admin-search-form">
      <div class="admin-search-name-container">
        <div class="form-box">
          <label class="form-input-name-label" for="nachname">Vorname: </label>
          <div class="form-input">
            <input formControlName="vorname" placeholder="Mark" class="search search-name-input" id="vorname"
                   type="search">
            <app-form-error
              class="error-message"
              [controlName]="getStudentForm().controls['vorname']">
            </app-form-error>
            <img (click)="clearFirstNameInput()"
                 class="clear"
                 src="../../../assets/icons/delete-bin.svg"
                 alt="clear">
          </div>
        </div>
        <div class="form-box">
          <label class="form-input-name-label" for="nachname">Nachname: </label>
          <div class="form-input">
            <input formControlName="nachname" placeholder="Meier" class="search search-name-input" id="nachname"
                   type="search">
            <app-form-error
              class="error-message"
              [controlName]="getStudentForm().controls['nachname']">
            </app-form-error>
            <img (click)="clearLastNameInput()"
                 class="clear"
                 src="../../../assets/icons/delete-bin.svg"
                 alt="clear">
          </div>
        </div>
      </div>
      <form style="width: 100%;" [formGroup]="getDropdownForm()">
        <select id="studienjahrgang" class="studiengang-dropdown" formControlName="studienjahrgang"
                (change)="detectFormChange($event)">
          <option selected [value]="0">Studienjahrgang auswählen (kein)</option>
          <ng-container *ngFor="let studienjahrgang of allStudienjahrgangInfos">
            <option *ngIf="studienjahrgang.bezeichnung"
                    [value]="studienjahrgang.studienjahrgangId">
              <span>{{ parseStudyMode(studienjahrgang).bezeichnung }}</span>
            </option>
          </ng-container>
        </select>
      </form>
      <button type="submit"
              class="search-button"
              [disabled]="!getStudentForm().valid">Suchen</button>
    </div>
  </form>
  <div class="admin-search-filters" >
    <form [formGroup]="filtersForm" [ngClass]="filtersDisabled ? 'disabled-form': ''">
      <app-slider
        [min]="0"
        [max]="60"
        [defaultSliderValue]="filtersForm.get('failedEcts')?.value"
        [label]="'Fehlgeschlagene ECTS: >='"
        (sliderValue)="updateNegativeECTSSliderValue($event)"
      ></app-slider>
      <div class="form-check">
        <input type="checkbox"
               class="form-check-input"
               id="activeStatus"
               formControlName="showOnlyActive"
               (change)="onActiveStudentsChange()">
        <label class="form-check-label" for="activeStatus">Aktiver Status</label>
      </div>

      <div class="form-check">
        <input type="checkbox"
               class="form-check-input"
               id="runningAssessment"
               formControlName="runningAssessment"
               (change)="onRunningAssessmentChange()">
        <label class="form-check-label" for="runningAssessment">Assessment Laufend</label>
      </div>

      <div class="form-check">
        <input type="checkbox"
               class="form-check-input"
               id="passedAssessment"
               formControlName="passedAssessment"
               (change)="onPassedAssessmentChange()">
        <label class="form-check-label" for="passedAssessment">Assessment Bestanden</label>
      </div>
    </form>
  </div>
</div>
