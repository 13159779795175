import {
  ChangeDetectorRef,
  Component,
  ElementRef, Input,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {Subscription} from 'rxjs';
import {IModul} from '../../models/Modul';
import {FilterService} from '../../services/filter.service';
import {NavigationService} from '../../services/navigation.service';
import {SidenavRightService} from '../../services/sidenav-right.service';
import {ThemeService} from '../../services/theme.service';
import {SidenavService} from '../../services/sidenav.service';
import {ModulService} from '../../services/modul.service';
import {ParseService} from '../../services/parse.service';
import {Modulstatus} from '../../models/Modulstatus';
import {Minor, minorModuleMap} from '../../navigation/filter/minors';
import {Studiengang} from '../../models/Studiengaenge';

@Component({
  selector: 'app-sidenav-right',
  templateUrl: './sidenav-right.component.html',
  styleUrls: ['./sidenav-right.component.scss'],
})
export class SidenavRightComponent implements OnInit, OnDestroy {

  @ViewChild('sideNavContent') sideNavContent!: ElementRef;
  @ViewChild('stickyMenu') menuElement!: ElementRef;
  private subscriptions: Subscription[] = [];
  private isContentExpanded = false;
  public groupDetails!: IModul;
  public groupType!: string;
  public typeColor = '#000000';
  public filter = '';
  public isSideNavigationExpanded = false;
  public studiengang = Studiengang;
  public topOffset = 0;
  sortByStatus = ParseService.sortByStatus;

  constructor(
    public sidenavRightService: SidenavRightService,
    public navigationService: NavigationService,
    public sidenavService: SidenavService,
    private changeDetectorRef: ChangeDetectorRef,
    public modulService: ModulService,
    public filterService: FilterService,
    public themeService: ThemeService,
    public parseService: ParseService
  ) {
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      this.sidenavRightService.getSubjectSelectedGroup().subscribe((value: IModul) => this.groupDetails = value),
      this.sidenavRightService.getSubjectSelectedType().subscribe((value: string) => {
        this.groupType = value;
        switch (value) {
          case 'Kontext':
            this.typeColor = '#8e4fd0';
            break;
          case 'Fachergänzung':
            this.typeColor = '#2094ec';
            break;
          case 'Grundlagen':
            this.typeColor = '#00D0CD';
            break;
          case 'Englisch':
            this.typeColor = '#8E4FD0';
            break;
          case 'GSW':
            this.typeColor = '#8E4FD0';
            break;
          default:
            this.typeColor = '#2094EC';
            break;
        }
      }),
      this.navigationService.getCurrentScroll().subscribe((value: number) => {
        this.topOffset = Math.max(61, 141 - value);
        this.changeDetectorRef.detectChanges();
      }),
      this.sidenavRightService.getSubjectGroup().subscribe((value: boolean) => {
        value ? this.openSideNavigation() : this.closeSideNavigation();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }


  stop(event: MouseEvent): void {
    event.stopPropagation();
  }

  private openSideNavigation(): void {
    this.isSideNavigationExpanded = true;
    this.navigationService.closeFilterSettings();
    this.navigationService.closeUserSettings();
    this.filter = this.modulService.searchInput;
    setTimeout(() => (this.isContentExpanded = true), 200);
  }

  private closeSideNavigation(): void {
    this.isSideNavigationExpanded = false;
    this.groupDetails && this.sidenavRightService.clearSelectedGroup();
  }

  public showModuleDetails(event: any, currentModule: IModul): void {
    this.sidenavService.setSelectedModule([currentModule.modules![0]]);
    event.stopPropagation();
    this.navigationService.closePreferences();
  }

  public setSelectedSidenavRightBackgroundColor(modul: IModul): string {
    if (this.sidenavService.getSelectedModules().includes(modul)) {
      return 'rgba(10, 139, 236, 0.1)';
    } else {
      return '';
    }
  }

  showGradeOrCredited(module: IModul): string {
    let val = '-.-';
    if (this.filterService.getShowFilter('Noten')) {
      if (module.modules &&
        module.modules[0] &&
        module.modules![0].attempts &&
        module.modules[0].attempts[0] &&
        module.modules![0].attempts[0].finalGrade) {
        if (module.modules[0].attempts[0].status !== Modulstatus.CREDITED) {
          val = module.modules![0].attempts[0].finalGrade.toString();
        }
      }
    }
    if (this.filterService.getShowFilter('Angerechnet') &&
      module.modules &&
      module.modules[0] &&
      module.modules![0].attempts &&
      module.modules[0].attempts[0] &&
      module.modules![0].attempts[0].status === 'Angerechnet') {
      val = ' angr.';
    }
    return val;
  }

  shouldDisplayMinorModule(module: IModul): boolean {
    const minors = Object.values(Minor);
    const noMinorsFiltered = !minors.some(minor => this.filterService.getShowFilter(minor));
    return minors.some(minor => !!module?.modules?.[0] && this.modulService.shouldDisplayMinor(minor, module.modules[0])) || noMinorsFiltered;
  }

  sortStuff(modules: Array<IModul>): Array<IModul> {
    if (this.filterService.getShowFilter('Web Engineering')) {
      return modules.sort(modul => minorModuleMap.get(Minor.I_WE)!.includes(modul.modules![0].abbreviation!) ? -1 : 1);
    } else if ((this.filterService.getShowFilter('Data Science'))) {
      return modules.sort(modul => minorModuleMap.get(Minor.I_DS)!.includes(modul.modules![0].abbreviation!) ? -1 : 1);
    } else if ((this.filterService.getShowFilter('Spatial Computing'))) {
      return modules.sort(modul => minorModuleMap.get(Minor.I_SC)!.includes(modul.modules![0].abbreviation!) ? -1 : 1);
    } else if ((this.filterService.getShowFilter('System Management'))) {
      return modules.sort(modul => minorModuleMap.get(Minor.I_SM)!.includes(modul.modules![0].abbreviation!) ? -1 : 1);
    } else if ((this.filterService.getShowFilter('TFEC'))) {
      return modules.sort(modul => minorModuleMap.get(Minor.MB_TFEC)!.includes(modul.modules![0].abbreviation!) ? -1 : 1);
    } else if ((this.filterService.getShowFilter('TFEE'))) {
      return modules.sort(modul => minorModuleMap.get(Minor.MB_TFEE)!.includes(modul.modules![0].abbreviation!) ? -1 : 1);
    } else if ((this.filterService.getShowFilter('PT'))) {
      return modules.sort(modul => minorModuleMap.get(Minor.MB_PT)!.includes(modul.modules![0].abbreviation!) ? -1 : 1);
    } else if ((this.filterService.getShowFilter('PD'))) {
      return modules.sort(modul => minorModuleMap.get(Minor.MB_PD)!.includes(modul.modules![0].abbreviation!) ? -1 : 1);
    } else if ((this.filterService.getShowFilter('PE'))) {
      return modules.sort(modul => minorModuleMap.get(Minor.MB_PE)!.includes(modul.modules![0].abbreviation!) ? -1 : 1);
    } else if ((this.filterService.getShowFilter('EA'))) {
      return modules.sort(modul => minorModuleMap.get(Minor.EIT_EAS)!.includes(modul.modules![0].abbreviation!) ? -1 : 1);
    } else if ((this.filterService.getShowFilter('ES'))) {
      return modules.sort(modul => minorModuleMap.get(Minor.EIT_ESD)!.includes(modul.modules![0].abbreviation!) ? -1 : 1);
    } else if ((this.filterService.getShowFilter('EG'))) {
      return modules.sort(modul => minorModuleMap.get(Minor.EUT_EG)!.includes(modul.modules![0].abbreviation!) ? -1 : 1);
    } else if ((this.filterService.getShowFilter('ESYS'))) {
      return modules.sort(modul => minorModuleMap.get(Minor.EUT_ES)!.includes(modul.modules![0].abbreviation!) ? -1 : 1);
    } else if ((this.filterService.getShowFilter('NWC'))) {
      return modules.sort(modul => minorModuleMap.get(Minor.EUT_NWC)!.includes(modul.modules![0].abbreviation!) ? -1 : 1);
    } else {
      return modules;
    }
  }

}
