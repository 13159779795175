import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {IStudienjahrgang} from '../../models/Studienjahrgang';
import {ParseService} from '../../services/parse.service';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-dropdown-studiengaenge',
  templateUrl: './dropdown-studiengaenge.component.html',
  styleUrls: ['./dropdown-studiengaenge.component.scss']
})
export class DropdownStudiengaengeComponent implements OnInit, OnChanges {

  @Input() isExpanded = false;

  constructor(
    public parseService: ParseService,
    private authService: AuthService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: any): void {
    if (changes.isExpanded) {
      this.isExpanded = changes.isExpanded.currentValue;
    }
  }

  stopEventPropagation(event: any): void {
    if (event instanceof MouseEvent) {
      event.stopPropagation();
    }
  }

  selectStudienJahrgang(studienjahrgang: IStudienjahrgang): void {
    if (this.authService.getRole() === 'student') {
      this.parseService.initData();
    } else {
      // TODO maybe fix (is this getting loaded twice?)
      this.router.navigate([`/student/${this.parseService.getStudent().studierendeId}/studienjahrgang/${studienjahrgang.studienjahrgangId}`]);
      this.parseService.initData(this.parseService.getStudent().studierendeId!.toString(), studienjahrgang);
    }
  }
}
