<div class="container">
  <div class="item">
    <div class="circle passed-background-color"></div>
  </div>
  <div class="label">Bestanden</div>
</div>

<div class="container">
  <div class="item">
    <div class="circle failed-background-color"></div>
  </div>
  <div class="label">Einmal fehlgeschlagen</div>
</div>

<div class="container">
  <div class="item">
    <div class="circle failed-twice-background-color"></div>
  </div>
  <div class="label">Zweimal fehlgeschlagen</div>
</div>

<div class="container">
  <div class="item">
    <div class="circle running-background-color"></div>
  </div>
  <div class="label">Laufend</div>
</div>

<div class="container">
  <div class="item">
    <div *ngIf="themeService.isDark()" class="icon icon-done-dark"></div>
    <div *ngIf="!themeService.isDark()" class="icon icon-done-light"></div>
  </div>
  <div class="label">Modulgruppe Bestanden</div>
</div>

<div class="container">
  <div class="item">
    <div *ngIf="themeService.isDark()" class="icon icon-warning-dark"></div>
    <div *ngIf="!themeService.isDark()" class="icon icon-warning-light"></div>
  </div>
  <div class="label">Modulgruppen Warnung</div>
</div>

<div *ngIf="parseService.getStudiengang() !== studiengang.DS" class="container">
  <div class="item">
    <div class="text-blue">(3 gc.)</div>
  </div>
  <div class="label">Gruppen ECTS</div>
</div>

<div class="container">
  <div class="item">
    <div class="text-blue">angr.</div>
  </div>
  <div class="label">Modul angerechnet</div>
</div>

<div class="container">
  <div class="item">
    <div class="text-blue">✓</div>
  </div>
  <div class="label">Modul erfüllt</div>
</div>

<div class="container">
  <div class="item">
    <div class="text-blue">✗</div>
  </div>
  <div class="label">Modul nicht erfüllt</div>
</div>
