<ng-container *ngIf="modulGroup">
  <div class="modulgroup-status" *ngIf="status.status === modulStatus.PASSED"
       [style.color]="status.color"
       [style.bottom]="bottom + 'px'"
       [style.left]="left + 'px'"
       [style.display]="status.symbol === '' ? 'none' : 'flex'"
       [style.border]="'2px solid ' + status.color"
       [ngClass]="getPosition()">{{status.symbol}}
  </div>
  <div class="modulgroup-status" *ngIf="status.status === modulStatus.FAILED"
       [style.color]="status.color"
       [style.bottom]="bottom + 'px'"
       [style.left]="left + 'px'"
       [style.display]="status.symbol === '' ? 'none' : 'flex'"
       [style.border]="'2px solid ' + status.color"
       [ngClass]="getPosition()">{{status.symbol}}
  </div>
</ng-container>
<ng-container *ngIf="!modulGroup && !status">
  <div class="modulgroup-status"
       [style.color]="'#65B983'"
       [style.bottom]="bottom + 'px'"
       [style.left]="left + 'px'"
       [style.display]="'flex'"
       [style.border]="'2px solid ' + '#65B983'"
       [ngClass]="getPosition()">✓
  </div>
</ng-container>
