<div class="profilierung-container" [ngClass]="themeService.isDark() ? 'studiengang-name-dark' : 'studiengang-name-light'">
  <div class="left-section" title="Liste der obligatorischen Vertiefungsmodulen">
    <span class="profilierung-header-pflicht">Pflichtmodule für die Profilierung</span>
    <span class="profilierung-angerechnet-fehlgeschlagen">
      <ul *ngIf="profilierungService.isProfilierungSelected()" class="module-list">
        <li *ngFor="let module of profilierungService.mandatoryModules; let last = last"
            [ngClass]="{'passed-color': profilierungService.getMandatoryModulesPassed().get(module), 'not-passed-color': !profilierungService.getMandatoryModulesPassed().get(module)}"
            [ngStyle]="{'margin-right': last ? '0' : '0.5rem'}">
          {{ module }}
        </li>
      </ul>
    </span>
  </div>
  <div class="profilierung-center">
    <span class="profilierung-header-title">Profilierung {{ profilierungService.getSelectedProfilierung()[0] }}</span>
  </div>
  <div class="right-section" title="Minimale Anzahl an benötigten Wahlpflichtmodulen abgeschlossen.">
    <app-progress-bar
      [title]="''"
      [marginTop]="'20px'"
      [marginBottom]="'20px'"
      [textMarginTop]="'1.5rem'"
      [color]="'var(--passed-color)'"
      [label]="'Abgeschlossen ' + profilierungService.profilierungsModulesPassed.length + ' / ' + profilierungService.requiredModules"
      [max]="profilierungService.requiredModules"
      [value]="profilierungService.profilierungsModulesPassed.length"
      [isProfilierung] = true>
    </app-progress-bar>
  </div>
</div>




