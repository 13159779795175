<div class="slider-component-wrapper">
  <label for="negativECTSSlider">
    {{ label }} {{ defaultSliderValue }}
  </label>
  <div class="slider-wrapper">
    <span>{{ min }}</span>
    <input class="slider-element" id="negativECTSSlider"
           #slider
           type="range"
           [min]="min"
           [max]="max"
           [value]="defaultSliderValue"
           (input)="onSliderChange(slider.value)">
    <span>{{ max }}</span>
  </div>
</div>
