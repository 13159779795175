import {Injectable} from "@angular/core";
import {ParseService} from "./parse.service";
import {FilterService} from "./filter.service";
import {IModul} from "../models/Modul";
import {Minor, minorModuleMap} from "../navigation/filter/minors";
import {FilterConfigEntry} from "../models/FilterConfigEntry";

class RequiredModuleInfo {
  constructor(public requiredModules: number, public mandatoryModule: string[]) {}
}

@Injectable({
  providedIn: 'root'
})
export class ProfilierungService {

  public allModulesForMinor: string[] = [];
  public profilierungsModulesPassed: IModul[] = [];
  public requiredModules = 0;
  public mandatoryModules: string[] = [''];

  private requiredModulesMap: Map<string, RequiredModuleInfo> = new Map([
    [Minor.EUT_EG, new RequiredModuleInfo(6, ['gkl', 'gbp', 'bpad','gbs','geg'])],
    [Minor.EUT_ES, new RequiredModuleInfo(6, ['thpr', 'ent', 'slt', 'thea', 'leem'])],
    [Minor.EUT_NWC, new RequiredModuleInfo(6, ['uwms', 'awr', 'sproc', 'res', 'niu'])],
    [Minor.MB_TFEC, new RequiredModuleInfo(11, ['wueb', 'aem', 'thd3', 'flm3', 'thd4', 'flm4'])],
    [Minor.MB_TFEE, new RequiredModuleInfo(11, ['wueb', 'aem', 'thd3', 'flm3', 'thd4', 'flm4'])],
    [Minor.MB_PT, new RequiredModuleInfo(11, ['c1d', 'c1m', 'dpev1', 'kt1', 'kt2', 'dpev2'])],
    [Minor.MB_PD, new RequiredModuleInfo(11, ['mant1', 'amat', 'am', 'mea', 'apd', 'mant2'])],
    [Minor.MB_PE, new RequiredModuleInfo(11, ['mant1', 'amat', 'am', 'mea', 'apd', 'mant2'])],
    [Minor.I_DS, new RequiredModuleInfo(8, ['wods'])],
    [Minor.I_SM, new RequiredModuleInfo(7, ['wosm'])],
    [Minor.I_SC, new RequiredModuleInfo(7, ['wosc'])],
    [Minor.I_WE, new RequiredModuleInfo(9, ['woweb'])],
    [Minor.EIT_EAS, new RequiredModuleInfo(6, ['mev', 'spe', 'ele', 'dya', 'rads', 'mst'])],
    [Minor.EIT_ESD, new RequiredModuleInfo(6, ['dsv', 'kom', 'dcom', 'dst', 'mspr', 'ebssd'])],
  ]);

  constructor(
    public parseService: ParseService,
    public filterService: FilterService
  ) {
  }

  public updateProfilierungData(): void {
    this.findAllModulesForMinor();
    this.updatePassedMinorModules();
    this.getRequiredModulesForMinor();
    this.getMandatoryModulesForMinor();
  }

  private getRequiredModulesForMinor(): void {
    const selectedMinor = this.getSelectedMinor();
    this.requiredModules = this.requiredModulesMap.get(selectedMinor)?.requiredModules ?? 0;
  }

  getMandatoryModulesForMinor(): void {
    const selectedMinor = this.getSelectedMinor();
    this.mandatoryModules = this.requiredModulesMap.get(selectedMinor)?.mandatoryModule ?? [];
  }

  getMandatoryModulesPassed(): Map<string, boolean> {
    const result = new Map<string, boolean>();
    const passedModuleAbbreviations = this.profilierungsModulesPassed.map(module => module.abbreviation);

    this.mandatoryModules.forEach(module => {
      result.set(module, passedModuleAbbreviations.includes(module));
    });

    return result;
  }

  private findAllModulesForMinor(): void {
    this.allModulesForMinor = [];

    if (!this.isProfilierungSelected()) {
      return;
    }

    const profilierungFilters = Array.from(this.filterService.config.filters)
      .filter(([_, filterConfig]) =>
        filterConfig.on && filterConfig.category === "Profilierungen"
      );

    profilierungFilters.forEach(([filterKey]) => {
      const minorModules = minorModuleMap.get(filterKey);
      if (minorModules) {
        this.allModulesForMinor.push(...minorModules);
      }
    });
  }

  private updatePassedMinorModules(): void {
    const passedModules = this.parseService.getFlattenedModuleTree().filter(module => {
      const status = module.attempts?.[0]?.status;
      return 'Angerechnet' == status || 'Bestanden' == status;
    });

    this.profilierungsModulesPassed = passedModules.filter(module =>
      this.allModulesForMinor.includes(module.abbreviation)
    );

    this.parseService.setProfileModules(this.profilierungsModulesPassed);
  }

  getSelectedProfilierung(): [string, FilterConfigEntry] | undefined {
    return this.filterService.getEnabledFilters().find(([_, filter]) => filter.category === 'Profilierungen');
  }

  isProfilierungSelected(): boolean {
    return !!this.getSelectedProfilierung();
  }

  private getSelectedMinor(): string {
    const selectedProfilierung = this.getSelectedProfilierung();
    return selectedProfilierung?.[0] ?? '';
  }

  getRowStyles(): any {
    return {
      '--grid-row-start': this.isProfilierungSelected() ? '6' : '4',
      '--grid-row-end': this.isProfilierungSelected() ? '7' : '5'
    };
  }
}
