import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrl: './slider.component.scss'
})
export class SliderComponent {

  @Input() label: string;
  @Input() min: number;
  @Input() max: number;
  @Input() defaultSliderValue: number;
  @Input() disabled: boolean = false;

  @Output() sliderValue = new EventEmitter<number>();

  public onSliderChange(value: string): void {
    const parsedSliderValue = parseInt(value);
    this.defaultSliderValue = parsedSliderValue;
    this.sliderValue.emit(parsedSliderValue);
  }
}
