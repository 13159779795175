import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {Attempt} from '../models/Attempt';
import {LevelDescription} from '../models/Level';
import {IModul, Modul} from '../models/Modul';
import {Modulstatus} from '../models/Modulstatus';
import {Minor, minorModuleMap} from '../navigation/filter/minors';
import {FilterService} from './filter.service';
import {NavigationService} from './navigation.service';
import {ParseService} from './parse.service';
import {SidenavService} from './sidenav.service';
import {SnackbarService} from './snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class ModulService {

  constructor(
    private navigationService: NavigationService,
    private sidenavService: SidenavService,
    private filterService: FilterService,
    private parseService: ParseService,
    private snackbarService: SnackbarService
  ) {
  }

  public modulSearchArray: BehaviorSubject<Array<IModul>> = new BehaviorSubject<Array<IModul>>([]);
  public searchInput = '';

  static calcModuleHeight(modul?: IModul, isInTimeline?: boolean, isProject?: boolean): number {
    if (!!modul?.modules && modul.modules.length > 0) {
      modul = modul.modules[0];
    }
    if (!modul?.modul) {
      modul = modul?.modules?.[0];
    }

    if (isInTimeline || !modul?.modul?.ects) {
      return 20;
    }
    const pMult = isProject ? 3 : 1;
    return modul.modul.ects * 12 + pMult * 12;
  }

  static calcModuleWidth(currView?: string, modul?: IModul, ects?: number): string {
    const isTimeline = currView === 'timeline';
    ects = ects ?? modul?.modul?.ects;

    if (isTimeline) {
      return `module-width-${ects ?? 1}`;
    } else {
      return 'module-width-100px';
    }
  }

  showModuleDetails(event: any, currentModule: Modul): void {
    const modules = [currentModule].concat(currentModule.dependingModules ?? []);
    this.sidenavService.setSelectedModule(modules);
    event.stopPropagation();
    this.navigationService.closePreferences();
  }

  scrollToElement(elementId?: string): void {
    document.getElementById(elementId!)?.scrollIntoView({block: 'center'});
  }

  getStatusClass(modul?: IModul, attempt?: Attempt): string {
    const statusToClassMapping = new Map([
      [Modulstatus.PASSED, 'passed-background-color'],
      [Modulstatus.FAILED, 'failed-background-color'],
      [Modulstatus.LOCKED, 'failed-twice-background-color'],
      [Modulstatus.RUNNING, 'running-background-color'],
    ]);
    modul = modul?.modules && modul.modules[0] ? modul.modules[0] : modul;
    const attemptStatus = attempt?.status;
    const modulStatus = attempt ? attempt.status : modul?.attempts?.[0]?.status;
    const isValidEnabledStatus = (status: Modulstatus | undefined) => status
      && statusToClassMapping.has(status)
      && (this.filterService.getShowFilter(status)
        || (status === Modulstatus.LOCKED && this.filterService.getShowFilter(Modulstatus.FAILED)));

    if (isValidEnabledStatus(attemptStatus)) {
      // attemptStatus is non-null, because key is checked in isValidEnabledStatus
      // Result is non-null, because key is defined and in map
      return statusToClassMapping.get(attemptStatus!)!;
    } else if (isValidEnabledStatus(modulStatus)) {
      // attemptStatus is non-null, because key is checked in isValidEnabledStatus
      // Result is non-null, because key is defined and in map
      return statusToClassMapping.get(modulStatus!)!;
    } else if (!!modul?.angerechnet
      && this.filterService.getShowFilter(Modulstatus.PASSED)) {
      // Result is non-null, because key is in map.
      return statusToClassMapping.get(Modulstatus.PASSED)!;
    }
    return '';
  }

  displayTypes(modul: IModul): boolean {
    let val = true;
    const filters = ['TFEC', 'TFEE', 'PT', 'PD', 'PE', 'EG', 'ESYS', 'NWC', 'Data Science', 'System Management',
      'Spatial Computing', 'Web Engineering', 'EA', 'ES', 'Challenge', 'Portfolio', 'Basis', 'Projekt'];

    if (filters.some(name => this.filterService.getShowFilter(name))) {
      val = false;
    }
    const filterToFuncMap = new Map([
      ['Challenge', (m: IModul) => !!this.displayDataScienceTypes(m)],
      ['Portfolio', (m: IModul) => !!this.displayDataScienceTypes(m)],
      ['Basis', (m: IModul) => !!this.displayDataScienceTypes(m)],
      ['Projekt', (m: IModul) => !!this.displayDataScienceTypes(m)],
    ]);
    Object.values(Minor)
      .map(minor => {
        return {minor, callback: (m: IModul) => this.shouldDisplayMinor(minor, m)};
      })
      .forEach(({minor, callback}) => filterToFuncMap.set(minor, callback));

    if (Array.from(filterToFuncMap.entries())
      .some(([filterName, displayType]) => this.filterService.getShowFilter(filterName) && displayType(modul))) {
      return true;
    }
    return val;
  }

  displayDataScienceTypes(modul?: IModul): string {
    const typeToAbbrMapping = new Map([
      ['Challenge', 'C'],
      ['Portfolio', 'P'],
      ['Basis', 'B'],
      ['Projekt', 'Proj.'],
    ]);
    return Array.from(typeToAbbrMapping.keys())
      .filter(type => modul?.modul?.memo7DE?.toLowerCase().includes(type.toLowerCase()))
      .filter(type => this.filterService.getShowFilter(type))
      .map(type => typeToAbbrMapping.get(type))
      .sort()
      [0] ?? '';
  }

  displayDifficulty(modul?: IModul): string {
    const difficulties = Object.values(LevelDescription).filter(diff => diff !== LevelDescription.none);
    const currentDiff = modul?.level?.levelDescription ?? '';
    if (!this.filterService.getShowFilter(currentDiff) &&
      difficulties.filter(diff => diff !== currentDiff).some(diff => this.filterService.getShowFilter(diff))) {
      return 'opacity-02';
    }
    return 'opacity-1';
  }

  shouldDisplayMinor(minor?: string, modul?: IModul): boolean {
    if (!minor || !modul?.abbreviation || !minorModuleMap.has(minor)) {
      return false;
    }

    // Result of get on map is non-null, because key is in map.
    return this.filterService.getShowFilter(minor) &&
      minorModuleMap.get(minor)!.includes(modul.abbreviation);
  }

  public searchModul(event: any): void {
    this.searchInput = event?.target?.value ?? this.searchInput;
    this.snackbarService.executedHintSnackbar();
    this.modulSearchArray.next(this.parseService.getFlattenedModuleTree().filter(modul => {
      return [modul.bezeichnung, modul.abbreviation]
        .some(input => !!input && input.toLowerCase().includes(this.searchInput?.toLowerCase()));
    }));
  }

  public clearSearchData(): void {
    this.searchInput = '';
    this.modulSearchArray.next([]);
  }

  public searchedModuleInArray(group: Array<IModul>): boolean {
    return group.some(m => !!m.modules && this.modulSearchArray.getValue().includes(m.modules[0]));
  }

  minorModulesInArray(modules: Array<IModul>): boolean {
    const moduleAbbreviationIsInMinor
      = (abbr: string | undefined,
         minor: string) => !!abbr && minorModuleMap.get(minor)?.includes(abbr);
    const filterActive = (name: string) => this.filterService.getShowFilter(name);
    const noModulesInMinor = (minor: string) => !modules.some(m => moduleAbbreviationIsInMinor(m.modules?.[0]?.abbreviation, minor));
    return !Object.values(Minor)
      .some(minor => filterActive(minor) && noModulesInMinor(minor));
  }
}
