import {AfterViewChecked, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, ViewChild} from '@angular/core';
import {IModul, Modul} from '../../models/Modul';
import {ModulService} from '../../services/modul.service';
import {ParseService} from '../../services/parse.service';
import {Modulstatus} from '../../models/Modulstatus';
import {LevelDescription} from '../../models/Level';
import {IAttempt} from '../../models/Attempt';
import {Studiengang} from '../../models/Studiengaenge';

@Component({
  selector: 'app-module-details',
  templateUrl: './module-details.component.html',
  styleUrls: ['./module-details.component.scss']
})
export class ModuleDetailsComponent implements OnChanges, AfterViewChecked {

  @Input() module!: IModul;
  @ViewChild('descriptionContent', {static: true}) descriptionContent!: ElementRef;

  public isExpandable!: boolean;
  public isExpanded!: boolean;
  public levelDescription = LevelDescription;
  public descClass: Array<string> = [];

  constructor(
    public modulService: ModulService,
    public parseService: ParseService,
    private cdRef: ChangeDetectorRef
  ) {
  }

  ngOnChanges(): void {
    this.isExpandable = true;
    this.isExpanded = false;
    this.displayModuleDesc();
  }

  ngAfterViewChecked(): void {
    this.displayModuleDesc();
  }

  toggleExpandableText(event: MouseEvent): void {
    this.isExpanded = !this.isExpanded;
    this.displayModuleDesc();
    event.stopPropagation();
  }

  displayModuleDescription(modul: Modul): string {
    let desc = '';
    if (this.parseService.getStudiengang() === Studiengang.DS) {
      if (modul.modul?.memo3DE) {
        desc = modul.modul.memo3DE;
      } else {
        desc = 'Keine Beschreibung';
      }
    } else {
      if (modul.modul !== null) {
        if (modul.modul!.memo2DE !== null) {
          desc = modul.modul!.memo2DE!.replace('<br>', '');
        } else if (modul.modul!.memo2EN !== null) {
          desc = modul.modul!.memo2EN!.replace('<br>', '');
        } else {
          desc = 'Keine Beschreibung';
        }
      } else if (modul.modules![0].modul) {
        if (modul.modules![0].modul!.memo2DE !== null) {
          desc = modul.modules![0].modul!.memo2DE!.replace('<br>', '');
        } else if (modul.modules![0].modul!.memo2EN !== null) {
          desc = modul.modules![0].modul!.memo2EN!.replace('<br>', '');
        } else {
          desc = 'Keine Beschreibung';
        }
      } else {
        desc = 'Keine Beschreibung';
      }
    }
    return desc;
  }

  public getModulStatusColor(modulStatus: Modulstatus): string {
    if (modulStatus === Modulstatus.PASSED || modulStatus === Modulstatus.CREDITED) {
      return 'passed-color';
    } else if (modulStatus === Modulstatus.FAILED) {
      return 'failed-color';
    } else if (modulStatus === Modulstatus.LOCKED) {
      return 'failed-twice-color';
    } else if (modulStatus === Modulstatus.RUNNING) {
      return 'accent-blue';
    } else {
      return '';
    }
  }

  displayModuleDesc(): void {
    this.descClass = [];
    if (!this.isExpanded && this.descriptionContent.nativeElement.offsetHeight < 350) {
      this.descClass.push('description-small');
    } else if (this.isExpandable && !this.isExpanded) {
      this.descClass.push('description-small description-small-fade');
    }
    if (!this.isExpandable && this.isExpanded) {
      this.descClass.push('description-expanded');
    }
    this.cdRef.detectChanges();
  }

  getLecturers(attempt: IAttempt): string {
    const leitung = this.parseService.getModulanlaesse()
      .filter(a => a.nummer?.split('.')[0].includes(attempt.semester!))
      .filter(a => a.nummer?.split('.')[1].includes(this.module.abbreviation!))
      .filter(a => a.nummer?.split('.')[2].includes('SE'))
      .map(a => a.anlassleitungen);
    return leitung
      .flatMap(person => person?.map(p => `${p?.leitungsperson?.vorname} ${p?.leitungsperson?.nachname}`))
      .join(', ');
  }
}
