import {Component, Input} from '@angular/core';
import {IModul} from '../../models/Modul';
import {ParseService} from '../../services/parse.service';
import {ThemeService} from '../../services/theme.service';
import {Studiengang} from '../../models/Studiengaenge';
import {ModulService} from "../../services/modul.service";
import {View} from "../../views/views";

@Component({
  selector: 'app-depending-modules',
  templateUrl: './depending-modules.component.html',
  styleUrls: ['./depending-modules.component.scss']
})
export class DependingModulesComponent {

  @Input() module!: IModul;
  public studiengang = Studiengang;

  constructor(
    public parseService: ParseService,
    public themeService: ThemeService,
    public modulService: ModulService
  ) {
  }

  public openInModuleTree(): void {
    if (this.module?.abbreviation){
      this.modulService.searchInput = this.module.abbreviation;
      this.modulService.modulSearchArray.next([this.module]);
    }
    this.parseService.currentView.next(View.TREE);
  }

  public readonly View = View;
}
