<div [style.position]="fixed ? 'fixed': 'relative'"
     [style.height]="fixed ? '' : 'calc(100vh - 80px)'"
     class="side-nav" (click)="stop($event)"
     [style.top.px]="isInAdmin ? '' : topOffset" [ngClass]="{
    'side-nav-collapsed': !isSideNavigationExpanded,
    'side-nav-expanded': isSideNavigationExpanded}">
  <div #sideNavContent #stickyMenu class="sticky">
    <div class="toggle-btn" *ngIf="!isInAdmin"
         [ngClass]="{
        'toggle-btn-expanded': isSideNavigationExpanded,
        'icon-expand-dark': themeService.isDark(),
        'icon-expand-light': !themeService.isDark()}" (click)="toggleSidenav($event)">
    </div>
    <div [style.visibility]="!isSideNavigationExpanded ? 'hidden' : ''">
      <app-module-details *ngIf="isModuleSelected() && !isInAdmin" [module]="modul"></app-module-details>
      <app-sidenav-admin *ngIf="isInAdmin"
                         [currentStudentCount] = "currentStudentCount"
                         (filterChanged)="detectChangedFilters($event)"
                         (formSubmitted) = "detectFormSubmitted($event)"
                         (filtersDisabledOutput) = "detectFiltersDisabled($event)"
      ></app-sidenav-admin>
      <app-sidenav-study-progress *ngIf="!isModuleSelected() && !isInAdmin"></app-sidenav-study-progress>
      <div class="space"></div>
    </div>
  </div>
</div>
