<div class="wirtschaftsingenieurswesen-container">
  <div class="header-container-3-8">
    <app-modulteppich-header></app-modulteppich-header>
  </div>
  <ng-container *ngFor="let group of modulTree">
    <div *ngIf="group.bezeichnung!.trim() === 'Projekte'"
         [style.border]="'2px solid ' + modulgroupService.calcBubbleStatus(group, '#00D0CD').color"
         class="informatik-modulteppich-projekte-container">
      <app-modulgroup class="modulgroup"
                      [modulGroup]="group"
                      [isProject]="true">
      </app-modulgroup>
    </div>
    <div *ngIf="group.bezeichnung === 'Fachausbildung'"
         [style.border]="'2px solid ' + modulgroupService.calcBubbleStatus(group, '#2094EC').color"
         class="fachausbildung-container">
      <app-status-bubble [hidden]="!(filterService.getShowFilter('Bestanden') || filterService.getShowFilter('Fehlgeschlagen'))"
                         [modulGroup]="group"
                         [status]="modulgroupService.calcBubbleStatus(group, '#2094EC')">
      </app-status-bubble>
      <div class="container-title">{{group.bezeichnung}}</div>
      <app-ects [displayMinECTS]="false"
                [passedGroupECTS]="parseService.calculatePassedGroupECTS(group)"
                [minGroupECTS]="calculateMinGroupECTS(group)"
                [modulGroup]="group">
      </app-ects>
      <div #fachausbildung style="display: flex; flex-direction: column; gap: 16px;">
        <div class="wing-modulteppich-container">
          <ng-container *ngFor="let subgroup of group.modules">
            <div *ngIf="subgroup.bezeichnung === 'Fachvertiefung'"
                 [style.border]="'2px solid ' + modulgroupService.calcBubbleStatus(subgroup, '#2094EC').color"
                 class="vertiefung-container">
              <app-status-bubble [hidden]="!(filterService.getShowFilter('Bestanden') || filterService.getShowFilter('Fehlgeschlagen'))"
                                 [modulGroup]="subgroup"
                                 [status]="modulgroupService.calcBubbleStatus(subgroup, '#2094EC')">
              </app-status-bubble>
              <div class="container-title" [title]="subgroup.bezeichnung">{{ subgroup.bezeichnung }}</div>
              <app-gruppenanrechnung [group]="subgroup"></app-gruppenanrechnung>
              <app-ects [displayMinECTS]="false"
                        [passedGroupECTS]="parseService.calculatePassedGroupECTS(subgroup)"
                        [minGroupECTS]="calculateMinGroupECTS(subgroup)"
                        [modulGroup]="subgroup">
              </app-ects>
              <div class="icompetence-vertiefung-container">
                <ng-container *ngFor="let subsubgroup of sortByPosition(subgroup.modules!)">
                  <div *ngIf="!subsubgroup.bezeichnung!.toLowerCase().includes('weitere vert')"
                       style="position: relative; width: 122px; height: 458px;">
                    <app-modulgroup [modulGroup]="subsubgroup"
                                    [sortByAssessment]="true"
                                    [sortByPosition]="true"
                                    [showTitle]="false"
                                    [showECTS]="false"
                                    [showStatusBubble]="false">
                    </app-modulgroup>
                  </div>
                  <div
                    *ngIf="subsubgroup.bezeichnung!.toLowerCase().includes('weitere vert') && showWeitereVertiefungen"
                    style="position: relative; width: 122px; height: 458px;">
                    <app-modulgroup [modulGroup]="subsubgroup"
                                    [sortByAssessment]="true"
                                    [sortByPosition]="true"
                                    [showTitle]="false"
                                    [showECTS]="false"
                                    [showStatusBubble]="false">
                    </app-modulgroup>
                  </div>
                </ng-container>
              </div>
            </div>
            <div *ngIf="subgroup.bezeichnung === 'Fachergänzung'"
                 [style.border]="'2px solid ' + modulgroupService.calcBubbleStatus(subgroup, '#2094EC').color"
                 class="wing19-ergaenzung-container">
              <app-modulgroup class="modulgroup"
                              [modulGroup]="subgroup"
                              [sortByECTS]="true"
                              [orderColWise]="true"
                              [heightPixel]="364">
              </app-modulgroup>
            </div>
          </ng-container>
        </div>
        <ng-container *ngFor="let subgroup of group.modules">
          <div *ngIf="subgroup.bezeichnung === 'Fachgrundlagen'" class="grundlagen-container-wing">

            <ng-container *ngFor="let subsubgroup of subgroup.modules">
              <div [style.border]="'2px solid ' + modulgroupService.calcBubbleStatus(subsubgroup, '#2094EC').color"
                   class="grundlagen-group-container">
                <app-modulgroup class="modulgroup"
                                [modulGroup]="subsubgroup"
                                [sortByStatus]="true"
                                [maxElements]="6"
                                [showMoreModulesButtonHeightECTS]="3">
                </app-modulgroup>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="group.bezeichnung === 'Kontext'"
         [style.height]="contHeight + 84 + 'px'"
         class="informatik-modulteppich-kontext-container">
      <ng-container *ngFor="let subgroup of sortByPosition(group.modules!, {ascending: true})">
        <div [style.border]="modulgroupService.defineModulgroupBorder(subgroup, '#8E4FD0')"
             class="kontext-group flex-1-1-auto">
          <app-modulgroup class="modulgroup"
                          [maxElements]="7"
                          [maxHeightPixel]="200"
                          [spaceBetween]="true"
                          [sortByStatus]="true"
                          [modulGroup]="subgroup">
          </app-modulgroup>
        </div>
      </ng-container>
    </div>
    <div class="freie-module-container"
         *ngIf="group.bezeichnung === 'Freie Module' && parseService.calculatePassedGroupECTS(group) > 0">
      <div [style.border]="modulgroupService.defineModulgroupBorder(group, '#8E4FD0')"
           class="kontext-group freie-module">
        <app-modulgroup class="modulgroup"
                        [maxElements]="4"
                        [spaceBetween]="true"
                        [sortByStatus]="true"
                        [showModules]="false"
                        [modulGroup]="group">
        </app-modulgroup>
      </div>
    </div>
  </ng-container>
</div>
