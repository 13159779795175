import {
  ChangeDetectorRef,
  Component,
  ElementRef, EventEmitter,
  Input, OnChanges,
  OnDestroy,
  OnInit, Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {Subscription} from 'rxjs';
import {IAttempt} from '../../models/Attempt';
import {NavigationService} from '../../services/navigation.service';
import {SidenavService} from '../../services/sidenav.service';
import {ThemeService} from '../../services/theme.service';
import {IModul} from '../../models/Modul';
import {ModulService} from '../../services/modul.service';
import {ParseService} from '../../services/parse.service';
import {Studiengang} from '../../models/Studiengaenge';
@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit, OnDestroy {

  @Input() isInAdmin!: boolean;
  @Input() fixed?: boolean = false;
  @Input() currentStudentCount: number = 0;


  @Output() filterChanged = new EventEmitter<any>();
  @Output() formSubmitted = new EventEmitter<any>();
  @Output() filtersDisabledOutput = new EventEmitter<boolean>();


  @ViewChild('sideNavContent') sideNavContent!: ElementRef;
  @ViewChild('stickyMenu') menuElement!: ElementRef;
  private subscriptions: Subscription[] = [];
  private isContentExpanded = false;
  public modul!: IModul;
  public attempts: IAttempt[] = [];
  public isSideNavigationExpanded = false;
  public studiengang = Studiengang;
  public topOffset = 0;

  constructor(
    private sidenavService: SidenavService,
    public navigationService: NavigationService,
    private changeDetectorRef: ChangeDetectorRef,
    public themeService: ThemeService,
    public modulService: ModulService,
    public parseService: ParseService
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.sidenavService.getSubjectSelectedModule().subscribe((value: Array<IModul>) => this.setModuleDescription(value[0])),
      this.navigationService.getCurrentScroll().subscribe((value: number) => {
        this.topOffset = this.isInAdmin ? Math.max(0, 80 - value) : Math.max(61, 141 - value);
        this.changeDetectorRef.detectChanges();
      }),
      this.sidenavService.getSubjectExpanded().subscribe((value: boolean) => value ? this.openSideNavigation() : this.closeSideNavigation())
    );
    if (this.isInAdmin) {
      this.openSideNavigation();
    }
  }

  ngOnDestroy = (): void => this.subscriptions.forEach((s) => s.unsubscribe());
  isModuleSelected = (): boolean => this.modul !== undefined;
  stop = (event: MouseEvent): void => event.stopPropagation();

  toggleSidenav(event: MouseEvent): void {
    this.sidenavService.setIsExpanded(!this.isSideNavigationExpanded);
    event.stopPropagation();
  }

  detectChangedFilters(filters: any){
    this.filterChanged.emit(filters);
  }

  detectFormSubmitted(value: any){
    this.formSubmitted.emit(value);
  }

  detectFiltersDisabled(value: boolean): void{
    this.filtersDisabledOutput.emit(value);
  }

  openSideNavigation(): void {
    this.isSideNavigationExpanded = true;
    this.navigationService.closeFilterSettings();
    this.navigationService.closeUserSettings();
    setTimeout(() => (this.isContentExpanded = true), 200);
  }

  closeSideNavigation(): void {
    this.isSideNavigationExpanded = false;
    this.modul && this.sidenavService.clearSelectedModule();
  }

  setModuleDescription(value: IModul): void {
    this.modul = value;
    value ? this.openSideNavigation() : this.closeSideNavigation();
  }
}
