import {Component, Input, OnInit} from '@angular/core';
import {IModul} from '../models/Modul';

@Component({
  selector: 'app-modulgroup-title',
  templateUrl: './modulgroup-title.component.html',
  styleUrls: ['./modulgroup-title.component.scss']
})
export class ModulgroupTitleComponent implements OnInit {

  @Input() modulGroup?: IModul;

  constructor() {
  }

  ngOnInit(): void {
  }

}
