<div *ngIf="!showOnlyCredited" class="semester-ects-status-container">
  <div title="{{semester?.passedECTS}} ECTS dieses Semester bestanden" class="status-container">
    <div class="passed-circle"></div>
    <span class="semester-ects-status-number">{{semester?.passedECTS}}</span>
  </div>
  <div title="{{semester?.failedOnceECTS}} ECTS dieses Semester fehlgeschlagen" class="status-container">
    <div class="failed-circle"></div>
    <span class="semester-ects-status-number">{{semester?.failedOnceECTS}}</span>
  </div>
  <div title="{{semester?.failedMultipleTimesECTS}} ECTS dieses Semester fehlgeschlagen" class="status-container">
    <div class="failed-twice-circle"></div>
    <span class="semester-ects-status-number">{{semester?.failedMultipleTimesECTS}}</span>
  </div>
  <div title="{{semester?.runningECTS}} ECTS dieses Semester noch laufend" class="status-container">
    <div class="running-circle"></div>
    <span class="semester-ects-status-number">{{semester?.runningECTS}}</span>
  </div>
</div>

<div *ngIf="showOnlyCredited" class="semester-ects-status-container">
  <div title="{{parseService.anrechnungsECTS}} ECTS angerechnet" class="status-container">
    <div class="passed-circle"></div>
    <span class="semester-ects-status-number">{{parseService.anrechnungsECTS}}</span>
  </div>
</div>
