import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NavigationService} from '../services/navigation.service';
import {FilterService} from '../services/filter.service';
import {Subscription} from 'rxjs';
import {ParseService} from '../services/parse.service';
import {AuthService} from '../services/auth.service';
import {ThemeService} from '../services/theme.service';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import {Studiengang} from '../models/Studiengaenge';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, OnDestroy {

  public isUserDropdownExpanded = false;
  public isStudiengaengeExpanded = false;
  private subscriptions: Subscription[] = [];
  public studiengang: typeof Studiengang = Studiengang;
  @ViewChild('filterNav') filterNav?: ElementRef;


  public env = environment;


  constructor(
    public filterService: FilterService,
    public navigationService: NavigationService,
    public parseService: ParseService,
    public authService: AuthService,
    public themeService: ThemeService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.navigationService
        .getSubjectUserSettingsExpanded()
        .subscribe((value: boolean) => {
          this.isUserDropdownExpanded = value;
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  logoOnClick(): void {
    if (this.authService.getRole() === 'admin') {
      this.router.navigate(['/admin']);
    }
  }

  getStudentVorname(): string | undefined {
    if (this.authService.getUser().admin) {
      return this.authService.getUser().vorname
        ? this.authService.getUser().vorname
        : '(Vorname)';
    } else {
      return this.authService.getUser().vorname
        ? this.authService.getUser().vorname
        : '(Vorname)';
    }
  }

  getStudentNachname(): string | undefined {
    if (this.authService.getUser().admin) {
      return this.authService.getUser().nachname
        ? this.authService.getUser().nachname
        : '(Nachname)';
    } else {
      return this.authService.getUser().nachname
        ? this.authService.getUser().nachname
        : '(Nachname)';
    }
  }

  toggleUserSettingDropdown(event: MouseEvent): void {
    this.navigationService.setIsUserSettingsExpanded(
      !this.isUserDropdownExpanded
    );
    event.stopPropagation();
  }

  getCurrentNavigation(): string {
    return this.navigationService.getCurrentNavigation();
  }
}
