import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {V2Module} from "../models/V2Module";
import {NavigationService} from "../../services/navigation.service";
import {SidenavService} from "../../services/sidenav.service";
import {FilterService} from "../../services/filter.service";
import {SnackbarService} from "../../services/snackbar.service";
import {V2ModuleStatus} from "../models/V2ModuleStatus";
import {Minor, minorModuleMap} from "../../navigation/filter/minors";
import {Modul} from "../../models/Modul";

@Injectable({
  providedIn: 'root'
})

// Further implementation needed once v2SideNav will be fully implemented.
export class v2ModulService {

  constructor(
    private filterService: FilterService,
    private snackbarService: SnackbarService,
    private sidenavService: SidenavService,
    private navigationService: NavigationService
  ) {
  }

  public searchInput = '';
  public modulAbbreviationsSearchArray: BehaviorSubject<Array<String>> = new BehaviorSubject<Array<String>>([]);

  private studentModules = new Array<V2Module>();

  static calcModuleHeight(modul?: V2Module, isInTimeline?: boolean, isProject?: boolean): number {
    if (isInTimeline || !modul?.ects) {
      return 20;
    }
    const pMult = isProject ? 3 : 1;
    return modul.ects * 12 + pMult * 12;
  }

  static calcModuleWidth(currView?: string, modul?: V2Module, ects?: number): string {
    const isTimeline = currView === 'timeline';
    ects = ects ?? modul?.ects;

    if (isTimeline) {
      return `module-width-${ects ?? 1}`;
    } else {
      return 'module-width-100px';
    }
  }

  getStatusClass(moduleStatus: V2ModuleStatus): string {
    const statusToClassMapping = new Map([
      [V2ModuleStatus.PASSED, 'passed-background-color'],
      [V2ModuleStatus.CREDITED, 'passed-background-color'],
      [V2ModuleStatus.FAILED, 'failed-background-color'],
      [V2ModuleStatus.LOCKED, 'failed-twice-background-color'],
      [V2ModuleStatus.RUNNING, 'running-background-color'],
    ]);

    const isValidEnabledStatus = (status: V2ModuleStatus | undefined) => status
      && statusToClassMapping.has(status)
      && (this.filterService.getShowFilter(status)
        || (status === V2ModuleStatus.LOCKED && this.filterService.getShowFilter(V2ModuleStatus.FAILED)));

    if (isValidEnabledStatus(moduleStatus)) {
      // attemptStatus is non-null, because key is checked in isValidEnabledStatus
      // Result is non-null, because key is defined and in map
      return statusToClassMapping.get(moduleStatus!)!;
    } else if (isValidEnabledStatus(moduleStatus)) {
      // attemptStatus is non-null, because key is checked in isValidEnabledStatus
      // Result is non-null, because key is defined and in map
      return statusToClassMapping.get(moduleStatus!)!;
    }
    return '';
  }

  public searchModul(event: any): void {
    this.searchInput = event?.target?.value ?? this.searchInput;
    this.snackbarService.executedHintSnackbar();
    const matchingAbbreviations = this.studentModules
      .filter(modul => [modul.name, modul.abbreviation]
        .some(input => input?.toLowerCase().includes(this.searchInput.toLowerCase())))
      .map(modul => modul.abbreviation)
      .filter(Boolean);

    this.modulAbbreviationsSearchArray.next(matchingAbbreviations);
  }

  public clearSearchData(): void {
    this.searchInput = '';
    this.modulAbbreviationsSearchArray.next([]);
  }

  displayTypes(modul: V2Module): boolean {
    let val = true;
    const filters = ['TFEC', 'TFEE', 'PT', 'PD', 'PE', 'EG', 'ESYS', 'NWC', 'Data Science', 'System Management',
      'Spatial Computing', 'Web Engineering', 'EA', 'ES', 'Challenge', 'Portfolio', 'Basis', 'Projekt'];

    if (filters.some(name => this.filterService.getShowFilter(name))) {
      val = false;
    }
    const filterToFuncMap = new Map([
      ['Challenge', (m: V2Module) => !!this.displayDataScienceTypes(m)],
      ['Portfolio', (m: V2Module) => !!this.displayDataScienceTypes(m)],
      ['Basis', (m: V2Module) => !!this.displayDataScienceTypes(m)],
      ['Projekt', (m: V2Module) => !!this.displayDataScienceTypes(m)],
    ]);
    Object.values(Minor)
      .map(minor => {
        return {minor, callback: (m: V2Module) => this.shouldDisplayMinor(minor, m)};
      })
      .forEach(({minor, callback}) => filterToFuncMap.set(minor, callback));

    if (Array.from(filterToFuncMap.entries())
      .some(([filterName, displayType]) => this.filterService.getShowFilter(filterName) && displayType(modul))) {
      return true;
    }
    return val;
  }


  shouldDisplayMinor(minor: string, modul?: V2Module): boolean {
    if (!minor || !modul?.abbreviation || !minorModuleMap.has(minor)) {
      return false;
    }
    // Result of get on map is non-null, because key is in map.
    return this.filterService.getShowFilter(minor) &&
      minorModuleMap.get(minor)!.includes(modul.abbreviation);
  }

  public setStudentModules(studentModules: V2Module[]) {
    this.studentModules = studentModules;
  }

  showModuleDetails(event: any, currentModule: Modul): void {
    const modules = [currentModule].concat(currentModule.dependingModules ?? []);
    this.sidenavService.setSelectedModule(modules);
    event.stopPropagation();
    this.navigationService.closePreferences();
  }

  scrollToElement(elementId?: string): void {
    document.getElementById(elementId!)?.scrollIntoView({block: 'center'});
  }

  displayDataScienceTypes(modul?: V2Module): string {
    return '';
    //TODO
  }

  displayDifficulty(): string {
    return '';
    //TODO
  }
}
