import {CompetenceGroup} from './CompetenceGroup';

export class DataScienceAssessment {
  modulgruppen: Array<CompetenceGroup>;
  minECTS: number;

  constructor(modulgruppen: Array<CompetenceGroup>, minECTS: number) {
    this.modulgruppen = modulgruppen;
    this.minECTS = minECTS;
  }
}
