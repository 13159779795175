import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from '@angular/core';
import { LocalStorageConfig } from "../config/LocalStorageConfig";
import { LocalStorageService } from "./local-storage.service";

enum MsgLevel {
  INFO,
  ERROR,
  HINT
}

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  static MsgLevel = MsgLevel;
  private static readonly LevelToDurationMap = new Map([ [MsgLevel.INFO, 3000],
                                                         [MsgLevel.ERROR, 10000],
                                                         [MsgLevel.HINT, 0],
  ]);

  constructor(
    private localStorageService: LocalStorageService
  ) {
    this.localStorageService.config.subscribe(config => {
      this.config = config;
    });
  }

  private showSnackbar = false;
  private snackbarMessage = '';
  private snackbarLevel = SnackbarService.MsgLevel.ERROR;
  private moduleSearchExecuted: boolean;

  public config: LocalStorageConfig = LocalStorageConfig.empty();

  openHttpErrorResponse(error: HttpErrorResponse): void {
    const statusToMessageMapping = new Map([
      [401, 'Keine Autorisierung.'],
      [400, 'Ungültige Anfrage.'],
    ]);
    const defaultMessage =
      'Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es später erneut.';

    this.showMsg(
      statusToMessageMapping.get(error.status) ?? defaultMessage,
      SnackbarService.MsgLevel.ERROR
    );
  }

  showSearchHintModuleTree(): void {
    this.moduleSearchExecuted = this.config.moduleSearchExecuted;
    const defaultMessage = 'Gib den Namen eines Moduls ein, um es zu suchen.';

    if (!this.moduleSearchExecuted) {
      this.showMsg(defaultMessage, SnackbarService.MsgLevel.HINT);
    }
  }

  hideSearchHintModuleTree(): void {
    if (this.snackbarLevel === SnackbarService.MsgLevel.HINT) {
      this.showSnackbar = false;
    }
  }

  /**
   * Sets the showSearchHint flag to true and saves it to the local storage.
   */
  executedHintSnackbar(): void {
    this.config.moduleSearchExecuted = true;
    this.localStorageService.config.next(this.config);
    this.showSnackbar = false;
  }

  showMsg(msg: string, level: MsgLevel): void {
    this.snackbarMessage = msg;
    this.showSnackbar = true;
    this.snackbarLevel = level;
    if (level !== SnackbarService.MsgLevel.HINT) {
      setTimeout(
        () => (this.showSnackbar = false),
        SnackbarService.LevelToDurationMap.get(level)
      );
    }
  }

  close(): void {
    this.snackbarLevel == SnackbarService.MsgLevel.HINT ? this.executedHintSnackbar() : this.showSnackbar = false;
  }

  getShowSnackbar(): boolean {
    return this.showSnackbar;
  }

  getSnackbarMessage(): string {
    return this.snackbarMessage;
  }

  getSnackbarLevel(): MsgLevel {
    return this.snackbarLevel;
  }

  // This function is used to adjust the CSS behaviour of the Snackbar-Container for the hint snackbar.
  getModuleSearchNotExecuted(): boolean {
    return !this.moduleSearchExecuted;
  }
}
