import { Component } from '@angular/core';
import { ModulService } from "../services/modul.service";
import { ParseService } from '../services/parse.service';
import { SnackbarService } from '../services/snackbar.service';
import { ThemeService } from '../services/theme.service';
import { View } from '../views/views';

@Component({
  selector: 'app-view-toggle',
  templateUrl: './view-toggle.component.html',
  styleUrls: ['./view-toggle.component.scss']
})
export class ViewToggleComponent {

  public readonly View = View;

  constructor(
    public themeService: ThemeService,
    public parseService: ParseService,
    private moduleService: ModulService,
    private snackbarService: SnackbarService
  ) {
  }

  getClassForViewLabel(view: string): Array<string> {
    const currentViewClass = 'current-view';
    const thirdAboveClass = 'third-above-view';
    const secondAboveClass = 'second-above-view';
    const secondBelowClass = 'second-below-view';
    const thirdBelowClass = 'third-below-view';
    const currentView = this.parseService.currentView;
    const defaultValue = [''];

    if (view === currentView.getValue()) {
      return [currentViewClass];
    }

    switch (currentView.getValue()) {
      case View.CARPET: {
        if (View.TIMELINE === view) {
          return [secondBelowClass];
        } else if (View.TREE) {
          return [thirdBelowClass];
        }
        break;
      }
      case View.TIMELINE: {
        if (View.CARPET === view) {
          return [secondAboveClass];
        } else if (View.TREE) {
          return [secondBelowClass];
        }
        break;
      }
      case View.TREE: {
        if (View.CARPET === view) {
          return [thirdAboveClass];
        } else if (View.TIMELINE) {
          return [secondAboveClass];
        }
        break;
      }
      default: {
        return defaultValue;
      }
    }
    return defaultValue;
  }

  public setView(newView: View): void {
    const currentView = this.parseService.currentView.getValue();

    // The search data should only be cleared when switching to or from the module tree view.
    if (currentView === View.TREE || newView === View.TREE) {
      this.moduleService.clearSearchData();
    } 

    this.toggleSearchHintModuleTree(newView, currentView);

    this.parseService.currentView.next(newView);
  }

  private toggleSearchHintModuleTree(newView: View, currentView: View): void {
    if (newView === View.TREE && currentView !== View.TREE) {
      this.snackbarService.showSearchHintModuleTree();
    } else if (newView !== View.TREE && currentView === View.TREE) {
      this.snackbarService.hideSearchHintModuleTree();
    }
  }
}
