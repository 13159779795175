import {Component, Input} from '@angular/core';
import {FilterService} from '../../../services/filter.service';
import {ModulService} from '../../../services/modul.service';
import {ParseService} from '../../../services/parse.service';
import {NavigationService} from '../../../services/navigation.service';
import {V2Module} from "../../models/V2Module";
import {V2Attempt} from "../../models/V2Attempt";
import {V2AttemptStatus} from "../../models/V2AttemptStatus";

@Component({
  selector: 'app-v2modul-attributes',
  templateUrl: './v2modul-attributes.component.html',
  styleUrls: ['./v2modul-attributes.component.scss']
})
export class V2modulAttributesComponent {

  @Input() modul?: V2Module;
  @Input() modulAttempts?: V2Attempt[];
  @Input() attempt?: V2Attempt;
  @Input() isTimeline = this.navigationService.getCurrentNavigation().includes('timeline');

  constructor(
    public filterService: FilterService,
    public modulService: ModulService,
    public parseService: ParseService,
    public navigationService: NavigationService
  ) {
  }

  showGrades(): string {
    let grade = '';
      if (this.attempt.status != V2AttemptStatus.RUNNING) {
        if (this.attempt.finalGrade == 0.0){
          if (this.attempt.status === V2AttemptStatus.PASSED) {// module die erfullt sein konnen, ohne grade
            grade = '✓';
          } else if (this.attempt.status === (V2AttemptStatus.FAILED)) {
            grade = '✗';
          }
        }
        else {
          grade = this.filterService.getShowFilter('Noten Runden') ? ParseService.roundGradeToHalf(this.attempt.finalGrade) : ParseService.roundGrade(this.attempt.finalGrade);
        }
      }
    //TODO for modulteppich
    return grade;
  }

  showECTS(): string {
    return this.modul.ects.toString();
  }

  displayModuleCredited(): string {
    let modulStatus = '';
    if (this.filterService.getShowFilter('Angerechnet')) {
      if (this.attempt.status == V2AttemptStatus.CREDITED) {
        modulStatus = 'angr.';
      }
    }
    return modulStatus;
  }

  displayAssessmentModule(): string {
    let modulStatus = '';
    if (this.filterService.getShowFilter('Assessment')) {
      if (this.modul.assessment === true) {
          modulStatus = 'A';
      }
    }
    return modulStatus.trim();
  }

  displayModuleMitMSP(): string {
    let modulStatus = '';
    if (this.filterService.getShowFilter('MSP')) {
        if (this.modul.hasMsp) {
          modulStatus = 'M';
        }
    }
    return modulStatus.trim();
  }

  attributeColorModuleRunning(): string {
    const filterLaufend = this.filterService.getShowFilter('Laufend');
    if (this.attempt) {//timeline
      if (this.attempt.status === V2AttemptStatus.RUNNING && filterLaufend) {
          return 'running-color-white';
      } else {
        return 'running-color';
      }
    }
    return '';
    //TODO for modulteppich
  }
}
