<div [ngClass]="hidden ? 'modal-hidden' : 'modal-show'" class="modal-content">
  <div class="modal-container-wrapper">
    <div (clickOutside)="hide()" class="modal-container">
      <div class="modal-header-container">
        <span class="modal-title">
          Studenthub
          <ng-container *ngIf="environment.isTest">- Testumgebung</ng-container>
        </span>
        <span (click)="hide()" class="modal-close-button pointer">x</span>
      </div>
      <div *ngIf="!content" class="modal-body-container">
        <div *ngIf="!isOnBoarding" class="onboarding-info">
          <p *ngIf="environment.isTest">
            Dies ist eine Testumgebung. Diese Umgebung spiegelt einen aktuelleren Entwicklungsstand als die Produktivumgebung wieder, ist aber nicht immer verfügbar und enthält möglicherweise mehr Programmfehler.
            Stattdessen empfehlen wir die <a href="https://studenthub.technik.fhnw.ch">Produktivumgebung</a>.
          </p>
          <ng-container *ngIf="!environment.isTest">
            <p>
              Angaben ohne Gewähr! Massgebend sind weiterhin die Angaben im Transcript of Records (ToR).
            </p>
            <p>
              Falls Ihr einen Bug entdeckt, schickt uns bitte eine E-Mail an
              <a class="email-link" href="mailto:{{emailAddress}}?subject=Studenthub Bug Report">{{emailAddress}}</a>
            </p>
          </ng-container>
        </div>
      </div>
      <div *ngIf="content" class="modal-body-container">
        <p>{{content}}</p>
      </div>
      <div class="modal-button-container">
        <button (click)="hide()" class="modal-confirm-button pointer">Verstanden</button>
      </div>
    </div>
  </div>
</div>
<div [ngClass]="hidden ? 'background-hidden' : 'background-show'" class="modal-background"></div>
