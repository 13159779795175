<div class="progress-bar"
     [style.margin-top]="marginTop ? marginTop : ''"
     [style.margin-bottom]="marginBottom ? marginBottom : ''">
  <div class="row" *ngIf="!isProfilierung">
    <div class="display5" *ngIf="title">{{title}}</div>
    <div class="display5" *ngIf="displayMax">{{value >= max ? max : value}}/{{max}}</div>
  </div>
  <div class="row description"
       [style.float]="float ? float : ''"
       [style.width]="isSubBar ? '90%' : ''"
       [style.margin-top]="textMarginTop ? textMarginTop : ''">
    <div class="display6" [ngClass]="isProfilierung ? 'primary-color' : 'accent-grey'">{{label}}</div>
    <div class="display6" [ngStyle]="{'color': title === 'Total ECTS'?
     themeService.isDark() ? 'var(--passed-color)' : 'var(--accent-green)'
     : value >= max ?
     (themeService.isDark() ? 'var(--passed-color)' : 'var(--accent-green)')
     : (color === 'var(--failed-color)' && !themeService.isDark() ? 'var(--accent-yellow)' : color)}">{{percentage}}%
    </div>
  </div>
  <div class="bar-chart"
       [style.float]="float ? float : ''"
       [style.margin-top]="textMarginTop ? textMarginTop : ''"
       [style.width]="isSubBar ? '90%' : ''"
       #progressbar>
    <div class="background"></div>
    <div class="progress"
         [ngStyle]="{
         'width.px': getProgressWidth(progressbar.offsetWidth),
         'background-color': value >= max ? 'var(--passed-color)' : color
         }">
    </div>
  </div>
</div>
