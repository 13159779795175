import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {LocalStorageConfig} from '../config/LocalStorageConfig';

@Injectable({
  providedIn: 'root'
})

export class LocalStorageService {
  constructor() {
    this.config = new BehaviorSubject<LocalStorageConfig>(LocalStorageService.loadFromLocalStorage());
    this.config.subscribe(LocalStorageService.saveToLocalStorage);
  }

  config: BehaviorSubject<LocalStorageConfig>;

  private static saveToLocalStorage(config: LocalStorageConfig): void {
    localStorage.setItem('config', LocalStorageConfig.makeStringify(config));
  }

  private static loadFromLocalStorage(): LocalStorageConfig {
    const item = localStorage.getItem('config'); // load config from localStorage
    if (!item) {
      localStorage.setItem('config', LocalStorageConfig.makeStringify(LocalStorageConfig.empty()));
      return LocalStorageConfig.empty();
    }

    const conf: LocalStorageConfig = JSON.parse(item) as LocalStorageConfig; // parse config to JSON
    if (conf.winter === undefined) {
      conf.winter = true;
    }

    if (!!conf.showGrade ||
      LocalStorageConfig.fromObject(conf).filters.get('Noten')?.category === undefined ||
      LocalStorageConfig.fromObject(conf).filters.get('Noten')?.legend === undefined) { // because of old localStorage contents
      return LocalStorageConfig.empty();
    } else {
      return LocalStorageConfig.fromObject(conf);
    }
  }
}
