<div *ngIf="displayFilterForStudiengang()" class="filter-preference">
  <span class="filter-preference-title pointer" (click)="toggleFilter()">{{filterName}}</span>
  <span *ngIf="legend" class="preferences-abbreviation">{{legend}}</span>
  <div *ngIf="showCircleRunning" class="module-status-circle running-background-color"></div>
  <ng-container *ngIf="showCircleFailed">
    <div class="module-status-circle failed-background-color"></div>
    <div class="module-status-circle failed-twice-module-status-circle failed-twice-background-color"></div>
  </ng-container>
  <div *ngIf="showCirclePassed" class="module-status-circle passed-background-color"></div>
  <label class="switch">
    <input (change)="toggleFilter()" [checked]="filterService.getShowFilter(filterName)" type="checkbox">
    <span class="slider round"></span>
  </label>
</div>
