<div [ngClass]="padding ? 'modulteppich-ects' : 'modulteppich-ects-no-padding'">
  <ng-container *ngIf="displayMinECTS">
    <ng-container *ngIf="showMax">
      <div *ngIf="modulGroup.bezeichnung!.toLowerCase().includes('freie module')">max. 8 ECTS</div>
      <div *ngIf="modulGroup && !modulGroup.bezeichnung!.toLowerCase().includes('freie module')"
           [ngClass]="positionAbsolute ? 'min-ects' : ''">max. {{ maxGroupECTS }} ECTS
      </div>
    </ng-container>
    <ng-container *ngIf="showMin">
      <div *ngIf="modulGroup" [ngClass]="positionAbsolute ? 'min-ects' : ''">
        mind. {{ modulGroup.minECTS !== null ? modulGroup.minECTS : 0 }} ECTS
      </div>
      <div *ngIf="!modulGroup" [ngClass]="positionAbsolute ? 'min-ects' : ''">mind. 0 ECTS</div>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!displayMinECTS">
    <div [ngClass]="bold ? 'bold' : ''" *ngIf="maxGroupECTS !== undefined">{{passedGroupECTS}}/{{maxGroupECTS}}
      ECTS
    </div>
    <div [ngClass]="bold ? 'bold' : ''" *ngIf="minGroupECTS !== undefined">{{passedGroupECTS}}/{{minGroupECTS}}
      ECTS
    </div>
  </ng-container>
</div>
