<div class="timeline">
  <div class="timeline-ruler" [style.grid-template-columns]="calculateGrid(maxSemesterECTS)">
    <ng-container *ngFor="let ects of getMaxSemesterECTS(maxSemesterECTS); let i = index;">
      <div class="ruler-bar-line">
        <div *ngIf="i % 3 === 0"
             [style.height]="height + 'px' "
             [style.margin-top]="'-' + height + 'px'"></div>
      </div>
    </ng-container>
  </div>
  <div class="timeline-number-grid" [style.grid-template-columns]="calculateGrid(maxSemesterECTS)">
    <ng-container *ngFor="let ects of getMaxSemesterECTS(maxSemesterECTS)">
      <div [style.left]="ects.toString().length === 1 ? '-4px' : '-8px'" class="timeline-ruler-one">
        <div *ngIf="ects % 3 === 0">{{ects}}</div>
      </div>
    </ng-container>
  </div>
  <div class="semester-number">ECTS</div>
</div>
