import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ThemeService} from "../../../../services/theme.service";
import {ModulService} from "../../../../services/modul.service";
import {ParseService} from "../../../../services/parse.service";
import {View} from "../../../views";

@Component({
  selector: 'app-module-search',
  templateUrl: './module-search.component.html',
  styleUrls: ['./module-search.component.scss']
})
export class ModuleSearchComponent implements OnInit {

  @ViewChild('input') moduleSearchInputField: ElementRef;

  constructor(
    public themeService: ThemeService,
    public moduleService: ModulService,
    private parseService: ParseService
  ) {
  }

  ngOnInit(): void {
  }

  public searchModule(event: any): void {
    if (this.parseService.currentView.getValue() === View.TREE) {
      setTimeout(() => {
        this.moduleService.searchModul(event);
      }, 100);
    } else {
      this.moduleService.searchModul(event);
    }
  }

  public clearSearch(): void {
    this.moduleSearchInputField.nativeElement.value = '';
    this.moduleService.clearSearchData()
  }
}
