<div class="wirtschaftsingenieurswesen-container">
  <div class="header-container-3-8">
    <app-modulteppich-header></app-modulteppich-header>
  </div>
  <div class="profilierung-header-container" *ngIf="profilierungService.isProfilierungSelected()">
    <app-modulteppich-profilierung-header></app-modulteppich-profilierung-header>
  </div>
  <ng-container *ngFor="let group of modulTree">
    <div *ngIf="group.bezeichnung === 'Projekte'"
         [style.border]="modulgroupService.defineModulgroupBorder(group, '#00D0CD')"
         [ngStyle]="profilierungService.getRowStyles()"
         class="informatik-modulteppich-projekte-container">
      <app-modulgroup class="modulgroup"
                      [modulGroup]="group"
                      [isProject]="true">
      </app-modulgroup>
    </div>
    <div *ngIf="group.bezeichnung === 'Fachausbildung'"
         [style.border]="modulgroupService.defineModulgroupBorder(group, '#2094EC')"
         [ngStyle]="profilierungService.getRowStyles()"
         class="fachausbildung-container">
      <app-status-bubble [hidden]="!(filterService.getShowFilter('Bestanden') || filterService.getShowFilter('Fehlgeschlagen'))"
                         [modulGroup]="group"
                         [status]="modulgroupService.calcBubbleStatus(group, '#2094EC')">
      </app-status-bubble>
      <app-modulgroup-title [modulGroup]="group"></app-modulgroup-title>
      <app-ects [displayMinECTS]="false"
                [passedGroupECTS]="parseService.calculatePassedGroupECTS(group)"
                [minGroupECTS]="calculateMinGroupECTS(group)"
                [modulGroup]="group">
      </app-ects>
      <div #fachausbildung class="eut-modulteppich-container">
        <ng-container *ngFor="let subgroup of group.modules">
          <div *ngIf="subgroup.bezeichnung === 'Fachvertiefung'"
               class="vertiefung-container-eut">
            <div class="height-full"
                 [style.border]="modulgroupService.defineModulgroupBorder(subgroup, '#2094EC')">
              <app-status-bubble [hidden]="!(filterService.getShowFilter('Bestanden') || filterService.getShowFilter('Fehlgeschlagen'))"
                                 [modulGroup]="subgroup"
                                 [status]="modulgroupService.calcBubbleStatus(subgroup, '#2094EC')">
              </app-status-bubble>
              <app-modulgroup-title [modulGroup]="subgroup"></app-modulgroup-title>
              <app-gruppenanrechnung [group]="subgroup"></app-gruppenanrechnung>
              <app-ects [displayMinECTS]="false"
                        [passedGroupECTS]="parseService.calculatePassedGroupECTS(subgroup)"
                        [minGroupECTS]="calculateMinGroupECTS(subgroup)"
                        [modulGroup]="subgroup">
              </app-ects>
              <div class="icompetence-vertiefung-container">
                <ng-container *ngFor="let subsubgroup of sortByPosition(subgroup.modules!)">
                  <div *ngIf="!subsubgroup.bezeichnung!.toLowerCase().includes('weitere vert')"
                       style="position: relative; width: 122px;"
                       [style.border]="modulgroupService.defineModulgroupBorder(subsubgroup, '#2094EC')">
                    <app-modulgroup [modulGroup]="subsubgroup"
                                    [sortByAssessment]="true"
                                    [sortByPosition]="true"
                                    [maxElements]="6">
                    </app-modulgroup>
                  </div>
                  <div
                    *ngIf="subsubgroup.bezeichnung!.toLowerCase().includes('weitere vert') && showWeitereVertiefungen"
                    style="position: relative; width: 122px;"
                    [style.border]="modulgroupService.defineModulgroupBorder(subsubgroup, '#2094EC')">
                    <app-modulgroup [modulGroup]="subsubgroup"
                                    [sortByAssessment]="true"
                                    [sortByPosition]="true"
                                    [maxElements]="6">
                    </app-modulgroup>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div *ngIf="subgroup.bezeichnung === 'Fachergänzung'"
               class="ergaenzung-container-eut">
            <div [style.border]="modulgroupService.defineModulgroupBorder(subgroup, '#2094EC')"
                 style="height: 100%;">
              <app-modulgroup class="modulgroup"
                              [modulGroup]="subgroup"
                              [sortByStatus]="true"
                              [orderColWise]="true"
                              [heightPixel]="384"
                              [showMoreModulesButtonHeightECTS]="3">
              </app-modulgroup>
            </div>
          </div>
          <div *ngIf="subgroup.bezeichnung === 'Erneuerbare Energien'"
               class="erneuerbare-energien-container-eut">
            <div [style.border]="modulgroupService.defineModulgroupBorder(subgroup, '#2094EC')"
                 style="height: 100%;">
              <app-modulgroup class="modulgroup"
                              [modulGroup]="subgroup"
                              [sortByStatus]="true">
              </app-modulgroup>
            </div>
          </div>
          <div *ngIf="subgroup.bezeichnung === 'Fachgrundlagen'"
               class="grundlagen-container-eut">
            <ng-container *ngFor="let subsubgroup of sortByPosition(subgroup.modules!, {ascending: true})">
              <div [style.grid-row-start]="1"
                   [style.grid-row-end]="3"
                   [style.border]="modulgroupService.defineModulgroupBorder(subsubgroup, '#2094EC')"
                   class="grundlagen-group-container">
                <app-modulgroup class="modulgroup"
                                [modulGroup]="subsubgroup"
                                [sortByPosition]="true"
                                [sortByAssessment]="true">
                </app-modulgroup>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="group.bezeichnung === 'Kontext'"
         [style.height]="contHeight + 84 + 'px'"
         [ngStyle]="profilierungService.getRowStyles()"
         class="informatik-modulteppich-kontext-container">
      <ng-container *ngFor="let subgroup of sortByPosition(group.modules!, {ascending: true})">
        <div [style.border]="modulgroupService.defineModulgroupBorder(subgroup, '#8E4FD0')"
             class="kontext-group flex-1-1-auto">
          <app-modulgroup class="modulgroup"
                          [maxElements]="6"
                          [maxHeightPixel]="225"
                          [spaceBetween]="true"
                          [sortByStatus]="true"
                          [modulGroup]="subgroup">
          </app-modulgroup>
        </div>
      </ng-container>
    </div>
    <div class="freie-module-container"
         *ngIf="group.bezeichnung === 'Freie Module' && parseService.calculatePassedGroupECTS(group) > 0"
         [ngStyle]="profilierungService.getRowStyles()">
      <div [style.border]="modulgroupService.defineModulgroupBorder(group, '#8E4FD0')"
           class="kontext-group freie-module">
        <app-modulgroup class="modulgroup"
                        [maxElements]="4"
                        [spaceBetween]="true"
                        [sortByStatus]="true"
                        [showModules]="false"
                        [modulGroup]="group">
        </app-modulgroup>
      </div>
    </div>
  </ng-container>
</div>
