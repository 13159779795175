import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { AdminService } from '../../services/admin.service';
import { SnackbarService } from '../../services/snackbar.service';
import { StudierendeWithAnmeldungen } from '../../models/StudierendeWithAnmeldungen';
import { IModul } from '../../models/Modul';
import { Studienjahrgang } from '../../models/Studienjahrgang';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  public loading = false;
  public hasError = false;
  public sortStudentsBy = { attribute: '', ascending: false };
  public errorMessage = '';

  public studierendeWithAnmeldungen: Array<StudierendeWithAnmeldungen> = [];
  public originalStudierendeWithAnmeldungen: Array<StudierendeWithAnmeldungen> = [];
  public currentSGmodulgruppen: Array<IModul> = [];
  public currentStudiengangId = 0;

  public filterChanged: any;
  public filtersDisabled: boolean = true;


  public studienjahrgangInfo: Array<Studienjahrgang> = [];

  constructor(
    public apiService: ApiService,
    public adminService: AdminService,
    public snackbarService: SnackbarService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getQueryParams();
    this.applyFilters(this.filterChanged);
  }

  getQueryParams(): void {
    this.route.queryParams.subscribe(params => {
      const { vorname, nachname, studienjahrgangId } = params;
      if (!vorname && !nachname && !studienjahrgangId) {
        this.errorMessage = 'Wählen Sie bitte Filter, um Studierende zu suchen';
        this.hasError = true;
        this.originalStudierendeWithAnmeldungen = [];
        this.studierendeWithAnmeldungen = [];
      } else {
        if (studienjahrgangId) {
          this.fetchStudentsWithAnmeldungen(params);
          this.getStudienjahrgangModulgruppen(studienjahrgangId);
        } else if (vorname || nachname) {
          this.fetchStudents(params);
          this.getStudienjahrgangModulgruppen(studienjahrgangId);
        }
      }
    });
  }

  public fetchStudents(params: any): void {
    const { vorname, nachname, studienjahrgangId } = params;
    const studienjahrgangParam = studienjahrgangId ? studienjahrgangId : undefined;
    this.loading = true;
    this.hasError = false;
    this.apiService.fetchStudierende(vorname, nachname, studienjahrgangParam).subscribe(studis => {
      this.studierendeWithAnmeldungen = studis.map(student => new StudierendeWithAnmeldungen(student, []));
    }, (error) => {
      this.showErrorMessage(error);
    }, () => {
      if (this.studierendeWithAnmeldungen.length > 0) {
        this.originalStudierendeWithAnmeldungen = [...this.studierendeWithAnmeldungen];
        this.fetchStudienjahrgangInfo();
      } else {
        this.showErrorMessage();
      }
      this.loading = false;
    });
  }

  fetchStudienjahrgangInfo(): void {
    const studienjahrgangAnmeldungIds: Array<string> = [];
    this.studierendeWithAnmeldungen
      .forEach(s => s.student.studienjahrgangAnmeldungen?.forEach(a => studienjahrgangAnmeldungIds.push(a.studienjahrgangId!.toString())));
    if (studienjahrgangAnmeldungIds.length > 0) {
      this.apiService.fetchStudienJahrgaengeInfo(undefined, studienjahrgangAnmeldungIds)
        .subscribe(
          l => {
            this.studienjahrgangInfo = l;
          }, (error) => {
            this.snackbarService.openHttpErrorResponse(error);
            this.hasError = true;
            this.loading = false;
          },
          () => {
            this.studierendeWithAnmeldungen = this.adminService.matchStudentsWithStudienjahrgangInfo(this.studierendeWithAnmeldungen, this.studienjahrgangInfo);
            this.hasError = false;
            this.loading = false;
          }
        );
    }
  }

  public fetchStudentsWithAnmeldungen(params: any): void {
    const { vorname, nachname, studienjahrgangId } = params;
    const studienjahrgangParam = studienjahrgangId ? studienjahrgangId : undefined;
    this.loading = true;
    this.hasError = false;
    this.apiService.fetchStudierendeWithAnmeldungen(vorname, nachname, studienjahrgangParam).subscribe(studis => {
        this.studierendeWithAnmeldungen = studis;
      }, (error) => {
        this.showErrorMessage(error);
      },
      () => {
        if (this.studierendeWithAnmeldungen.length > 0) {
          this.studierendeWithAnmeldungen = this.adminService.calculateStudentsAssessment(this.studierendeWithAnmeldungen, this.currentStudiengangId, this.currentSGmodulgruppen);
          this.studierendeWithAnmeldungen = this.adminService.calculateTotalFailedEctsStudents(this.studierendeWithAnmeldungen,this.currentSGmodulgruppen);

          this.originalStudierendeWithAnmeldungen = [...this.studierendeWithAnmeldungen];
          this.fetchStudienjahrgangInfo();
        } else {
          this.showErrorMessage();
        }
        this.loading = false;
      });
  }

  public getStudienjahrgangModulgruppen(studienjahrgangId: string): void {
    this.apiService.fetchStudienJahrgaengeInfo(undefined, undefined, undefined, undefined, studienjahrgangId)
      .subscribe(
        l => {
          this.studienjahrgangInfo = l;
          this.currentStudiengangId = this.studienjahrgangInfo.find(s => s.studiengangId)?.studiengangId;
          if (this.currentStudiengangId) {
            this.apiService.fetchModulgruppen(this.currentStudiengangId.toString()).subscribe(
              m => {
                this.currentSGmodulgruppen = m;
              },
              error => {
                console.error('Error fetching modulgruppen', error);
              }
            );
          }
        },
        error => {
          console.error('Error fetching studienjahrgangInfo', error);
        }
      );
  }

  showErrorMessage(error?: any): void {
    if (error) {
      this.snackbarService.openHttpErrorResponse(error);
      this.errorMessage = 'Oops, es ist ein Fehler aufgetreten';
    } else {
      this.errorMessage = 'Keine Studierende gefunden';
    }
    this.loading = false;
    this.hasError = true;
  }
  applyFilters(filters: any): void {
    if (!filters) {
      return;
    }
    let filteredStudents = [...this.originalStudierendeWithAnmeldungen];

    if (filters.showOnlyActive) {
      filteredStudents = this.adminService.calculateActiveStudents(filteredStudents);
    }

    if (filters.failedEcts > 0) {
      filteredStudents = this.adminService.filterCriticalStudents(filteredStudents, filters.failedEcts);
    }

    if (filters.passedAssessment && !filters.runningAssessment) {
      filteredStudents = this.adminService.calculateStudentsWithPassedAssessment(filteredStudents, this.currentStudiengangId, this.currentSGmodulgruppen);
    }

    if (filters.runningAssessment && !filters.passedAssessment) {
      filteredStudents = this.adminService.calculateStudentsWithRunningAssessment(filteredStudents, this.currentStudiengangId, this.currentSGmodulgruppen);
    }

    if (!filters.runningAssessment && !filters.passedAssessment) {
      filteredStudents = [];
    }
    if (filteredStudents.length > 0){
      this.hasError = false;
      this.studierendeWithAnmeldungen = filteredStudents;
      this.filterChanged = filters;
    }
    else{
      this.showErrorMessage();
    }
  }

  detectFormSubmitted(params: any){
    this.router.navigate(['/admin'], { queryParams: params });
  }

  detectFiltersDisabled(value: boolean): void{
    this.filtersDisabled = value;
  }

}
