<div *ngIf="filterService.getShowFilter('MSP') && displayModuleMitMSP()"
     class="msp-assessment-module"
     [style.top]="isTimeline ? '1px' : '3px'"
     [ngClass]="this.attributeColorModuleRunning()">
  {{displayModuleMitMSP()}}
</div>
<div *ngIf="filterService.getShowFilter('Assessment') && displayAssessmentModule()"
     class="msp-assessment-module"
     [style.top]="isTimeline ? '1px' : '3px'"
     [ngClass]="this.attributeColorModuleRunning()">
  {{displayAssessmentModule()}}
</div>
<div *ngIf="!!modulService.displayDataScienceTypes(modul)"
     class="module-type-icon"
     [ngClass]="this.attributeColorModuleRunning()">
  {{modulService.displayDataScienceTypes(modul)}}
</div>
<div *ngIf="filterService.getShowFilter('Noten')"
     class="grade-module"
     [style.bottom]="isTimeline ? '1px' : '3px'">
  {{showGrades()}}
</div>
<div *ngIf="filterService.getShowFilter('Angerechnet')"
     class="grade-module"
     [style.bottom]="isTimeline ? '1px' : '3px'">
  {{displayModuleCredited()}}
</div>
<div *ngIf="filterService.getShowFilter('ECTS')"
     class="modul-ECTS"
     [style.bottom]="isTimeline ? '1px' : '3px'"
     [ngClass]="this.attributeColorModuleRunning()">
  {{showECTS()}}
</div>
