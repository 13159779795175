import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ApplicationRef, DoBootstrap, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {OAuthModule, OAuthService} from 'angular-oauth2-oidc';
import {JwksValidationHandler} from 'angular-oauth2-oidc-jwks';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ECTSComponent} from './utils/ects/ects.component';
import {FilterComponent} from './navigation/filter/filter.component';
import {VpnErrorPageComponent} from './error-pages/vpn-error-page/vpn-error-page.component';
import {LegendComponent} from './sidenav/legend/legend.component';
import {LogoutComponent} from './navigation/logout/logout.component';
import {ModuleDetailsComponent} from './sidenav/module-details/module-details.component';
import {
  ModulteppichDataScienceComponent
} from './views/modulteppich/modulteppich-data-science/modulteppich-data-science.component';
import {
  ModulteppichInformatik2018Component
} from './views/modulteppich/modulteppich-informatik/modulteppich-informatik-2018.component';
import {ModulteppichComponent} from './views/modulteppich/modulteppich.component';
import {ModulteppichDirective} from './views/modulteppich/modulteppich.directive';
import {NavigationComponent} from './navigation/navigation.component';
import {SemesterPipe} from './pipes/SemesterPipe';
import {ProgressBarComponent} from './utils/progress-bar/progress-bar.component';
import {RulerComponent} from './views/timeline/ruler/ruler.component';
import {SidenavRightComponent} from './sidenav/right/sidenav-right.component';
import {SidenavComponent} from './sidenav/left/sidenav.component';
import {TimelineComponent} from './views/timeline/timeline.component';
import {ModulComponent} from './modul/modul.component';
import {ModuluebersichtComponent} from './views/timeline/moduluebersicht/moduluebersicht.component';
import {AngerechnetTimelineComponent} from './views/timeline/angerechnet-timeline/angerechnet-timeline.component';
import {ModulteppichHeaderComponent} from './views/modulteppich/modulteppich-header/modulteppich-header.component';
import {ProfilierungHeaderComponent} from './views/modulteppich/modulteppich-profilierung-header/modulteppich-profilierung-header.component';
import {StatusBubbleComponent} from './utils/status-bubble/status-bubble.component';
import {NotenschnittComponent} from './views/all/notenschnitt/notenschnitt.component';
import {
  ModulteppichInformatik2016Component
} from './views/modulteppich/modulteppich-informatik/modulteppich-informatik-2016.component';
import {NoMatchComponent} from './views/modulteppich/modulteppich-no-match/no-match.component';
import {
  ModulteppichIcompetence2018Component
} from './views/modulteppich/modulteppich-iCompetence/modulteppich-icompetence-2018.component';
import {AdminComponent} from './views/admin/admin.component';
import {LoaderComponent} from './utils/loader/loader.component';
import {FormErrorComponent} from './utils/form-error/form-error.component';
import {AuthenticationConfig} from './config/AuthenticationConfig';
import {DropdownStudiengaengeComponent} from './navigation/dropdown-studiengaenge/dropdown-studiengaenge.component';
import {AuthService} from './services/auth.service';
import {
  ModulteppichInformatik2013Component
} from './views/modulteppich/modulteppich-informatik/modulteppich-informatik-2013.component';
import {StudentComponent} from './student/student.component';
import {SanitizeHtmlPipe} from './pipes/SanitizeHtmlPipe';
import {ModulgroupComponent} from './modulgroup/modulgroup.component';
import {ModulgroupTitleComponent} from './modulgroup-title/modulgroup-title.component';
import {GruppenanrechnungComponent} from './gruppenanrechnung/gruppenanrechnung.component';
import {DependingModulesComponent} from './sidenav/depending-modules/depending-modules.component';
import {
  ModulteppichWirtschaftsingenieurwesen2019Component
} from './views/modulteppich/modulteppich-wirtschaftsingenieurwesen/modulteppich-wirtschaftsingenieurwesen-2019.component';
import {
  ModulteppichSystemtechnikComponent
} from './views/modulteppich/modulteppich-systemtechnik/modulteppich-systemtechnik.component';
import {
  ModulteppichMaschinenbau2018Component
} from './views/modulteppich/modulteppich-maschinenbau/modulteppich-maschinenbau-2018.component';
import {
  ModulteppichElektroUndInformationstechnikComponent
} from './views/modulteppich/modulteppich-elektro-und-informationstechnik/modulteppich-elektro-und-informationstechnik.component';
import {
  ModulteppichEnergieUndUmwelttechnikComponent
} from './views/modulteppich/modulteppich-energie-und-umwelttechnik/modulteppich-energie-und-umwelttechnik.component';
import {
  ModulteppichMaschinenbau2020Component
} from './views/modulteppich/modulteppich-maschinenbau/modulteppich-maschinenbau-2020.component';
import {ModulAttributesComponent} from './modul-attributes/modul-attributes.component';
import {SemesterEctsStatusComponent} from './views/timeline/semester-ects-status/semester-ects-status.component';
import {
  ModulteppichIcompetence2016Component
} from './views/modulteppich/modulteppich-iCompetence/modulteppich-icompetence-2016.component';
import {ModalComponent} from './modal/modal.component';
import {ViewToggleComponent} from './view-toggle/view-toggle.component';
import {SnackbarComponent} from './snackbar/snackbar.component';
import {FilterAttributeComponent} from './filter-attribute/filter-attribute.component';
import {
  ModulteppichOptometrie2021Component
} from './views/modulteppich/modulteppich-optometrie/modulteppich-optometrie-2021.component';
import {OnboardingComponent} from './onboarding/onboarding.component';
import {ArrowComponent} from './arrow/arrow.component';
import {ShowMoreModulesComponent} from './show-more-modules/show-more-modules.component';
import {ProfileImageComponent} from './views/admin/profile-image/profile-image.component';
import {ClickOutsideDirective} from './click-outside.directive';
import {AuthInterceptor} from './services/auth.interceptor';
import {UnauthorizedErrorPageComponent} from './error-pages/unauthorized-error-page/unauthorized-error-page.component';
import {ApiService} from './services/api.service';
import {
  ModulteppichOptometrie2022Component
} from './views/modulteppich/modulteppich-optometrie/modulteppich-optometrie-2022.component';
import {LightChainComponent} from './light-chain/light-chain.component';
import {SnowComponent} from './snow/snow.component';
import {ModulTreeComponent} from './views/modul-tree/modul-tree.component';
import {ModuleSearchComponent} from './views/modulteppich/modulteppich-header/module-search/module-search.component';
import {StudyOverviewComponent} from './views/modulteppich/modulteppich-header/study-overview/study-overview.component';
import {SidenavStudyProgressComponent} from "./sidenav/sidenav-study-progress/sidenav-study-progress.component";
import {SidenavAdminComponent} from "./sidenav/sidenav-admin/sidenav-admin.component";
import {SliderComponent} from "./slider/slider.component";
import {StudentListComponent} from "./views/admin/student-list/student-list.component";

//V2
import {v2ModulteppichHeaderComponent} from "./v2/components/v2modulteppich-header/v2modulteppich-header.component";
import {
  v2StudyOverviewComponent
} from "./v2/components/v2modulteppich-header/v2study-overview/v2study-overview.component";
import {v2ModuleSearchComponent} from "./v2/components/v2modulteppich-header/v2module-search/v2module-search.component";
import {v2notenschnittComponent} from "./v2/components/v2notenschnitt/v2notenschnitt.component";
import {v2TimelineComponent} from "./v2/components/v2timeline/v2timeline.component";
import {V2modulComponent} from "./v2/components/v2modul/v2modul.component";
import {V2moduluebersichtComponent} from "./v2/components/v2timeline/v2moduluebersicht/v2moduluebersicht.component";
import {V2semesterEctsStatusComponent} from "./v2/components/v2timeline/v2semester-ects-status/v2semester-ects-status.component";
import {V2modulAttributesComponent} from "./v2/components/v2modul-attributes/v2modul-attributes.component";
import {V2dependingModulesComponent} from "./v2/components/v2sidenav/v2depending-modules/v2depending-modules.component";

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    ModulteppichComponent,
    ModulteppichInformatik2013Component,
    ModulteppichInformatik2016Component,
    ModulteppichInformatik2018Component,
    ModulteppichIcompetence2016Component,
    ModulteppichIcompetence2018Component,
    ModulteppichWirtschaftsingenieurwesen2019Component,
    ModulteppichSystemtechnikComponent,
    ModulteppichMaschinenbau2018Component,
    ModulteppichMaschinenbau2020Component,
    ModulteppichElektroUndInformationstechnikComponent,
    ModulteppichEnergieUndUmwelttechnikComponent,
    ModulteppichOptometrie2021Component,
    ModulteppichOptometrie2022Component,
    NoMatchComponent,
    ModulteppichDataScienceComponent,
    ModulteppichDirective,
    TimelineComponent,
    SidenavComponent,
    FilterComponent,
    ModuleDetailsComponent,
    RulerComponent,
    SemesterPipe,
    SanitizeHtmlPipe,
    VpnErrorPageComponent,
    ECTSComponent,
    LogoutComponent,
    LegendComponent,
    ProgressBarComponent,
    SidenavRightComponent,
    ModulComponent,
    ModuluebersichtComponent,
    AngerechnetTimelineComponent,
    ModulteppichHeaderComponent,
    ProfilierungHeaderComponent,
    StatusBubbleComponent,
    NotenschnittComponent,
    AdminComponent,
    LoaderComponent,
    FormErrorComponent,
    DropdownStudiengaengeComponent,
    StudentComponent,
    GruppenanrechnungComponent,
    DependingModulesComponent,
    ModulgroupComponent,
    ModulgroupTitleComponent,
    ModulAttributesComponent,
    SemesterEctsStatusComponent,
    ModalComponent,
    ViewToggleComponent,
    SnackbarComponent,
    FilterAttributeComponent,
    OnboardingComponent,
    ArrowComponent,
    ShowMoreModulesComponent,
    ProfileImageComponent,
    ClickOutsideDirective,
    UnauthorizedErrorPageComponent,
    ModulTreeComponent,
    LightChainComponent,
    SnowComponent,
    ModuleSearchComponent,
    StudyOverviewComponent,
    SidenavStudyProgressComponent,
    SidenavAdminComponent,
    SliderComponent, StudentListComponent,
    v2ModuleSearchComponent,
    v2StudyOverviewComponent,
    v2ModulteppichHeaderComponent,
    v2notenschnittComponent,
    V2dependingModulesComponent,
    v2TimelineComponent,
    V2modulComponent,
    V2moduluebersichtComponent,
    V2semesterEctsStatusComponent,
    V2modulAttributesComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    OAuthModule.forRoot(),
    HttpClientModule,
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [
          'https://studenthub-test.technik.fhnw.ch/api',
          'https://studenthub.technik.fhnw.ch/api',
          'http://localhost:4200',
        ],
        sendAccessToken: true,
      },
    }),
    BrowserAnimationsModule,
    ReactiveFormsModule
  ],
  exports: [
    ProgressBarComponent,
    LegendComponent,
    FormErrorComponent
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true
  }]
})
export class AppModule implements DoBootstrap {

  private authConfig: AuthenticationConfig = new AuthenticationConfig();

  constructor(
    public readonly oauthService: OAuthService,
    private authService: AuthService,
    private apiService: ApiService
  ) {
  }

  ngDoBootstrap(app: ApplicationRef): void {
    this.oauthService.configure(this.authConfig.authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.setupAutomaticSilentRefresh();
    try {
      if ((window as any).Cypress) {
        app.bootstrap(AppComponent);
      } else {
        this.oauthService
          .loadDiscoveryDocumentAndLogin()
          .then((success) => {
            if (success) {
              this.authService.parseUser().subscribe(user => {
                this.authService.setUser(user);
              }, () => {
                app.bootstrap(UnauthorizedErrorPageComponent);
              }, () => {
                app.bootstrap(AppComponent);
              });
            }
          }).catch((error) => {
          if (error.hasOwnProperty('statusText') && error.statusText === 'Unknown Error') {
            app.bootstrap(VpnErrorPageComponent);
          }
        });
      }
    } catch (e) {
    }
  }
}
