import {Injectable} from '@angular/core';
import {forkJoin, Observable} from 'rxjs';
import {IModul} from '../models/Modul';
import {Anmeldung, IAnmeldung} from '../models/Anmeldung';
import {IStudent, Student} from '../models/Student';
import {IStudienjahrgang, Studienjahrgang} from '../models/Studienjahrgang';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {IModulanlass} from '../models/Modulanlass';
import {IStudierendeWithAnmeldungen, StudierendeWithAnmeldungen} from "../models/StudierendeWithAnmeldungen";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private BaseURL = (window as any).Cypress ? 'http://localhost:3000/' : environment.apiUrl;
  private BaseURLPublic =  environment.apiUrlPublic;

  constructor(
    private http: HttpClient
  ) {
  }

  /*
  * Fetches all modules for a Studiengang
  * */
  public fetchModulgruppen(id?: string): Observable<Array<IModul>> {
    if (id) {
      return this.http.get<Array<IModul>>(`${this.BaseURL}modulgruppen?studentId=${id}`);
    } else {
      return this.http.get<Array<IModul>>(`${this.BaseURL}modulgruppen`);
    }
  }

  /*
  * Fetches all Anmeldungen for a Student
  * */
  public fetchStudentAnmeldungen(studentId?: string): Observable<Array<Anmeldung>> {
    if (studentId) {
      return this.http.get<Array<Anmeldung>>(`${this.BaseURL}anmeldungen?id=${studentId}`);
    } else {
      return this.http.get<Array<Anmeldung>>(`${this.BaseURL}anmeldungen`);
    }
  }

  /*
  * Fetches info for a Student
  * */
  public fetchStudent(id?: string): Observable<IStudent> {
    if (id) {
      return this.http.get<Student>(`${this.BaseURL}student?id=${id}`);
    } else {
      return this.http.get<Student>(`${this.BaseURL}student`);
    }
  }

  /*
  * Fetches all students that equal the 3 params
  * */
  public fetchStudierende(vorname?: string, nachname?: string, studienjahrgangId?: string): Observable<Array<IStudent>> {
    let requestUrl = `${this.BaseURL}studierende`;
    let isFirstFilter = true;
    if (vorname) {
      requestUrl += (isFirstFilter ? '?' : '&') + `vorname=${vorname}`;
      isFirstFilter = false;
    }
    if (nachname) {
      requestUrl += (isFirstFilter ? '?' : '&') + `nachname=${nachname}`;
      isFirstFilter = false;
    }
    if (studienjahrgangId && Number(studienjahrgangId) !== 0) {
      requestUrl += (isFirstFilter ? '?' : '&') + `studienjahrgangId=${studienjahrgangId}`;
      isFirstFilter = false;
    }
    if (isFirstFilter) {
      return new Observable<Array<IStudent>>();
    } else {
      return this.http.get<Array<IStudent>>(requestUrl);
    }
  }

  /*
  * Fetches info about Studienjahrgang
  * */
  public fetchStudienJahrgaengeInfo(id?: string, studienjahrgangIds?: Array<string>, studiengangId?: string, all?: boolean, studienjahrgangId?: string): Observable<[Studienjahrgang]> {
    if (id) {
      return this.http.get<[Studienjahrgang]>(`${this.BaseURL}studienjahrgang?id=${id}`);
    } else if (studienjahrgangIds) {
      return this.http.get<[Studienjahrgang]>(`${this.BaseURL}studienjahrgangInfos?ids=${studienjahrgangIds}`);
    } else if (studiengangId) {
      return this.http.get<[Studienjahrgang]>(`${this.BaseURL}studienjahrgangInfos?studiengangId=${studiengangId}`);
    } else if (all) {
      return this.http.get<[Studienjahrgang]>(`${this.BaseURL}studienjahrgangInfos?all=true`);
    } else if (studienjahrgangId) {
      return this.http.get<[Studienjahrgang]>(`${this.BaseURL}studienjahrgangInfos?studienjahrgangId=${studienjahrgangId}`);
    } else {
      return this.http.get<[Studienjahrgang]>(`${this.BaseURL}studienjahrgang`);
    }
  }

  /*
  * Fetches Student Info with the corresponding Studienjahrgaenge
  * */
  public fetchStudentAndStudienjahrgaenge(studentID?: string): Observable<[IStudent, Array<IStudienjahrgang>]> {
    return forkJoin([
      this.fetchStudent(studentID), this.fetchStudienJahrgaengeInfo(studentID)
    ]);
  }

  /*
  * Fetches Modulteppich and Anmeldungen from Student
  * */
  public fetchModulteppichAndAnmeldungen(studentID?: string, studienjahrgangID?: string): Observable<[Array<IModul>, Array<IAnmeldung>]> {
    return forkJoin([
      this.fetchModulgruppen(studienjahrgangID),
      this.fetchStudentAnmeldungen(studentID)
    ]);
  }

  /*
 * Fetches Modulanlaesse for given ids
 * */
  public fetchModulanlaesse(ids?: (number | undefined)[]): Observable<IModulanlass[]> {
    return this.http.get<IModulanlass[]>(`${this.BaseURL}modulanlaesse?ids=${ids}`);
  }


  public fetchStatus(): Observable<Map<string, object>> {
    return  this.http.get<Map<string, object>>(`${this.BaseURLPublic}healthcheck`);
  }

  public fetchStudierendeWithAnmeldungen (vorname?:string, nachname?:string, studienjahrgangId?: string): Observable<Array<IStudierendeWithAnmeldungen>> {
    let requestUrl = `${this.BaseURL}studierendeWithAnmeldungen`;
    let isFirstFilter = true;
    if (vorname) {
      requestUrl += (isFirstFilter ? '?' : '&') + `vorname=${vorname}`;
      isFirstFilter = false;
    }
    if (nachname) {
      requestUrl += (isFirstFilter ? '?' : '&') + `nachname=${nachname}`;
      isFirstFilter = false;
    }
    if (studienjahrgangId && Number(studienjahrgangId) !== 0) {
      requestUrl += (isFirstFilter ? '?' : '&') + `studienjahrgangId=${studienjahrgangId}`;
      isFirstFilter = false;
    }
    if (isFirstFilter) {
      return new Observable<Array<IStudierendeWithAnmeldungen>>();
    } else {
      return this.http.get<Array<IStudierendeWithAnmeldungen>>(requestUrl);
    }
  }
}
