<div class="side-nav-title">
  <span class="side-nav-label">Modulbeschreibung</span>
  <a class="module-description-link pointer" [href]="'https://www.fhnw.ch/de/studium/module/' + module.modulId"
     target="_blank"
     style="display: flex; flex-direction: row; gap: 4px; align-items: center; width: fit-content; margin-right: 32px;">
    <span>Detailbeschreibung</span>
    <img class="open-detailed-description-icon" alt="open-detailed-description" src="assets/icons/open-link.svg">
  </a>
</div>
<div class="container">
  <div class="competence-title">{{module.gruppe ? 'Gruppenanrechnung ' + module.gruppe : module.bezeichnung}}</div>
  <div class="level" style="display: flex; flex-direction: row; gap: 16px;">
    <div class="abbreviation-container">
      <div>{{module.abbreviation || (module.modules![0] && module.modules![0].abbreviation)}}</div>
    </div>
    <div *ngIf="module.level && module.level!.levelDescription! !== levelDescription.none">
      Anspruchsniveau: {{module.level!.levelDescription!}}
    </div>
  </div>
  <div class="competence-description-short" [ngClass]="descClass">
    <div #descriptionContent class="content" [innerHTML]="displayModuleDescription(module).trim() | sanitizeHtml"></div>
  </div>
  <div class="description-expand" *ngIf="!this.isExpanded && descriptionContent.offsetHeight > 350"
       (click)="toggleExpandableText($event)">Mehr Anzeigen
  </div>
  <div class="description-expand" *ngIf="isExpanded"
       (click)="toggleExpandableText($event)">Weniger Anzeigen
  </div>
  <div class="label margin-top-45">
    {{module.modul !== null && module.modul!.ects !== null ? module.modul!.ects : module.modules![0].modul!.ects}} ECTS
  </div>
  <div *ngFor="let attempt of (module.modules && module.modules![0] ? module.modules![0].attempts : module.attempts)">
    <div class="side-nav-small" *ngIf="attempt.semester">
      <div class="display5 tertiary-color">{{ attempt.semester! | semesterPipe}}</div>
    </div>
    <div *ngIf="getLecturers(attempt).length > 0" class="lecturer">Leitung: {{getLecturers(attempt)}}</div>
    <div class="competence-semester">
      <div class="semester-info tertiary-color">Modul Status</div>
      <div [class]="getModulStatusColor(attempt.status!)">{{ attempt.status }}</div>
    </div>
    <div *ngIf="attempt.MSPGrade" class="competence-semester">
      <div class="semester-info">
        <span>Modulschlussprüfung MSP </span>
        <span *ngIf="!module.attempts![0]!.finalGrade">(Inoffiziell)</span>
      </div>
      <div class="semester-info">{{ attempt.MSPGrade }}</div>
    </div>
    <div *ngIf="attempt.ENGrade" class="competence-semester">
      <div class="semester-info">
        <span>Erfahrungsnote </span>
        <span *ngIf="!module.attempts![0]!.finalGrade">(Inoffiziell)</span>
      </div>
      <div class="semester-info">{{ attempt.ENGrade }}</div>
    </div>
    <ng-container *ngIf="attempt.finalGrade">
      <div class="separation-line"></div>
      <div class="competence-semester">
        <div class="semester-info">Abschlussnote</div>
        <div [class]="getModulStatusColor(attempt.status!)">{{ attempt.finalGrade }}</div>
      </div>
    </ng-container>
  </div>
  <div class="mt-32">
    <app-v2depending-modules class="width-full" [module]="module"></app-v2depending-modules>
  </div>
</div>
