import {AfterViewChecked, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ModulteppichDirective} from '../modulteppich.directive';
import {SidenavRightService} from 'src/app/services/sidenav-right.service';
import {ModulService} from '../../../services/modul.service';
import {FilterService} from '../../../services/filter.service';
import {ParseService} from '../../../services/parse.service';
import {IModul, Modul} from '../../../models/Modul';
import {ModulgroupService} from '../../../services/modulgroup.service';
import {ProfilierungService} from "../../../services/profilierung.service";

@Component({
  selector: 'app-modulteppich-informatik-2016',
  templateUrl: './modulteppich-informatik-2016.component.html',
  styleUrls: ['../modulteppich.component.scss'],
})
export class ModulteppichInformatik2016Component extends ModulteppichDirective implements OnInit, AfterViewChecked {

  @ViewChild('fachausbildung') fachausbildung?: ElementRef;
  public contHeight = '';
  public modulTree: Array<IModul> = [];
  sortByPosition = ParseService.sortByPosition;
  calculateMinGroupECTS = ParseService.calculateMinGroupECTS;

  constructor(
    sideNavRightService: SidenavRightService,
    parseService: ParseService,
    modulService: ModulService,
    filterService: FilterService,
    profilierungService: ProfilierungService,
    public modulgroupService: ModulgroupService
  ) {
    super(sideNavRightService, parseService, modulService, filterService, profilierungService);
  }

  ngOnInit(): void {
    this.modulTree = this.parseService.getModuleTree();
  }

  ngAfterViewChecked(): void {
    this.defineHeight();
  }

  private defineHeight(): void {
    this.contHeight = this.fachausbildung?.nativeElement.offsetHeight;
  }

  columnSpan(module: Modul): string {
    const abb = module.modules![0]?.abbreviation!;
    let span = '';
    if (abb !== '' && abb) {
      if (abb.includes('wosm')) {
        span = '1 / span 2';
      } else if (abb.includes('itfs')) {
        span = '1 / span 2';
      } else if (abb.includes('netsi')) {
        span = '1 / span 2';
      } else if (abb.includes('cpnet')) {
        span = '1 / span 2';
      } else if (abb.includes('witec')) {
        span = '1 / span 2';
      } else if (abb.includes('itsar')) {
        span = '1 / span 2';
      } else if (abb.includes('pcls')) {
        span = '1 / span 2';
      } else if (abb.includes('cysL')) {
        span = '1 / span 2';
      } else if (abb.includes('wodss')) {
        span = '3 / span 2';
      } else if (abb.includes('ddm')) {
        span = '3 / span 2';
      } else if (abb.includes('apsi')) {
        span = '3 / span 2';
      } else if (abb.includes('apm')) {
        span = '3 / span 2';
      } else if (abb.includes('eaf')) {
        span = '3 / span 2';
      } else if (abb.includes('webfr')) {
        span = '3 / span 2';
      } else if (abb.includes('mvdbs')) {
        span = '3 / span 2';
      } else if (abb.includes('woipv')) {
        span = '5 / span 2';
      } else if (abb.includes('ivis')) {
        span = '5 / span 2';
      } else if (abb.includes('igs')) {
        span = '5 / span 2';
      } else if (abb.includes('bverI')) {
        span = '5 / span 2';
      } else if (abb.includes('comgr')) {
        span = '5 / span 2';
      } else if (abb.includes('efalg')) {
        span = '5 / span 2';
      } else if (abb.includes('ml')) {
        span = '5 / span 2';
      } else if (abb.includes('pac')) {
        span = '5 / span 2';
      } else {
        span = '';
      }
    }
    return span;
  }

  rowSpan(module: Modul): string {
    const abb = module.modules![0]?.abbreviation!;
    let span = '';
    if (abb !== '') {
      if (abb.includes('wosm')) {
        span = '1 / 2';
      } else if (abb.includes('itfs')) {
        span = '2 / 3';
      } else if (abb.includes('netsi')) {
        span = '3 / 4';
      } else if (abb.includes('cpnet')) {
        span = '4 / 5';
      } else if (abb.includes('cysL')) {
        span = '5 / 6';
      } else if (abb.includes('pcls')) {
        span = '6 / 7';
      } else if (abb.includes('wodss')) {
        span = '1 / 2';
      } else if (abb.includes('ddm')) {
        span = '2 / 3';
      } else if (abb.includes('apsi')) {
        span = '3 / 4';
      } else if (abb.includes('apm')) {
        span = '4 / 5';
      } else if (abb.includes('eaf')) {
        span = '5 / 6';
      } else if (abb.includes('webfr')) {
        span = '6 / 7';
      } else if (abb.includes('mvdbs')) {
        span = '7 / 8';
      } else if (abb.includes('woipv')) {
        span = '1 / 2';
      } else if (abb.includes('ml')) {
        span = '2 / 3';
      } else if (abb.includes('bverI')) {
        span = '3 / 4';
      } else if (abb.includes('comgr')) {
        span = '4 / 5';
      } else if (abb.includes('efalg')) {
        span = '5 / 6';
      } else if (abb.includes('pac')) {
        span = '6 / 7';
      } else {
        span = '';
      }
    }
    return span;
  }
}
