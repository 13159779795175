import {Component, OnInit} from '@angular/core';
import {ParseService} from '../../../services/parse.service';
import {ThemeService} from '../../../services/theme.service';

@Component({
  selector: 'app-modulteppich-header',
  templateUrl: './modulteppich-header.component.html',
  styleUrls: ['./modulteppich-header.component.scss']
})
export class ModulteppichHeaderComponent implements OnInit {

  constructor(
    public parseService: ParseService,
    public themeService: ThemeService
  ) {
  }

  ngOnInit(): void {
  }
}
