import {Component, Input, OnInit} from '@angular/core';
import {Semester} from '../../../models/Semester';
import {ParseService} from '../../../services/parse.service';

@Component({
  selector: 'app-semester-ects-status',
  templateUrl: './semester-ects-status.component.html',
  styleUrls: ['./semester-ects-status.component.scss']
})
export class SemesterEctsStatusComponent implements OnInit {

  @Input() semester?: Semester;
  @Input() showOnlyCredited = false;

  constructor(
    public parseService: ParseService
  ) {
  }

  ngOnInit(): void {
  }

}
