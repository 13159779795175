import {Modulstatus} from './Modulstatus';

export class Bubblestatus {
  status: Modulstatus;
  color: string;
  symbol: string;

  constructor(status: Modulstatus, color: string, symbol: string) {
    this.status = status;
    this.color = color;
    this.symbol = symbol;
  }
}
