import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {IModul} from '../../models/Modul';
import {FilterService} from '../../services/filter.service';
import {Bubblestatus} from '../../models/Bubblestatus';
import {Modulstatus} from '../../models/Modulstatus';

@Component({
  selector: 'app-status-bubble',
  templateUrl: './status-bubble.component.html',
  styleUrls: ['./status-bubble.component.scss']
})
export class StatusBubbleComponent implements OnChanges {

  constructor(
    private filterService: FilterService
  ) {
  }

  @Input() positionRight = true;
  @Input() positionTop = true;
  @Input() modulGroup!: IModul;
  @Input() status!: Bubblestatus;
  @Input() bottom!: number;
  @Input() left!: number;
  private moduleCount = 0;
  private position: Array<string> = [];
  private searchResponse: Array<IModul> = [];
  public modulStatus: typeof Modulstatus = Modulstatus;

  ngOnChanges(): void {
    this.setPosition();
  }

  getPosition(): Array<string> {
    return this.position;
  }

  setPosition(): void {
    const positionArray: Array<string> = [];
    if (this.positionRight) {
      positionArray.push('right');
    }
    if (!this.positionRight) {
      positionArray.push('left');
    }
    if (this.positionTop) {
      positionArray.push('top');
    }
    if (!this.positionTop) {
      positionArray.push('bottom');
    }
    this.position = positionArray;
  }

  getModuleCount(): number {
    return this.moduleCount;
  }

  setModuleCount(count: number): void {
    this.moduleCount = count;
  }

  getSearchResponse(): Array<IModul> {
    return this.searchResponse;
  }

  getFilterService(): FilterService {
    return this.filterService;
  }
}
