import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {User} from '../models/User';
import {OAuthService} from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private BaseURL = environment.apiUrl;
  private user: User = new User('', false, '', '');
  private role = '';

  constructor(
    private http: HttpClient,
    private oAuthService: OAuthService
  ) {
  }

  public parseUser(): Observable<User> {
    return this.http.get<User>(`${this.BaseURL}user`);
  }

  public setUser(user: User): void {
    user.admin ? this.role = 'admin' : this.role = 'student';
    this.user = user;
  }

  public getUser(): User {
    return this.user;
  }

  public logout(): void {
    this.oAuthService.logOut();
  }

  public getRole(): string {
    return this.role;
  }
}
