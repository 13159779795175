import {Component, Input, OnInit} from '@angular/core';
import {ModulService} from '../../../services/modul.service';
import {ParseService} from '../../../services/parse.service';
import {FilterService} from '../../../services/filter.service';
import {IModul} from '../../../models/Modul';
import {IAttempt} from '../../../models/Attempt';
import {Semester} from '../../../models/Semester';
import {Modulstatus} from '../../../models/Modulstatus';

@Component({
  selector: 'app-moduluebersicht',
  templateUrl: './moduluebersicht.component.html',
  styleUrls: ['./moduluebersicht.component.scss']
})
export class ModuluebersichtComponent implements OnInit {
  calcAccurateAverage = ParseService.calcAccurateAverage;
  calcModuleWidth = ModulService.calcModuleWidth;
  constructor(
    public modulService: ModulService,
    public parseService: ParseService,
    public filterService: FilterService
  ) {
  }

  @Input() gridSize!: number;
  semesterModuleArray: Array<{ semester: Semester, semesterArray: Array<[IModul, IAttempt]> }> = [];

  ngOnInit(): void {
    this.semesterModuleArray = this.mapSemesterOnModule();
  }

  public mapSemesterOnModule(): Array<{ semester: Semester, semesterArray: Array<[IModul, IAttempt]> }> {
    const attemptArray: Array<[IAttempt, IModul]> = [];
    this.parseService.getAllAngemeldeteModule().forEach(modul => {
      modul.attempts?.forEach(attempt => {
        attemptArray.push([attempt, modul]);
      });
    });
    let finArray: Array<{ semester: Semester, semesterArray: Array<[IModul, IAttempt]> }> = [];
    this.parseService.getSemesters().forEach(semester => {
      let semesterArray: Array<[IModul, IAttempt]> = [];
      attemptArray.forEach(attempt => {
        if (semester.abbreviation === attempt[0].semester) {
          semesterArray.push([attempt[1], attempt[0]]);
        }
      });
      semesterArray = this.sortArr(semesterArray);
      finArray.push({semester, semesterArray});
    });
    // sort them by attempt status
    finArray = finArray.map(sem => {
      sem.semesterArray = this.sortByAttemptStatus(sem.semesterArray);
      return sem;
    });
    return finArray;
  }

  sortByAttemptStatus(group: Array<[IModul, IAttempt]>): Array<[IModul, IAttempt]> {
    const order = [Modulstatus.PASSED, Modulstatus.CREDITED, Modulstatus.FAILED, Modulstatus.LOCKED, Modulstatus.RUNNING, Modulstatus.EMPTY];
    return group.sort((a, b) => order.indexOf(a[1].status!) - order.indexOf(b[1].status!));
  }

  public sortArr(semesterArray: Array<[IModul, IAttempt]>): Array<[IModul, IAttempt]> {
    return semesterArray
      .sort((a, b) => {
        if (a[1].status === b[1].status) {
          return (a[0].position ?? 0) > (b[0].position ?? 0) ? 1 : -1;
        } else {
          return a[1].status! < b[1].status! ? 1 : -1;
        }
      });
  }
}
