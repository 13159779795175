import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';
import {FilterService} from './filter.service';
import {ThemeConfig} from '../config/ThemeConfig';

@Injectable({
  providedIn: 'root'
})

export class NavigationService {
  public isFilterSettingsExpanded!: BehaviorSubject<boolean>;
  public isUserSettingsExpanded!: BehaviorSubject<boolean>;
  public isStudiengaengeExpanded!: BehaviorSubject<boolean>;
  public showPreferences = false;
  public currentScroll!: BehaviorSubject<number>;


  constructor(
    private router: Router,
    private filterService: FilterService
  ) {
    this.isFilterSettingsExpanded = new BehaviorSubject<boolean>(false);
    this.isUserSettingsExpanded = new BehaviorSubject<boolean>(false);
    this.isStudiengaengeExpanded = new BehaviorSubject<boolean>(false);
    this.currentScroll = new BehaviorSubject<number>(0);
  }


  getSubjectFilterSettingsExpanded(): BehaviorSubject<boolean> {
    return this.isFilterSettingsExpanded;
  }

  setIsFilterSettingsExpanded(value: boolean): void {
    this.isFilterSettingsExpanded.next(value);
  }

  closeFilterSettings(): void {
    this.isFilterSettingsExpanded.next(false);
  }


  getSubjectUserSettingsExpanded(): BehaviorSubject<boolean> {
    return this.isUserSettingsExpanded;
  }

  setIsUserSettingsExpanded(value: boolean): void {
    this.isUserSettingsExpanded.next(value);
  }

  closeUserSettings(): void {
    this.isUserSettingsExpanded.next(false);
  }


  getSubjectStudiengaengeExpanded(): BehaviorSubject<boolean> {
    return this.isStudiengaengeExpanded;
  }

  setIsStudiengaengeExpanded(value: boolean): void {
    this.isStudiengaengeExpanded.next(value);
  }

  closeStudiengaenge(): void {
    this.isStudiengaengeExpanded.next(false);
  }


  openPreferences(): void {
    this.showPreferences = true;
  }

  closePreferences(): void {
    this.showPreferences = false;
  }

  togglePreferences(): void {
    this.showPreferences = !this.showPreferences;
  }

  getCurrentNavigation(): string {
    return this.router.url;
  }

  scrollbarThemeClass(): string {
    return this.filterService.config.theme === ThemeConfig.Dark ?
           'os-theme-thin-light' :
           'os-theme-thin-dark';
  }
  setCurrentScroll(value: number): void{
    this.currentScroll.next(value);
  }
  getCurrentScroll(): BehaviorSubject<number>  {
    return this.currentScroll;
  }
}
