import {Directive} from '@angular/core';
import {FilterService} from '../../services/filter.service';
import {ModulService} from '../../services/modul.service';
import {ParseService} from '../../services/parse.service';
import {SidenavRightService} from '../../services/sidenav-right.service';
import {Studiengang} from '../../models/Studiengaenge';
import {ProfilierungService} from "../../services/profilierung.service";

@Directive({
  selector: '[appModulteppich]'
})
export class ModulteppichDirective {

  public studiengangEnum = Studiengang;

  constructor(
    public sideNavRightService: SidenavRightService,
    public parseService: ParseService,
    public modulService: ModulService,
    public filterService: FilterService,
    public profilierungService?: ProfilierungService
  ) {
  }
}
