<div class="timeline-grid">
  <div class="header-container-3-8">
    <app-v2modulteppich-header></app-v2modulteppich-header>
  </div>
  <div class="modul-timeline" *ngIf="student && student.modules">
    <div [ngClass]="[navigationService.scrollbarThemeClass(), 'horScroll-long']">
      <div class="modul-timeline-container" #timeline>
        <app-v2moduluebersicht [gridSize]="gridSize"
                               [semesters] ="semesters"
                               [student]="student"
        ></app-v2moduluebersicht>
      </div>
      <app-ruler (gridSize)="setGridSize($event)"
                 [height]="height"
                 [maxSemesterECTS]="semesterMaxECTS">
      </app-ruler>
    </div>
    </div>
  </div>
