import { Component, OnInit } from '@angular/core';
import {ParseService} from "../../../../services/parse.service";

@Component({
  selector: 'app-study-overview',
  templateUrl: './study-overview.component.html',
  styleUrls: ['./study-overview.component.scss']
})
export class StudyOverviewComponent implements OnInit {

  constructor(
    public parseService: ParseService
  ) {
  }

  ngOnInit(): void {
  }

}
