<div class="studiengang-header">
  <div class="studiengang-header-title" *ngIf="parseService.getActiveStudienJahrgang()">{{parseService.getActiveStudienJahrgang().bezeichnung}}</div>
  <div class="studiengang-angerechnet-fehlgeschlagen">
    <span>{{parseService.earnedECTS}}/180 ECTS</span>
    <span>Fehlgeschlagen: {{parseService.failedECTS}} ECTS</span>
  </div>
  <div class="studiengang-angerechnet-fehlgeschlagen">
    <span *ngIf="parseService.modulAnrechnungsECTS > 0">Modulanrechnungen: {{parseService.modulAnrechnungsECTS}} ECTS</span>
    <span *ngIf="parseService.pauschalAnrechnungsECTS > 0">Gruppenanrechnungen: {{parseService.pauschalAnrechnungsECTS}} ECTS</span>
  </div>
</div>
