import {
  AfterViewChecked,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {ParseService} from '../../../services/parse.service';
import {NavigationService} from '../../../services/navigation.service';
import {V2Semester} from "../../models/V2Semester";
import {V2Attempt} from "../../models/V2Attempt";
import {V2Student} from "../../models/V2Student";
import {V2AttemptStatus} from "../../models/V2AttemptStatus";
import {v2ModulService} from "../../services/v2modul.service";

@Component({
  selector: 'app-v2timeline',
  templateUrl: './v2timeline.component.html',
  styleUrls: ['./v2timeline.component.scss']
})
export class v2TimelineComponent implements AfterViewChecked, OnChanges {

  public gridSize = 0;
  public semesterMaxECTS = 0;
  public mostCreditsSemester: V2Semester = {} as V2Semester;

  public height = 0;
  @ViewChild('timeline') timeline?: ElementRef;

  @Input() student?: V2Student;
  semesters: V2Semester[];

  constructor(
    public parseService: ParseService,
    public navigationService: NavigationService,
    public v2ModulService: v2ModulService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.student && this.student) {
      this.semesters = this.parseSemesters();
      this.mostCreditsSemester = this.findMostECTSSemester();
      this.compareMaxECTSInSemester();
    }
  }

  parseSemesters(): V2Semester[] {
    const semesterAbbreviations = [...new Set(this.student.attempts
      .filter(attempt => !attempt.semesterAbbreviation.includes("UNKNOWN"))
      .map(attempt => attempt.semesterAbbreviation)
    )];

    return semesterAbbreviations.map(abbreviation => this.createSemester(abbreviation));
  }

  private createSemester(abbreviation: string): V2Semester {
    const semesterAttempts = this.student.attempts.filter(attempt => attempt.semesterAbbreviation === abbreviation);

    let passedECTS = 0, failedOnceECTS = 0, failedMultipleTimesECTS = 0, runningECTS = 0;
    const attempts: Array<V2Attempt> = [];
    const failureCounts: { [key: string]: number } = {};

    semesterAttempts.forEach(attempt => {
      attempts.push(attempt);
      const ects = this.getModuleEcts(attempt.moduleAbbreviation);
      switch (attempt.status) {
        case V2AttemptStatus.PASSED:
          passedECTS += ects;
          break;
        case V2AttemptStatus.CREDITED:
          passedECTS += ects;
          break;
        case V2AttemptStatus.FAILED:
          failureCounts[attempt.moduleAbbreviation] = (failureCounts[attempt.moduleAbbreviation] || 0) + 1;
          break;
        case V2AttemptStatus.RUNNING:
          runningECTS += ects;
          break;
      }
    });

    const semesterModules = this.student.modules.filter(m =>
      attempts.some(attempt => attempt.moduleAbbreviation === m.abbreviation)
    );
    const pauschalAnrechnungen = this.student.pauschalAnrechnungen.filter(anr => anr.semesterAbbreviation === abbreviation);

    Object.entries(failureCounts).forEach(([moduleAbbreviation, count]) => {
      const ects = this.getModuleEcts(moduleAbbreviation);
      if (count > 1) {
        failedMultipleTimesECTS += count * ects;
      } else {
        failedOnceECTS += ects;
      }
    });

    return new V2Semester(
      abbreviation,
      passedECTS,
      failedOnceECTS,
      failedMultipleTimesECTS,
      runningECTS,
      attempts,
      semesterModules,
      pauschalAnrechnungen
    );
  }

  getModuleEcts(abbreviation: string) {
    const module = this.student.modules.find(module => module.abbreviation === abbreviation);
    return module ? module.ects : 0;
  }

  ngAfterViewChecked(): void {
    this.getHeight();
  }

  getHeight(): void {
    this.height = this.timeline?.nativeElement.offsetHeight;
  }

  /**
   * Set semesterMaxECTS to the sum, of the semester with the most ECTS
   * If anrechnungsECTS are bigger, it takes that one
   */
  compareMaxECTSInSemester(): void {
    this.semesterMaxECTS = 0;
    if (this.semesters.length === 0) {
      this.semesterMaxECTS = this.calculatePauschalAnrechnungEcts();
    } else {
      const mostSemesterECTS = this.sumOfECTSPerSemester(this.mostCreditsSemester);
      if (mostSemesterECTS > this.calculatePauschalAnrechnungEcts()) {
        this.semesterMaxECTS = mostSemesterECTS;
      } else {
        this.semesterMaxECTS = this.calculatePauschalAnrechnungEcts();
      }
    }
  }

  /**
   * Returns the total ECTS of a semester
   */
  sumOfECTSPerSemester(semester: V2Semester): number {
    return (semester.passedECTS + semester.failedOnceECTS + semester.failedMultipleTimesECTS + semester.runningECTS);
  }

  /**
   * Returns the semester with the most ECTS
   */
  findMostECTSSemester(): V2Semester {
    return this.semesters.reduce((prev, current) => (this.sumOfECTSPerSemester(prev) > this.sumOfECTSPerSemester(current)) ? prev : current, this.semesters[0]);
  }

  setGridSize(event: any): void {
    this.gridSize = event;
  }

  calculatePauschalAnrechnungEcts() {
    return this.student.pauschalAnrechnungen.reduce((acc, currValue) => {
      return acc + currValue.ects
    }, 0);
  }
}
