import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IModul } from '../models/Modul';

@Injectable({
  providedIn: 'root',
})
export class SidenavRightService {
  public currentSelectedGroup!: BehaviorSubject<IModul>;
  public currentGroupType!: BehaviorSubject<string>;
  public emptyModule!: IModul;
  public isExpanded!: BehaviorSubject<boolean>;
  public topOffset!: BehaviorSubject<number>;

  constructor() {
    this.currentSelectedGroup = new BehaviorSubject<IModul>(this.emptyModule);
    this.currentGroupType = new BehaviorSubject<string>('');
    this.isExpanded = new BehaviorSubject<boolean>(false);
    this.topOffset = new BehaviorSubject<number>(141);
  }

  public expandRightSideNav(e: MouseEvent, subgroup: IModul, groupType: string): void {
    this.setSelectedGroup(subgroup);
    this.setSelectedType(groupType);
    this.setIsExpanded(true);
    e.stopPropagation();
  }

  getSubjectSelectedGroup(): BehaviorSubject<IModul> {
    return this.currentSelectedGroup;
  }

  getSelectedGroup(): IModul {
    return this.currentSelectedGroup.getValue();
  }

  setSelectedGroup(value: IModul): void {
    this.currentSelectedGroup.next(value);
  }

  getSubjectSelectedType(): BehaviorSubject<string> {
    return this.currentGroupType;
  }

  getSelectedType(): string {
    return this.currentGroupType.getValue();
  }

  setSelectedType(value: string): void {
    this.currentGroupType.next(value);
  }

  clearSelectedGroup(): void {
    this.currentSelectedGroup.next(this.emptyModule);
  }

  getSubjectGroup(): BehaviorSubject<boolean> {
    return this.isExpanded;
  }

  setIsExpanded(value: boolean): void {
    this.isExpanded.next(value);
  }
}
