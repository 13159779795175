import {Component, OnInit} from '@angular/core';
import {ThemeService} from '../../../services/theme.service';
import {v2ApiService} from "../../services/v2api.service";
import {V2Student} from "../../models/V2Student";
import {V2Attempt} from "../../models/V2Attempt";
import {V2Module} from "../../models/V2Module";

@Component({
  selector: 'app-v2modulteppich-header',
  templateUrl: './v2modulteppich-header.component.html',
})
export class v2ModulteppichHeaderComponent implements OnInit {

  attempts: Array<V2Attempt> = [];
  modules: Array<V2Module> = [];
  student: V2Student;

  constructor(
    public v2ApiService: v2ApiService,
    public themeService: ThemeService
  ) {}

  ngOnInit(): void {
    this.fetchStudentData();
  }

  private fetchStudentData(): void {
    this.v2ApiService.fetchStudent().subscribe(
      (data) => {
        if (data) {
          this.student = data;
          this.attempts = this.student.attempts;
          this.modules = this.student.modules;
        } else {
          console.error('Received null student data');
        }
      },
      (error) => console.error('Error fetching student:', error)
    );
  }
}
