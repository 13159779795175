import {IModul} from './Modul';

export class Semester {
  abbreviation: string;
  passedECTS: number;
  failedOnceECTS: number;
  failedMultipleTimesECTS: number;
  runningECTS: number;
  modules: Array<IModul>;

  constructor(abbreviation: string, passedECTS: number, failedOnceECTS: number, failedMultipleTimesECTS: number, runningECTS: number, modules: Array<IModul>) {
    this.abbreviation = abbreviation;
    this.passedECTS = passedECTS;
    this.failedOnceECTS = failedOnceECTS;
    this.failedMultipleTimesECTS = failedMultipleTimesECTS;
    this.runningECTS = runningECTS;
    this.modules = modules;
  }
}
