import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {FilterService} from '../../../services/filter.service';
import {Anmeldung} from '../../../models/Anmeldung';
import {NavigationService} from '../../../services/navigation.service';
import {ParseService} from '../../../services/parse.service';
import {SidenavService} from '../../../services/sidenav.service';
import {v2ModulService} from "../../services/v2modul.service";
import {V2ModuleStatus} from "../../models/V2ModuleStatus";
import {V2Attempt} from "../../models/V2Attempt";
import {V2Module} from "../../models/V2Module";
import {V2AttemptStatus} from "../../models/V2AttemptStatus";

@Component({
  selector: 'app-v2modul',
  templateUrl: './v2modul.component.html'
})
export class V2modulComponent implements OnChanges {

  @Input() module?: V2Module;
  @Input() anmeldung?: Anmeldung;
  @Input() timeline?: boolean;
  @Input() allAttempts?: Array<V2Attempt>; // necessary, because Type V2Module has no Array of attempts.
  @Input() currentAttempt?: V2Attempt;
  @Input() status?: V2ModuleStatus;
  @Input() title?: string;
  @Input() isProject?: boolean;
  @Input() isInSidenav = false;
  public moduleHeight = '';


  constructor(
    public parseService: ParseService,
    public filterService: FilterService,
    public v2moduleService: v2ModulService,
    public naviService: NavigationService,
    public sidenavService: SidenavService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.module) {
      this.moduleHeight = v2ModulService.calcModuleHeight(this.module!, this.timeline, this.isProject) + 'px';
    }
  }

  //Todo: Implement this
  private parseModul(modul: V2Module): V2Module {
    return null;
  }

  public displayAbbreviation(modul: V2Module): string {
    if (modul!.abbreviation) {
      return modul!.abbreviation;
    }  else {
      return '';
    }
  }

  highlightModule(): boolean {
    let val = true;
    if (this.sidenavService.getSelectedModules().length > 0) {
      val = this.isDependingModuleOfSelectedModule();
    } else {
      if (this.v2moduleService.searchInput.length > 0) {
        val = this.modulIsInSearch();
      } else {
        val = this.v2moduleService.displayTypes(this.module!);
      }
    }
    return val;
  }

  modulIsInSearch(): boolean {
    return this.v2moduleService.searchInput.length > 0 ? this.v2moduleService.modulAbbreviationsSearchArray.getValue().includes(this.module.abbreviation) : true;
  }

  isDependingModuleOfSelectedModule(): boolean {
    return this.sidenavService.getSelectedModules().some(mod =>
      mod.dependingModules.filter(dep => dep.abbreviation === this.module.abbreviation)
    );
  }

  public displayTriangle(): boolean {
    if (!this.naviService.getCurrentNavigation().includes('timeline')) {
      if (this.filterService.getShowFilter('Fehlgeschlagen')) {
        return (this.allAttempts?.length! > 1 && this.allAttempts?.some(att => att.status == V2AttemptStatus.FAILED)
          && [V2AttemptStatus.RUNNING, V2ModuleStatus.PASSED,].includes(this.allAttempts![0].status!))!;
      } else
        return false;
    }
    return false;
  }
}
