import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ThemeService} from "../../../../services/theme.service";
import {ModulService} from "../../../../services/modul.service";
import {ParseService} from "../../../../services/parse.service";
import {View} from "../../../../views/views";
import {v2ModulService} from "../../../services/v2modul.service";

@Component({
  selector: 'app-v2module-search',
  templateUrl: './v2module-search.component.html',
  styleUrls: ['./v2module-search.component.scss']
})
export class v2ModuleSearchComponent implements OnInit {

  @ViewChild('input') moduleSearchInputField: ElementRef;

  constructor(
    public themeService: ThemeService,
    public v2moduleService: v2ModulService,
    public moduleService: ModulService,
    private parseService: ParseService
  ) {
  }

  ngOnInit(): void {
  }

  public searchModule(event: any): void {
    if (this.parseService.currentView.getValue() === View.TREE) {
      setTimeout(() => {
        this.moduleService.searchModul(event);
      }, 100);
    } else {
      this.v2moduleService.searchModul(event);
      this.moduleService.searchModul(event);// TODO remove once modulteppich and modultree are also v2
    }
  }

  public clearSearch(): void {
    this.moduleSearchInputField.nativeElement.value = '';
    this.v2moduleService.clearSearchData()
    this.moduleService.clearSearchData();// TODO remove once modulteppich and modultree are also v2
  }
}
