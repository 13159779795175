import {AfterViewChecked, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ModulteppichDirective} from '../modulteppich.directive';
import {SidenavRightService} from 'src/app/services/sidenav-right.service';
import {ModulService} from '../../../services/modul.service';
import {FilterService} from '../../../services/filter.service';
import {ParseService} from '../../../services/parse.service';
import {ModulgroupService} from '../../../services/modulgroup.service';
import {IModul, Modul} from '../../../models/Modul';
import {ProfilierungService} from "../../../services/profilierung.service";

@Component({
  selector: 'app-modulteppich-elektro-und-informationstechnik',
  templateUrl: './modulteppich-elektro-und-informationstechnik.component.html',
  styleUrls: ['../modulteppich.component.scss'],
})
export class ModulteppichElektroUndInformationstechnikComponent extends ModulteppichDirective implements OnInit, AfterViewChecked {

  @ViewChild('fachausbildung') fachausbildung?: ElementRef;
  public contHeight = '';
  public vertiefungen: Array<IModul> = [];
  public modulTree: Array<IModul> = [];
  sortByPosition = ParseService.sortByPosition;
  calculateMinGroupECTS = ParseService.calculateMinGroupECTS;

  constructor(
    sideNavRightService: SidenavRightService,
    parseService: ParseService,
    modulService: ModulService,
    filterService: FilterService,
    profilierungService: ProfilierungService,
    public modulgroupService: ModulgroupService
  ) {
    super(sideNavRightService, parseService, modulService, filterService, profilierungService);
  }

  ngOnInit(): void {
    this.modulTree = this.parseService.getModuleTree();
    // Regroups vertiefungen into one array for displaying them in modulteppich at correct position
    this.parseService.getFlattenedModuleTree()
      .filter(modul => modul.bezeichnung?.toLowerCase().includes('fachver'))
      .forEach(vertiefung => vertiefung.modules?.forEach(modul2 => modul2.modules?.forEach(m => this.vertiefungen.push(m))));
  }

  ngAfterViewChecked(): void {
    this.defineHeight();
  }

  private defineHeight(): void {
    this.contHeight = this.fachausbildung?.nativeElement.offsetHeight;
  }

  columnSpan(module: Modul): string {
    if (module.modules![0]?.abbreviation?.includes('mev')) {
      return '1 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('spe')) {
      return '1 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('ele')) {
      return '1 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('dya')) {
      return '1 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('rads')) {
      return '1 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('mst')) {
      return '1 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('eet1')) {
      return '1 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('eet2')) {
      return '1 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('lea1')) {
      return '1 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('lea2')) {
      return '1 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('rt')) {
      return '1 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('dsv')) {
      return '3 / span 4';
    } else if (module.modules![0]?.abbreviation?.includes('kom')) {
      return '3 / span 4';
    } else if (module.modules![0]?.abbreviation?.includes('dcom')) {
      return '3 / span 4';
    } else if (module.modules![0]?.abbreviation?.includes('dst')) {
      return '3 / span 4';
    } else if (module.modules![0]?.abbreviation?.includes('ast')) {
      return '3 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('mxst')) {
      return '3 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('dsv')) {
      return '5 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('kom')) {
      return '5 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('dcom')) {
      return '5 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('dst')) {
      return '5 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('mspr')) {
      return '5 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('ebssd')) {
      return '5 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('enau')) {
      return '7 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('aces')) {
      return '7 / span 2';
    } else {
      return '';
    }
  }

  rowSpan(module: Modul): string {
    if (module.modules![0]?.abbreviation?.includes('mev')) {
      return '1 / 2';
    } else if (module.modules![0]?.abbreviation?.includes('spe')) {
      return '2 / 3';
    } else if (module.modules![0]?.abbreviation?.includes('ele')) {
      return '3 / 4';
    } else if (module.modules![0]?.abbreviation?.includes('dya')) {
      return '4 / 5';
    } else if (module.modules![0]?.abbreviation?.includes('rads')) {
      return '5 / 6';
    } else if (module.modules![0]?.abbreviation?.includes('mst')) {
      return '6 / 7';
    } else if (module.modules![0]?.abbreviation?.includes('eet1')) {
      return '7 / 8';
    } else if (module.modules![0]?.abbreviation?.includes('eet2')) {
      return '8 / 9';
    } else if (module.modules![0]?.abbreviation?.includes('lea1')) {
      return '9 / 10';
    } else if (module.modules![0]?.abbreviation?.includes('lea2')) {
      return '10 / 11';
    } else if (module.modules![0]?.abbreviation?.includes('rt')) {
      return '11 / 12';
    } else if (module.modules![0]?.abbreviation?.includes('dsv')) {
      return '1 / 2';
    } else if (module.modules![0]?.abbreviation?.includes('kom')) {
      return '2 / 3';
    } else if (module.modules![0]?.abbreviation?.includes('dcom')) {
      return '3 / 4';
    } else if (module.modules![0]?.abbreviation?.includes('dst')) {
      return '4 / 5';
    } else if (module.modules![0]?.abbreviation?.includes('ast')) {
      return '5 / 6';
    } else if (module.modules![0]?.abbreviation?.includes('mxst')) {
      return '6 / 7';
    } else if (module.modules![0]?.abbreviation?.includes('dsv')) {
      return '1 / 2';
    } else if (module.modules![0]?.abbreviation?.includes('kom')) {
      return '2 / 3';
    } else if (module.modules![0]?.abbreviation?.includes('dcom')) {
      return '3 / 4';
    } else if (module.modules![0]?.abbreviation?.includes('dst')) {
      return '6 / 6';
    } else if (module.modules![0]?.abbreviation?.includes('mspr')) {
      return '5 / 6';
    } else if (module.modules![0]?.abbreviation?.includes('ebssd')) {
      return '6 / 7';
    } else if (module.modules![0]?.abbreviation?.includes('enau')) {
      return '1 / 2';
    } else if (module.modules![0]?.abbreviation?.includes('aces')) {
      return '2 / 3';
    } else {
      return '';
    }
  }
}
