import {Injectable} from '@angular/core';
import {LocalStorageConfig} from '../config/LocalStorageConfig';
import {FilterConfigEntry} from '../models/FilterConfigEntry';
import {LocalStorageService} from './local-storage.service';
import {Studiengang} from '../models/Studiengaenge';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  public config: LocalStorageConfig = LocalStorageConfig.empty();

  constructor(
    private localStorageService: LocalStorageService
  ) {
  }

  /**
   * Returns an array of all enabled filters
   */
  getEnabledFilters(): Array<[string, FilterConfigEntry]> {
    return Array.from(this.config.filters).filter(filter => filter[1].on);
  }

  /**
   * @param filterName
   * Resets a certain filter (disables it)
   */
  resetFilter(filterName: string): void {
    this.config.filters.get(filterName)?.turnOff();
    this.localStorageService.config.next(this.config);
  }

  /**
   * Resets all filters (disables them)
   */
  resetAllFilters(): void {
    this.config.filters.forEach(filter => filter.turnOff());
    this.localStorageService.config.next(this.config);
  }

  /**
   * Resets the filters that do not belong to the current Studiengang
   */
  resetNonStudiengangFilters(studiengang: Studiengang): void {
    this.getEnabledFilters()
      .filter(filter => !filter[1].type.includes(studiengang))
      .filter(filter => !filter[1].type.includes(Studiengang.NoMatch))
      .forEach(filter => this.resetFilter(filter[0]));
  }

  getShowFilter = (filterName: string) => this.config.filters.get(filterName)?.on!;
}
