<ng-container *ngIf="modulGroup">
  <div class="modulgroup-status" *ngIf="status.status"
       [style.color]="status.color"
       [style.bottom]="bottom + 'px'"
       [style.left]="left + 'px'"
       [style.display]="status.symbol === '' ? 'none' : 'flex'"
       [style.border]="'2px solid ' + status.color"
       [ngClass]="getPosition()">{{status.symbol}}
  </div>
</ng-container>
