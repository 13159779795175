<div class="side-nav-title">
  <span class="side-nav-label">Studienfortschritt</span>
</div>
<app-progress-bar
  [title]="'Total ECTS'"
  [label]="parseService.passedModules + ' Module'"
  [color]="'var(--passed-color)'"
  [max]="minBachelorECTS"
  [value]="parseService.earnedECTS">
</app-progress-bar>
<div class="side-nav-anrechnungen display6 accent-grey"
     *ngIf="parseService.getAngerechneteModule().length > 0">
  <div *ngIf="parseService.getAngerechneteModule().length > 0">
    Davon angerechnet: {{ angerechneteModule }} Module/{{ angerechneteECTS }} ECTS
  </div>
  <div *ngIf="parseService.pauschalAnrechnungsECTS > 0">
    Davon Gruppenanrechnungen: {{ pauschalAnrechnungen }} ECTS
  </div>
</div>
<div class="space"></div>
<ng-container *ngIf="parseService.getStudiengang() !== studiengang.DS">
  <app-progress-bar
    [title]="'Assessment ECTS'"
    [label]="parseService.assessModules + ' Module'"
    [color]="'var(--accent-blue)'"
    [max]="minAssessmentECTS"
    [value]="parseService.assessmentECTS">
  </app-progress-bar>
  <div *ngIf="parseService.assessmentECTS >= minAssessmentECTS"
       style="margin: -20px 32px 0 32px" class="display6">
    <div>Assessment bestanden</div>
  </div>
</ng-container>
<ng-container *ngIf="parseService.getStudiengang() === studiengang.DS">
  <app-progress-bar
    [title]="'Assessment'"
    [label]="parseService.assessmentModulesDSGroup1 + parseService.assessmentModulesDSGroup2 + ' Module'"
    [color]="'var(--accent-blue)'"
    [max]="27"
    [value]="(parseService.assessmentECTSDSGroup1 < 15 ? parseService.assessmentECTSDSGroup1 : 15) +
              (parseService.assessmentECTSDSGroup2 < 12 ? parseService.assessmentECTSDSGroup2 : 12)">)
  </app-progress-bar>
  <div style="display: flex; flex-direction: column;">
    <app-progress-bar
      [float]="'right'"
      [isSubBar]="true"
      [textMarginTop]="'10px'"
      [marginTop]="'-30px'"
      [displayMax]="false"
      [label]="'KG 1+2: ' + (parseService.assessmentECTSDSGroup1 >= 15 ? 15 : parseService.assessmentECTSDSGroup1) + '/15'"
      [color]="'var(--accent-blue)'"
      [max]="15"
      [value]="parseService.assessmentECTSDSGroup1">
    </app-progress-bar>
    <app-progress-bar
      [float]="'right'"
      [isSubBar]="true"
      [textMarginTop]="'10px'"
      [marginTop]="'0'"
      [marginBottom]="'0'"
      [displayMax]="false"
      [label]="'KG 3-7: ' + (parseService.assessmentECTSDSGroup2 >= 12 ? 12 : parseService.assessmentECTSDSGroup2) + '/12'"
      [color]="'var(--accent-blue)'"
      [max]="12"
      [value]="parseService.assessmentECTSDSGroup2">
    </app-progress-bar>
    <div *ngIf="parseService.assessmentECTSDSGroup1 + parseService.assessmentECTSDSGroup2 >= 27"
         style="margin: 10px 32px 0 32px;"
         class="display6">
      <div style="float: right;">Assessment bestanden</div>
    </div>
  </div>
</ng-container>
<div class="space"></div>

<app-progress-bar
  [title]="'Fehlgeschlagene ECTS'"
  [label]="parseService.failedModules + ' Module'"
  [color]="'var(--failed-color)'"
  [max]="maxFailedECTS"
  [value]="parseService.failedECTS">
</app-progress-bar>
<div class="space"></div>
<div class="side-nav-row">
  <div class="display5">Eingeschriebene ECTS</div>
  <div class="display5 accent-blue">{{ parseService.runningECTS }}</div>
</div>
<div class="side-nav-row">
  <div class="display5">
    Eingeschriebene Module
  </div>
  <div class="display5 accent-blue">
    {{ parseService.runningModules }}
  </div>
</div>
<div class="side-nav-title">
  <span class="side-nav-label">Legende</span>
</div>
<div class="space"></div>
<app-legend></app-legend>
