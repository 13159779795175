import {Component, Input} from '@angular/core';
import {FilterService} from '../services/filter.service';
import {ModulService} from '../services/modul.service';
import {IModul} from '../models/Modul';
import {Attempt, IAttempt} from '../models/Attempt';
import {ParseService} from '../services/parse.service';
import {Modulstatus} from '../models/Modulstatus';
import {NavigationService} from '../services/navigation.service';

@Component({
  selector: 'app-modul-attributes',
  templateUrl: './modul-attributes.component.html',
  styleUrls: ['./modul-attributes.component.scss']
})
export class ModulAttributesComponent {

  @Input() modul?: IModul;
  @Input() attempt?: IAttempt;
  @Input() isTimeline = this.navigationService.getCurrentNavigation().includes('timeline');

  constructor(
    public filterService: FilterService,
    public modulService: ModulService,
    public parseService: ParseService,
    public navigationService: NavigationService
  ) {
  }

  showGrades(modul?: IModul, attempt?: Attempt): string {
    let grade = '';
    const att = attempt ? attempt : modul?.attempts ? modul.attempts![0] : '';
    if (att) {
      if (att.finalGrade && !isNaN(parseFloat(att.finalGrade.toString()))) { // use noteBezeichnung as freieNote might contain dummy grade from Testat
        const numGrade = parseFloat(att.finalGrade.toString());
        grade = this.filterService.getShowFilter('Noten Runden') ? ParseService.roundGradeToHalf(numGrade) : ParseService.roundGrade(numGrade);
      } else if (modul?.attempts![0].status === Modulstatus.PASSED) {
        grade = '✓';
      } else if (modul?.attempts![0].status === (Modulstatus.FAILED || Modulstatus.LOCKED)) { // bestanden is set to "null" if no grade is available
        grade = '✗';
      }
    }
    return grade;
  }

  showECTS(module?: IModul): string {
    return module!.modul && module!.modul?.ects ?
      module!.modul!.ects.toString() :
      module!.modules![0].modul!.ects!.toString();
  }

  displayModuleCredited(modul?: IModul): string {
    let modulStatus = '';
    if (this.filterService.getShowFilter('Angerechnet')) {
      if (modul?.angerechnet) {
        modulStatus = 'angr.';
      }
    }
    return modulStatus;
  }

  displayAssessmentModule(modul?: IModul): string {
    let modulStatus = '';
    if (this.filterService.getShowFilter('Assessment')) {
      if (modul?.abbreviation) {
        const findActiveModulteppich = ParseService.AVAILABLE_STUDIENGAENGE
          .find(modulteppich =>
            modulteppich.getStudiengangIds().includes(this.parseService.getActiveStudienJahrgang().studiengangId!));
        if (findActiveModulteppich?.getAssessment().includes(modul.abbreviation)) {
          modulStatus = 'A';
        }
      }
    }
    return modulStatus.trim();
  }

  displayModuleMitMSP(modul?: IModul): string {
    let modulStatus = '';
    if (this.filterService.getShowFilter('MSP')) {
        if (modul?.hasMSP) {
          modulStatus = 'M';
        }
    }
    return modulStatus.trim();
  }

  attributeColorModuleRunning(modul: IModul, attempt?: IAttempt): string {
    const filterLaufend = this.filterService.getShowFilter('Laufend');
    if (attempt) {
      if (attempt.status === Modulstatus.RUNNING && filterLaufend) {
          return 'running-color-white';
      } else {
        return 'running-color';
      }
    } else {
      if (modul && modul.attempts && modul.attempts[0] && modul.attempts[0].status === Modulstatus.RUNNING && filterLaufend) {
          return 'running-color-white';
      } else {
        return 'running-color';
      }
    }
  }
}
