import {Component, OnInit} from '@angular/core';
import {ModulteppichDirective} from '../modulteppich.directive';
import {SidenavRightService} from '../../../services/sidenav-right.service';
import {ParseService} from '../../../services/parse.service';
import {ModulService} from '../../../services/modul.service';
import {FilterService} from '../../../services/filter.service';
import {ModulgroupService} from '../../../services/modulgroup.service';
import {IModul} from '../../../models/Modul';
import {ProfilierungService} from "../../../services/profilierung.service";

@Component({
  selector: 'app-modulteppich-data-science',
  templateUrl: './modulteppich-data-science.component.html',
  styleUrls: ['../modulteppich.component.scss']
})
export class ModulteppichDataScienceComponent extends ModulteppichDirective implements OnInit {
  sortByNiveau = ParseService.sortByNiveau;
  sortByPosition = ParseService.sortByPosition;
  calculateMinGroupECTS = ParseService.calculateMinGroupECTS;
  calculateMaxGroupECTS = ParseService.calculateMaxGroupECTS;
  public modulTree: Array<IModul> = [];

  constructor(
    sideNavRightService: SidenavRightService,
    parseService: ParseService,
    modulService: ModulService,
    filterService: FilterService,
    profilierungService: ProfilierungService,
    public modulgroupService: ModulgroupService
  ) {
    super(sideNavRightService, parseService, modulService, filterService, profilierungService);
  }

  ngOnInit(): void {
    this.modulTree = this.parseService.getModuleTree();
  }
}
