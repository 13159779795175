import {Injectable} from '@angular/core';
import {IModul} from '../models/Modul';
import {Bubblestatus} from '../models/Bubblestatus';
import {Modulstatus} from '../models/Modulstatus';
import {FilterService} from './filter.service';
import {ParseService} from './parse.service';

@Injectable({
  providedIn: 'root'
})
export class ModulgroupService {

  constructor(
    private filterService: FilterService,
    private parseService: ParseService
  ) {
  }

  /**
   * Maps Projects to 6 Dummy-projects, if there is an Anmeldung then the Dummy-project is being replaced with the one with an Anmeldung is put in its place instead
   * @param projects - Takes an array of IModul
   * @public
   */
  public mapProject(projects: Array<IModul>): Array<IModul> {
    const attemptedProjects = projects.filter(p => p.modules && p.modules[0] && p.modules![0].attempts!.length > 0);
    const mappedAttemptedProjects: Array<{ name: string, modul: IModul, weight: number }> = [];
    const dummyProjects = this.fillDummyProjects(projects);
    const dummyProjectsCopy = dummyProjects;
    attemptedProjects.forEach(p => mappedAttemptedProjects.push({
      name: p.bezeichnung!.substring(0, 9),
      modul: p,
      weight: dummyProjects.find(pr => pr.name.toLowerCase() === p.bezeichnung!.substring(0, 9).toLowerCase())!.weight // sets weight to project from dummyModule if name matches
    }));
    mappedAttemptedProjects.forEach(p => {
      dummyProjects.forEach((dp, index) => {
        if (p.name.toLowerCase() === dp.name.toLowerCase()) {
          dummyProjectsCopy.splice(index, 1);
        }
      });
    });
    return dummyProjectsCopy
      .concat(mappedAttemptedProjects)
      .sort((a, b) => b.weight - a.weight) // sort projects by weight
      .sort((a, b) => a.weight === b.weight
        ? a.modul.bezeichnung!.localeCompare(b.modul.bezeichnung!)
        : b.weight - a.weight)
      .map(p => p.modul);
  }

  /**
   * Sets up Dummy-projects
   * @param projects - Takes an array of IModul
   * @public
   */
  public fillDummyProjects(projects: Array<IModul>): Array<{ name: string, modul: IModul, weight: number }> {
    const pr: Array<{ name: string, modul: IModul, weight: number }> = [
      {name: 'Projekt 1', modul: {} as IModul, weight: 1},
      {name: 'Projekt 2', modul: {} as IModul, weight: 2},
      {name: 'Projekt 3', modul: {} as IModul, weight: 3},
      {name: 'Projekt 4', modul: {} as IModul, weight: 4},
      {name: 'Projekt 5', modul: {} as IModul, weight: 5},
      {name: 'Projekt 6', modul: {} as IModul, weight: 6}
    ];
    return pr.map(p => {
      projects.forEach(proj => {
        if (proj.bezeichnung?.toLowerCase()?.includes(p.name.toLowerCase())) {
          p.modul = proj;
        }
      });
      return p;
    });
  }

  /**
   * Evaluates the status of the Bubble of a Modulgroup
   * @param modulGroup - Takes a Modulgroup of type IModul
   * @param defaultColor - Takes the default color as string
   * @public
   */
  public calcBubbleStatus(modulGroup: IModul, defaultColor: string): Bubblestatus {
    const bubbleStatus = new Bubblestatus(Modulstatus.EMPTY, defaultColor, '');

    const showPassed = this.filterService.getShowFilter('Bestanden');
    const showFailed = this.filterService.getShowFilter('Fehlgeschlagen');

    if (showFailed) {
      this.calculateBubbleStatusFailed(modulGroup, bubbleStatus);
      if (bubbleStatus.status != Modulstatus.LOCKED){ //if the group is not failed, it can still be critical
        this.calculateBubbleStatusCritical(modulGroup, bubbleStatus);
      }
    }

    if (showPassed) {
      this.calculateBubbleStatusPassed(modulGroup, bubbleStatus);
    }

    return bubbleStatus;
  }


  public calculateBubbleStatusPassed(modulgroup: IModul, bubbleStatus: Bubblestatus): void {
    if (this.parseService.calculatePassedGroupECTS(modulgroup) !== 0 && this.parseService.calculatePassedGroupECTS(modulgroup) >= modulgroup.minECTS!) {
      bubbleStatus.status = Modulstatus.PASSED;
      bubbleStatus.color = '#65B983';
      bubbleStatus.symbol = '✓';
    }
  }

  public calculateBubbleStatusCritical(modulgroup: IModul, bubbleStatus: Bubblestatus): void {
    if (ParseService.calculateFailedGroupECTS(modulgroup) >= (modulgroup.minECTS! / 2) && !(this.parseService.calculatePassedGroupECTS(modulgroup) >= modulgroup.minECTS!)) {
      bubbleStatus.status = Modulstatus.FAILED;
      bubbleStatus.color = '#F9C94D';
      bubbleStatus.symbol = '!';
    }
  }

  public calculateBubbleStatusFailed(modulgroup: IModul, bubbleStatus: Bubblestatus): void {
    let passedEcts = this.parseService.calculatePassedGroupECTS(modulgroup);
    let remainingEctsNeeded = Math.max(0, modulgroup.minECTS! - passedEcts);

    if (ParseService.calculateAvailableGroupECTS(modulgroup) < remainingEctsNeeded) {
      bubbleStatus.status = Modulstatus.LOCKED;
      bubbleStatus.color = '#ffaaaa';
      bubbleStatus.symbol = '✗';
    }
  }

  public defineModulgroupBorder(group: IModul, color: string): string {
    return '2px solid ' + this.calcBubbleStatus(group, color).color;
  }
}
