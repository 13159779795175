import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'semesterPipe'})
export class SemesterPipe implements PipeTransform {
  transform(semesterAbbreviation: string): string {
    if (4 !== semesterAbbreviation?.length) {
      return '';
    }

    const year = semesterAbbreviation.substring(0, 2);
    const semester = semesterAbbreviation.substring(2, 4);

    if (isNaN(Number(year))) {
      return '';
    }

    const semesterMap = new Map([
      ['FS', 'Frühlings'],
      ['HS', 'Herbst']
    ]);
    if (semesterMap.has(semester)) {
      return `${semesterMap.get(semester)}semester 20${year}`;
    }

    return '';
  }
}
