import { Component, OnInit } from '@angular/core';
import {ParseService} from "../../../../services/parse.service";

@Component({
  selector: 'app-v2study-overview',
  templateUrl: './v2study-overview.component.html',
})
export class v2StudyOverviewComponent implements OnInit {

  constructor(
    public parseService: ParseService
  ) {
  }

  ngOnInit(): void {
  }

}
