import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {StudentWithStudienjahrgangInfo} from "../../../models/StudentWithStudienjahrgangInfo";
import {IStudent, Student} from "../../../models/Student";
import {getFormattedStatus} from "../admin-util";
import {ParseService} from "../../../services/parse.service";
import {StudierendeWithAnmeldungen} from "../../../models/StudierendeWithAnmeldungen";
import {ThemeService} from "../../../services/theme.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-student-list',
  templateUrl: './student-list.component.html',
  styleUrl: './student-list.component.scss'
})
export class StudentListComponent  {

  @Input() loading: boolean = true;
  @Input() hasError: boolean = false;
  @Input() errorMessage: string = '';
  @Input() studentsWithAnmeldungen: Array<StudierendeWithAnmeldungen> = [];

  @Input() filterChanged: any;
  @Input() filtersDisabled : boolean = true;

  studentFilterValue: string = '';

  @ViewChild('studentFilterInput') studentFilterInput: ElementRef;

  attribute: string = '';

  private isSortAscending: boolean = true;

  constructor(
    public themeService: ThemeService,
    private router: Router,
    public parseService: ParseService) {
  }

    /**
   * Checks whether the search matches a student
   */
  public studentIsInFilterSearch(student: Student): boolean {
    const {vorname, nachname, emailEducation, studierendeId} = student;
    const studentWithSjgInfo = this.studentsWithAnmeldungen.find(s => s.student.studierendeId === studierendeId);
    const sjgDetails = studentWithSjgInfo?.studienjahrgangInfo?.bezeichnung;
    const status = getFormattedStatus(studentWithSjgInfo);
    const filterTerm = this.studentFilterValue.toLowerCase();

    return [vorname, nachname, emailEducation, sjgDetails, status, studierendeId]
      .filter(a => a !== undefined)
      .map(a => typeof a === 'string' ? a.toLowerCase() : `${a}`)
      .some(a => a.includes(filterTerm));
  }

  filteredStudentsWithStudienjahrgangInfo() {
    return this.studentsWithAnmeldungen.filter(studiWithSJAInf =>
      this.studentIsInFilterSearch(studiWithSJAInf.student)
    );
  }

  public selectStudent(student: StudierendeWithAnmeldungen): void {
    if (student.student) {
      this.router.navigate([`/student/${student.student.studierendeId!.toString()}/studienjahrgang/${student.studienjahrgangInfo.studienjahrgangId}`]);
      this.parseService.setLoading(true);
    }
  }

  public parseStudiengangAnmeldungStatus(student: StudierendeWithAnmeldungen): string {
    return getFormattedStatus(student);
  }

  public toggleStudentAttributeSort(attribute: string): void {
    this.isSortAscending = !this.isSortAscending;
    this.isSortAscending ? this.sortStudentsByStudentAttributeAscending(attribute) : this.sortStudentsByStudentAttributeDescending(attribute);
  }

  private sortStudentsByStudentAttributeAscending(attribute: string): void {
    this.isSortAscending = true;
    this.attribute = attribute;
    this.sortStudentsByAttribute();
  }

  private sortStudentsByStudentAttributeDescending(attribute: string): void {
      this.attribute = attribute;
      this.isSortAscending = false;
    this.sortStudentsByAttribute();
  }

  public emitStudentFilterValue(): void {
    this.studentFilterValue = (this.studentFilterInput.nativeElement.value);
  }

    /*
    * Sorts an array of StudentWithStudienjahrgangInfo by a student attribute
    * Doesn't sort attributes of StudienjahrgangInfo
    * */
    private sortStudentsByAttribute(): void {
        const attribute = this.attribute;
        const ascending = this.isSortAscending;
        if (ascending) {
            this.studentsWithAnmeldungen.sort((a: any, b: any) => (a[attribute] ? a[attribute] : a.student[attribute]) > (b[attribute] ? b[attribute] : b.student[attribute]) ? 1 : -1);
        } else {
            this.studentsWithAnmeldungen.sort((a: any, b: any) => (a[attribute] ? a[attribute] : a.student[attribute]) <( b[attribute] ? b[attribute]: b.student[attribute]) ? 1 : -1);
        }
    }


}
