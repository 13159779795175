/**
 * Base component for correctly handling OnDestroy. If this is not done correctly it may produce a memory leak<br>
 * This Component should be implemented and used any time we use an infinite Observable i.e a BehaviourSubject.<br>
 * NOTE: Http-Request for example are finite and therefore do not require to be handled.<br>
 * Generally every component, service etc. can be destroyed so if not sure it is better to handle it.
 * @see https://angular.io/api/core/OnDestroy
 */
import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

// TODO some old classes do not yet implement this or similar logic and should be updated once encountered.
@Injectable()
export abstract class OnDestroyComponent implements OnDestroy {
  protected destroyed = new Subject<void>();

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }

  protected handleDestroy = <T>(observable: Observable<T>) => observable.pipe(takeUntil(this.destroyed));
}
