import {AfterViewChecked, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ParseService} from '../../services/parse.service';
import {NavigationService} from '../../services/navigation.service';
import {Semester} from '../../models/Semester';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit, AfterViewChecked {

  public gridSize = 0;
  public semesterMaxECTS = 0;
  public mostCreditsSemester: Semester = {} as Semester;

  public height = 0;
  @ViewChild('timeline') timeline?: ElementRef;

  constructor(
    public parseService: ParseService,
    public navigationService: NavigationService
  ) {
  }

  ngOnInit(): void {
    this.parseService.setSemesters();
    this.mostCreditsSemester = this.findMostECTSSemester();
    this.compareMaxECTSInSemester();
  }

  ngAfterViewChecked(): void {
    this.getHeight();
  }

  getHeight(): void {
    this.height = this.timeline?.nativeElement.offsetHeight;
  }

  /**
   * Set semesterMaxECTS to the sum, of the semester with the most ECTS
   * If anrechnungsECTS are bigger, it takes that one
   */
  compareMaxECTSInSemester(): void {
    this.semesterMaxECTS = 0;
    if (this.parseService.getSemesters().length === 0) {
      this.semesterMaxECTS = this.parseService.anrechnungsECTS;
    } else {
      const mostSemesterECTS = this.sumOfECTSPerSemester(this.mostCreditsSemester);
      if (mostSemesterECTS > this.parseService.anrechnungsECTS) {
        this.semesterMaxECTS = mostSemesterECTS;
      } else {
        this.semesterMaxECTS = this.parseService.anrechnungsECTS;
      }
    }
  }

  /**
   * Returns the total ECTS of a semester
   */
  sumOfECTSPerSemester(semester: Semester): number {
    return (semester.passedECTS + semester.failedOnceECTS + semester.failedMultipleTimesECTS + semester.runningECTS);
  }

  /**
   * Returns the semester with the most ECTS
   */
  findMostECTSSemester(): Semester {
    return this.parseService.getSemesters().reduce((prev, current) => (this.sumOfECTSPerSemester(prev) > this.sumOfECTSPerSemester(current)) ? prev : current, this.parseService.getSemesters()[0]);
  }

  setGridSize(event: any): void {
    this.gridSize = event;
  }
}
