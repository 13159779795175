import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Constants} from '../models/Constants';
import {environment} from '../../environments/environment';
import {AppComponent} from '../app.component';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

  @Input() slides: any[] = [];
  @Input() hidden = true;
  @Input() isOnBoarding?: boolean;
  @Input() isModulTreeModal?: boolean;
  @Input() content?: string;
  @Output() hiddenChange = new EventEmitter<boolean>();

  public emailAddress = Constants.STUDENTHUB_EMAIL_ADDRESS;
  readonly environment = environment;

  ngOnInit(): void {
    document.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        this.hide();
      }
    });
  }

  /**
   * Hides the modal.
   */
  hide(): void {
    AppComponent.setHasSeenInfoModal();
    this.hidden = true;
    this.hiddenChange.emit(this.hidden);
  }
}
