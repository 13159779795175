<div class="optometrie-container">
  <div class="header-container-3-8">
    <app-modulteppich-header></app-modulteppich-header>
  </div>
  <ng-container *ngFor="let group of modulTree">
    <div *ngIf="group.bezeichnung!.trim() === 'Projekte'"
         [style.border]="'2px solid ' + modulgroupService.calcBubbleStatus(group, '#00D0CD').color"
         class="informatik-modulteppich-projekte-container">
      <app-modulgroup class="modulgroup"
                      [optometrie]="true"
                      [modulGroup]="group"
                      [isProject]="true">
      </app-modulgroup>
    </div>
    <div *ngIf="group.bezeichnung === 'Fachausbildung'"
         [style.border]="modulgroupService.defineModulgroupBorder(group, '#2094EC')"
         class="fachausbildung-optometrie-container">
      <app-status-bubble
        [hidden]="!(filterService.getShowFilter('Bestanden') || filterService.getShowFilter('Fehlgeschlagen'))"
        [modulGroup]="group"
        [status]="modulgroupService.calcBubbleStatus(group, '#2094EC')">
      </app-status-bubble>
      <app-modulgroup-title [modulGroup]="group"></app-modulgroup-title>
      <app-ects [displayMinECTS]="false"
                [passedGroupECTS]="parseService.calculatePassedGroupECTS(group)"
                [minGroupECTS]="calculateMinGroupECTS(group)"
                [modulGroup]="group">
      </app-ects>
      <div #fachausbildung class="optometrie-modulteppich-container">
        <ng-container *ngFor="let subgroup of group.modules">
          <ng-container *ngIf="subgroup.bezeichnung === 'Fachergänzung'">
            <div [style.border]="modulgroupService.defineModulgroupBorder(subgroup, '#2094EC')">
              <app-status-bubble
                [hidden]="!(filterService.getShowFilter('Bestanden') || filterService.getShowFilter('Fehlgeschlagen'))"
                [modulGroup]="subgroup"
                [status]="modulgroupService.calcBubbleStatus(subgroup, '#2094EC')">
              </app-status-bubble>
              <app-modulgroup-title [modulGroup]="subgroup"></app-modulgroup-title>
              <app-ects [displayMinECTS]="false"
                        [passedGroupECTS]="parseService.calculatePassedGroupECTS(subgroup)"
                        [minGroupECTS]="calculateMinGroupECTS(subgroup)"
                        [modulGroup]="subgroup">
              </app-ects>
              <div style="padding: 0 10px 10px 10px;">
                <app-modulgroup [modulGroup]="subgroup"
                                [sortByAssessment]="true"
                                [sortByPosition]="true"
                                [showTitle]="false"
                                [showECTS]="false"
                                [usePadding]="false"
                                [numberOfColumns]="7"
                                [showStatusBubble]="false">
                </app-modulgroup>
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="subgroup.bezeichnung === 'Fachgrundlagen'">
            <div
              [style.border]="modulgroupService.defineModulgroupBorder(subgroup, '#2094EC')">
              <app-status-bubble
                [hidden]="!(filterService.getShowFilter('Bestanden') || filterService.getShowFilter('Fehlgeschlagen'))"
                [modulGroup]="subgroup"
                [status]="modulgroupService.calcBubbleStatus(subgroup, '#2094EC')">
              </app-status-bubble>
              <app-modulgroup-title [modulGroup]="subgroup"></app-modulgroup-title>
              <app-ects [displayMinECTS]="false"
                        [passedGroupECTS]="parseService.calculatePassedGroupECTS(subgroup)"
                        [minGroupECTS]="calculateMinGroupECTS(subgroup)"
                        [modulGroup]="subgroup">
              </app-ects>
              <div
                style="display: flex; flex-direction: row; gap: 20px; justify-content: center; padding: 0 10px 10px 10px;">
                <ng-container *ngFor="let subsubgroup of sortByPosition(subgroup.modules!)">
                  <div style="position: relative; width: 244px;"
                       [style.border]="modulgroupService.defineModulgroupBorder(subsubgroup, '#2094EC')">
                    <app-modulgroup [modulGroup]="subsubgroup"
                                    [numberOfColumns]="2"
                                    [aligningBottom]="false">
                    </app-modulgroup>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div *ngIf="group.bezeichnung === 'Kontext'"
         [style.height]="contHeight + 84 + 'px'"
         class="optometrie-modulteppich-kontext-container">
      <ng-container *ngFor="let subgroup of sortByPosition(group.modules!, {ascending: true})">
        <div [style.border]="modulgroupService.defineModulgroupBorder(subgroup, '#8E4FD0')"
             class="kontext-group flex-1-1-auto">
          <app-modulgroup class="modulgroup"
                          [optometrie]="true"
                          [maxHeightPixel]="200"
                          [spaceBetween]="true"
                          [sortByStatus]="true"
                          [modulGroup]="subgroup">
          </app-modulgroup>
        </div>
      </ng-container>
    </div>
    <div class="optometrie-freie-module-container"
         *ngIf="group.bezeichnung === 'Freie Module' && parseService.calculatePassedGroupECTS(group) > 0">
      <div [style.border]="modulgroupService.defineModulgroupBorder(group, '#8E4FD0')"
           class="kontext-group freie-module">
        <app-modulgroup class="modulgroup"
                        [optometrie]="true"
                        [spaceBetween]="true"
                        [sortByStatus]="true"
                        [modulGroup]="group">
        </app-modulgroup>
      </div>
    </div>
  </ng-container>
</div>
