<div [ngClass]="isExpanded ? 'expand-dropdown' : 'collapse-dropdown'">
  <div class="outer-triangle"  style="top: 18px">
    <div class="inner-triangle"></div>
  </div>
  <div class="dropdown-container logout-container" style="top: 30px" (click)="stopEventPropagation($event)">
    <div class="dropdown-padding">
      <span class="pointer" (click)="themeService.toggleTheme()">Dark Theme</span>
      <label class="general-preferences-switch switch">
        <input (change)="themeService.toggleTheme()" [checked]="getTheme()" type="checkbox">
        <span class="slider round"></span>
      </label>
    </div>
    <div class="dropdown-padding">
      <span class="pointer" (click)="themeService.toggleUseSystemDefaultTheme()">Sync mit System</span>
      <label class="switch">
        <input (change)="themeService.toggleUseSystemDefaultTheme()" [checked]="getSystemDefaultTheme()" type="checkbox">
        <span class="slider round"></span>
      </label>
    </div>
    <div *ngIf="themeService.isCurrentDateDuringWinter()" class="dropdown-padding">
      <span class="pointer" (click)="themeService.toggleWinterTheme()">Winter Theme</span>
      <label class="switch">
        <input (change)="themeService.toggleWinterTheme()" [checked]="themeService.displayWinterTheme()" type="checkbox">
        <span class="slider round"></span>
      </label>
    </div>
    <div class="logout-button dropdown-padding" title="Ausloggen">
      <div (click)="authService.logout()">Ausloggen</div>
      <img *ngIf="themeService.getActiveTheme(filterService.config.theme).name === 'dark'"
           (click)="authService.logout()"
           alt="logout" src="assets/icons/logout-dark.svg">
      <img *ngIf="themeService.getActiveTheme(filterService.config.theme).name === 'light'"
           (click)="authService.logout()"
           alt="logout" src="assets/icons/logout-light.svg">
    </div>
  </div>
</div>
