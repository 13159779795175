import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {IStudent, Student} from '../../../models/Student';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss']
})
export class ProfileImageComponent implements OnChanges {

  constructor(
    private sanitizer: DomSanitizer
  ) {
  }

  @Input() student!: IStudent;
  imgUrl!: SafeResourceUrl | string;
  initials!: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.student) {
      this.imgUrl = this.parseImageURL(this.student);
      this.initials = this.parseInitials(this.student);
    }
  }

  private parseInitials(student: Student): string {
    return student.vorname?.substring(0, 1)! + student.nachname?.substring(0, 1)!;
  }

  private parseImageURL(student: Student): SafeResourceUrl | string {
    if (student.bild) {
      const image = student.bild!.replace(/^"(.+(?="$))"$/, '$1');
      return this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${image}`);
    } else {
      return '';
    }
  }
}
