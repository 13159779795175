<div class="timeline-grid">
  <div class="header-container-3-8">
    <app-modulteppich-header></app-modulteppich-header>
  </div>
  <div class="modul-timeline">
    <div [ngClass]="[navigationService.scrollbarThemeClass(), 'horScroll-long']">
      <div class="modul-timeline-container" #timeline>
        <app-moduluebersicht [gridSize]="gridSize"></app-moduluebersicht>
        <app-angerechnet-timeline [maxECTSInSemester]="semesterMaxECTS"></app-angerechnet-timeline>
      </div>
      <app-ruler (gridSize)="setGridSize($event)"
                 [height]="height"
                 [maxSemesterECTS]="semesterMaxECTS">
      </app-ruler>
    </div>
    </div>
  </div>
