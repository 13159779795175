import {AfterViewChecked, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ModulteppichDirective} from '../modulteppich.directive';
import {SidenavRightService} from 'src/app/services/sidenav-right.service';
import {ModulService} from '../../../services/modul.service';
import {FilterService} from '../../../services/filter.service';
import {ParseService} from '../../../services/parse.service';
import {ModulgroupService} from '../../../services/modulgroup.service';
import {IModul, Modul} from '../../../models/Modul';
import {ProfilierungService} from "../../../services/profilierung.service";

@Component({
  selector: 'app-modulteppich-maschinenbau-2020',
  templateUrl: './modulteppich-maschinenbau-2020.component.html',
  styleUrls: ['../modulteppich.component.scss'],
})
export class ModulteppichMaschinenbau2020Component extends ModulteppichDirective implements OnInit, AfterViewChecked {

  @ViewChild('fachausbildung') fachausbildung?: ElementRef;
  public contHeight = '';
  public modulTree: Array<IModul> = [];
  sortByPosition = ParseService.sortByPosition;
  calculateMinGroupECTS = ParseService.calculateMinGroupECTS;

  constructor(
    sideNavRightService: SidenavRightService,
    parseService: ParseService,
    modulService: ModulService,
    filterService: FilterService,
    profilierungService: ProfilierungService,
    public modulgroupService: ModulgroupService
  ) {
    super(sideNavRightService, parseService, modulService, filterService, profilierungService);
  }

  ngOnInit(): void {
    this.modulTree = this.parseService.getModuleTree();
  }

  ngAfterViewChecked(): void {
    this.defineHeight();
  }

  private defineHeight(): void {
    this.contHeight = this.fachausbildung?.nativeElement.offsetHeight;
  }

  columnSpan(module: Modul): string {
    if (module.modules![0]?.abbreviation?.includes('thd4')) {
      return '1 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('flm4')) {
      return '1 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('thd3')) {
      return '1 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('flm3')) {
      return '1 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('wueb')) {
      return '1 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('aem')) {
      return '1 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('dpev2')) {
      return '3 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('dpev1')) {
      return '3 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('kt2')) {
      return '3 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('kt1')) {
      return '3 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('c1d')) {
      return '3 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('c1m')) {
      return '3 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('amat')) {
      return '5 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('apd')) {
      return '5 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('mea')) {
      return '5 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('mant1')) {
      return '5 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('am')) {
      return '5 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('veva')) {
      return '7 / span 2';
    } else {
      return '';
    }
  }

  rowSpan(module: Modul): string {
    if (module.modules![0]?.abbreviation?.includes('thd4')) {
      return '1 / 2';
    } else if (module.modules![0]?.abbreviation?.includes('flm4')) {
      return '2 / 3';
    } else if (module.modules![0]?.abbreviation?.includes('thd3')) {
      return '3 / 4';
    } else if (module.modules![0]?.abbreviation?.includes('flm3')) {
      return '4 / 5';
    } else if (module.modules![0]?.abbreviation?.includes('wueb')) {
      return '5 / 6';
    } else if (module.modules![0]?.abbreviation?.includes('aem')) {
      return '6 / 7';
    } else if (module.modules![0]?.abbreviation?.includes('dpev2')) {
      return '1 / 1';
    } else if (module.modules![0]?.abbreviation?.includes('dpev1')) {
      return '2 / 3';
    } else if (module.modules![0]?.abbreviation?.includes('kt2')) {
      return '3 / 4';
    } else if (module.modules![0]?.abbreviation?.includes('kt1')) {
      return '4 / 5';
    } else if (module.modules![0]?.abbreviation?.includes('c1d')) {
      return '5 / 6';
    } else if (module.modules![0]?.abbreviation?.includes('c1m')) {
      return '6 / 7';
    } else if (module.modules![0]?.abbreviation?.includes('amat')) {
      return '1 / 2';
    } else if (module.modules![0]?.abbreviation?.includes('apd')) {
      return '2 / 3';
    } else if (module.modules![0]?.abbreviation?.includes('mea')) {
      return '3 / 4';
    } else if (module.modules![0]?.abbreviation?.includes('mant1')) {
      return '4 / 5';
    } else if (module.modules![0]?.abbreviation?.includes('am')) {
      return '5 / 6';
    } else if (module.modules![0]?.abbreviation?.includes('veva')) {
      return '1 / 2';
    } else {
      return '';
    }
  }
}
