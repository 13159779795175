import {Modulstatus} from './Modulstatus';

export interface IAttempt {
  semester?: string;
  finalGrade?: string | number;
  MSPGrade?: string | number;
  ENGrade?: string | number;
  status?: Modulstatus;
}

export class Attempt implements IAttempt {
  public semester?: string;
  public finalGrade?: string | number;
  public MSPGrade?: string | number;
  public ENGrade?: string | number;
  public status?: Modulstatus;

  constructor({
                semester,
                finalGrade,
                MSPGrade,
                ENGrade,
                status,
              }: {
    semester?: string;
    finalGrade?: string | number;
    MSPGrade?: string | number;
    ENGrade?: string | number;
    status?: Modulstatus;
  }) {
    this.semester = semester;
    this.finalGrade = finalGrade;
    this.MSPGrade = MSPGrade;
    this.ENGrade = ENGrade;
    this.status = status;
  }
}
