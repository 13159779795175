<ng-container *ngIf="module">
  <div *ngIf="displayAbbreviation(module!) !== ''"
       class="module"
       [id]="!isInSidenav && module.abbreviation"
       (click)="moduleService.showModuleDetails($event, module!); isInSidenav && moduleService.scrollToElement(module.abbreviation)"
       [title]="title ? title : module!.bezeichnung"
       [style.width]="!timeline ? '100px' : ''"
       [style.height]="moduleHeight"
       [style.opacity]="highlightModule() ? '' : '0.2'"
       [ngClass]="[
        timeline ? 'timeline-module' : '',
        moduleService.getStatusClass(module, attempt),
        moduleService.displayDifficulty(module)
       ]">
    {{title ? title : displayAbbreviation(module!)}}
    <app-modul-attributes [attempt]="attempt" [modul]="module"></app-modul-attributes>
    <div class="triangle" *ngIf="displayTriangle(module)"></div>
  </div>
</ng-container>
