import { FilterConfigEntry } from '../models/FilterConfigEntry';
import * as FilterConfig from './FilterConfig.json';
import { ThemeConfig } from './ThemeConfig';

export class LocalStorageConfig {
  filters: Map<string, FilterConfigEntry>;
  activeStudienjahrgang: number;
  theme: ThemeConfig;
  winter: boolean;
  systemDefaultTheme: boolean;
  showGrade?: FilterConfigEntry;
  moduleSearchExecuted: boolean;

  constructor(
    activeStudienjahrgang: number,
    theme: ThemeConfig,
    winter: boolean,
    systemDefaultTheme: boolean,
    darkTheme: boolean,
    showSearchHint: boolean
  ) {
    this.activeStudienjahrgang = activeStudienjahrgang;
    this.theme = theme;
    this.winter = winter;
    this.systemDefaultTheme = systemDefaultTheme;
    this.filters = new Map<string, FilterConfigEntry>();
    (FilterConfig as any).default.forEach((category: any) => {
      category.filters.forEach((filter: any) => {
        this.filters.set(
          filter.filterName,
          new FilterConfigEntry(
            filter.filterKey,
            filter.studiengaenge,
            category.category,
            filter.showCirclePassed,
            filter.showCircleFailed,
            filter.showCircleRunning,
            filter.legend,
            filter.isMutualExclusive
          ));
      });
    });
    this.moduleSearchExecuted = showSearchHint;
  }

  static fromObject = (obj: LocalStorageConfig): LocalStorageConfig => {
    const conf: LocalStorageConfig = LocalStorageConfig.empty();
    conf.activeStudienjahrgang = obj.activeStudienjahrgang;
    conf.theme = obj.theme;
    conf.winter = obj.winter;
    conf.systemDefaultTheme = obj.systemDefaultTheme;
    conf.filters = new Map<string, FilterConfigEntry>();
    for (const a of Object.entries(obj.filters)) {
      const fc: FilterConfigEntry = new FilterConfigEntry(
        a[1].name,
        a[1].type,
        a[1].category,
        a[1].showCirclePassed,
        a[1].showCircleFailed,
        a[1].showCircleRunning,
        a[1].legend,
        a[1].isMutualExclusive
      );
      fc.on = a[1].on;
      conf.filters.set(a[0], fc);
    }
    conf.moduleSearchExecuted = obj.moduleSearchExecuted;
    return conf;
  }

  // Set default values
  static empty = () => new LocalStorageConfig(
    -1,
    ThemeConfig.Light,
    true,
    true,
    false,
    false
  )

  static makeStringify(obj: LocalStorageConfig): string {
    const dpl = (JSON.parse(JSON.stringify(obj)));
    // @ts-ignore
    dpl.filters = Object.fromEntries(obj.filters);
    return JSON.stringify(dpl);
  }
}
