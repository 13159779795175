import {Injectable} from '@angular/core';
import {IModul} from '../models/Modul';
import {SnackbarService} from './snackbar.service';
import {map} from 'rxjs/internal/operators';
import {IAnmeldung} from '../models/Anmeldung';
import {IStudent, Student} from '../models/Student';
import {IStudienjahrgang, Studienjahrgang} from '../models/Studienjahrgang';
import {formatNumber} from '@angular/common';
import {FilterService} from './filter.service';
import {AuthService} from './auth.service';
import {Modulstatus} from '../models/Modulstatus';
import {Attempt, IAttempt} from '../models/Attempt';
import {Constants} from '../models/Constants';
import {failedStatuses, ignorableStatuses as ignorableAnmeldungStatuses} from '../models/AnmeldungStatuses';
import {ignorableStatuses as ignorableModulStatuses} from '../models/ModulStatuses';
import {ignorableStatuses as ignorableSJAStatuses} from '../models/StudienjahrgangAnmeldungStatuses';
import {ignorableStatuses as ignorableSJStatuses} from '../models/StudienjahrgangStatuses';
import {Semester} from '../models/Semester';
import {SidenavService} from './sidenav.service';
import {DataScienceAssessment} from '../models/DataScienceAssessment';
import {ApiService} from './api.service';
import {Level, LevelDescription, LevelWeight} from '../models/Level';
import {environment} from '../../environments/environment';
import {BetaTesting} from '../config/BetaTesting';
import {IModulanlass} from '../models/Modulanlass';
import {Studiengaenge, Studiengang} from '../models/Studiengaenge';
import {Modulteppich} from '../models/Modulteppich';
import {View} from "../views/views";
import {BehaviorSubject} from "rxjs";
import {StudierendeWithAnmeldungen} from "../models/StudierendeWithAnmeldungen";
import {IStudienjahrgangAnmeldung} from "../models/StudienjahrgangAnmeldung";

@Injectable({
  providedIn: 'root'
})
export class ParseService {

  constructor(
    private snackbarService: SnackbarService,
    private filterService: FilterService,
    private authService: AuthService,
    private sidenavService: SidenavService,
    private apiService: ApiService
  ) {
  }

  public static readonly AVAILABLE_STUDIENGAENGE: Array<Modulteppich> = Studiengaenge.availableModulteppiche;

  private loading = false;
  private studiengang = Studiengang.NoMatch;
  private assessmentModules: Array<string> = [];
  private profileModules: Array<IModul> = [];
  private dataScienceAssessment: Array<DataScienceAssessment> = [];
  private modulTeppichIsComplete = false;
  private modulTeppichIsPublic = false;
  private student!: IStudent;
  private studierende: Array<StudierendeWithAnmeldungen> = [];
  private studienJahrgaenge!: Array<IStudienjahrgang>;
  private activeStudienJahrgang!: IStudienjahrgang;
  private flattenedModuleTree: Array<IModul> = [];
  private moduleTree: Array<IModul> = [];
  public anmeldungen: Array<IAnmeldung> = [];
  private modulanlaesse: Array<IAnmeldung> = [];
  private allAngemeldeteModule: Array<IModul> = [];
  private angerechneteModule: Array<IAnmeldung> = [];
  public gruppenAnrechnung: Array<IModul> = [];

  public earnedECTS = 0;
  public passedModules = 0;
  public failedECTS = 0;
  public failedModules = 0;
  public runningECTS = 0;
  public runningModules = 0;
  public assessmentECTS = 0;
  public assessModules = 0;
  public anrechnungsECTS = 0;
  public pauschalAnrechnungsECTS = 0;
  public modulAnrechnungsECTS = 0;

  public assessmentECTSDSGroup1 = 0;
  public assessmentECTSDSGroup2 = 0;
  public assessmentModulesDSGroup1 = 0;
  public assessmentModulesDSGroup2 = 0;

  public semesters: Array<Semester> = [];

  public currentView: BehaviorSubject<View> = new BehaviorSubject<View>(View.CARPET);

  /**
   * Sorts semesters in
   * @param semesterA Takes a semester
   * @param semesterB Takes a semester
   * @static
   */

  static sortSemester(semesterA: string, semesterB: string): number {
    if (!semesterA && !semesterB) {
      return 0;
    }
    if (!semesterA) {
      return -1;
    }
    if (!semesterB) {
      return 1;
    }

    const [aN, aS] = semesterA.split(/(H|F)/);
    const [bN, bS] = semesterB.split(/(H|F)/);

    if (aN === bN) {
      return bS === 'F' ? -1 : 1;
    } // F stands for Frühlingssemester which should come first

    return bN > aN ? 1 : -1;
  }

  /**
   * Calculates the total number of credited ECTS for a student based on their pauschalAnrechnungen
   * and modulAnrechnungen, filtered by the active Studienjahrgang and flattenedModule.
   *
   * @param student - The student for whom to calculate the total angerechnete ECTS.
   * @param activeSJG - The active Studienjahrgang to filter the anrechnungen.
   * @param flattenedModule - The array of flattened modules for comparison.
   *
   * @returns The total number of angerechnete ECTS for the student.
   */
  static calculateTotalAngerechneteECTS(student: IStudent, activeSJG: IStudienjahrgang, flattenedModule: IModul[]): number {
    let total = 0;
    student.pauschalAnrechnungen
      ?.filter(p => p.studiengangId && activeSJG.studiengangId && p.studiengangId === activeSJG.studiengangId)
      .forEach(anrechnung => total += anrechnung.anrechnungEcts ?? 0);
    student.modulAnrechnungen?.forEach(anrechnung => {
      if (flattenedModule.some(m => m.modulId && anrechnung.modulId && m.modulId === anrechnung.modulId)) {
        total += anrechnung.anrechnungEcts ?? 0;
      }
    });
    return total;
  }

  /**
   * Calculates the total ECTS credits for a given array of Pauschalanrechnung modules.
   *
   * @param {IModul[]} gruppenAnrechnung - An array of modules for which ECTS credits need to be calculated.
   * @returns {number} The total ECTS credits for the provided modules.
   */
  static calculatePauschalanrechnungsECTS(gruppenAnrechnung: IModul[]): number {
    let total = 0;
    gruppenAnrechnung.forEach(g => total += g.modul?.ects ?? 0);
    return total;
  }

  /**
   * Calculates the total number of ECTS credits for modulAnrechnungen for a student,
   * based on matching modulIds in the provided flattenedModule array.
   *
   * @param student - The student for whom to calculate the total modulAnrechnungsECTS.
   * @param flattenedModule - The array of flattened modules for comparison.
   *
   * @returns The total number of ECTS credits for modulAnrechnungen.
   */
  static calculateModulanrechnungsECTS(student: IStudent, flattenedModule: IModul[]): number {
    let total = 0;
    student.modulAnrechnungen?.forEach(anrechnung => {
      if (flattenedModule.some(m => m.modulId && anrechnung.modulId && m.modulId === anrechnung.modulId)) {
        total += anrechnung.anrechnungEcts ?? 0;
      }
    });
    return total;
  }

  /**
   * Calculates the total earned ECTS  for a student based on
   * their registered modules and credited ECTS from anrechnungECTS.
   *
   * @param registratedModules - The array of registered modules for the student.
   * @param anrechnungECTS - The number of ECTS  credited through  anrechnung..
   *
   * @returns The total number of earned ECTS.
   */
  static calculateTotalEarnedECTS(registratedModules: IModul[], anrechnungECTS: number): number {
    let ects = 0;
    registratedModules.forEach(modul => {
      if (modul && modul.attempts) {
        const passedAttempts = modul.attempts.filter(a => a.status && a.status === Modulstatus.PASSED);
        ects += (modul.modul?.ects ?? 0) * passedAttempts.length;
      }
    });
    ects += anrechnungECTS;
    return ects;
  }

  /**
   * Calculates the total ECTS credits for failed or locked modules
   * among the registered modules of a student.
   *
   * @param registratedModules - The array of registered modules for the student.
   *
   * @returns The total number of ECTS credits for failed or locked modules.
   */
  static calculateTotalFailedECTS(registratedModules: IModul[]): number {
    let ects = 0;
    registratedModules.forEach(modul => {
      if (modul && modul.attempts) {
        const failedAndLockedAttempts = modul.attempts.filter(a => a.status && [Modulstatus.FAILED, Modulstatus.LOCKED].includes(a.status));
        ects += (modul.modul?.ects ?? 0) * failedAndLockedAttempts.length;
      }
    });
    return ects;
  }

  /**
   * Calculates the total ECTS credits for failed or locked modules
   * among the registered modules of a student.
   *
   * @param registratedModules - The array of registered modules for the student.
   *
   * @returns The total number of ECTS credits for failed or locked modules.
   */
  static calculateRunningECTS(registratedModules: IModul[]): number {
    let ects = 0;
    registratedModules.forEach(modul => {
      if (modul && modul.attempts) {
        const runningAttempts = modul.attempts?.filter(a => a.status && a.status === Modulstatus.RUNNING);
        ects += (modul.modul?.ects ?? 0) * runningAttempts.length;
      }
    });
    return ects;
  }

  /**
   * Calculates and returns an array of Semester objects based on registered modules.
   *
   * @param {IModul[]} registratedModules - An array of registered modules to process.
   *
   * @returns {Semester[]} - An array of Semester objects representing modules per semester.
   */
  static calculateModulesPerSemester(registratedModules: IModul[]): Array<Semester> {
    return ParseService.parseSemesters(registratedModules).map(semesterAbbreviation => {
      const semesterModuleArray: IModul[] = registratedModules.filter(modul =>
        modul.attempts?.some(a => a.semester && a.semester === semesterAbbreviation)
      );
      const semesterECTS = ParseService.calculateECTSPerSemester(semesterAbbreviation, semesterModuleArray);
      return new Semester(semesterAbbreviation, semesterECTS[0], semesterECTS[1], semesterECTS[2], semesterECTS[3], semesterModuleArray);
    });
  }

  /**
   * Sums up failed ECTS wherever the last attempt was failed, used only to display group-warnings.
   *
   * @param modulGroup The module group for which failed ECTS credits need to be calculated.
   * @return The total number of ECTS credits associated with failed or locked sub-modules in the provided module group.
   */

  static calculateFailedGroupECTS(modulGroup: IModul): number {
    if (!modulGroup.modules || modulGroup.modules.length < 1) {
      return 0;
    }
    return ParseService.flattenModulGroup(modulGroup.modules, [])
      .reduce((failedECTSCount, modul) => {
        if (modul.modul?.ects && modul.attempts?.[0]?.status &&
          [Modulstatus.FAILED, Modulstatus.LOCKED].includes(modul.attempts[0].status)) {
          failedECTSCount += modul.modul.ects; // NOTE: this does NOT correctly sum all failed credits, it only uses the most recent attempt!
        }
        return failedECTSCount;
      }, 0);
  }

  static calculateAvailableGroupECTS(modulGroup: IModul): number {
    const flattenedModuleGroup = ParseService.flattenModulGroup(modulGroup.modules, []);
    let availableECTS = 0;

    for (const module of flattenedModuleGroup) {
      const relevantModule = module.modules?.[0] || module;

      if (!relevantModule?.attempts?.length ||
        (relevantModule.attempts[0]?.status &&
          ![Modulstatus.LOCKED, Modulstatus.PASSED, Modulstatus.CREDITED].includes(relevantModule.attempts[0].status))) {
        availableECTS += relevantModule.modul?.ects || 0; // Ensure ects is not undefined
      }
    }

    return availableECTS;
  }

  /**
   * Displays max. ECTS for a goup, currently used only in DataScience.
   * Note: May display ects of hidden modules (Vertiefungen). It means, we may not show these modules but their ects are counted.
   *
   * @param modulGroup - The module group for which ECTS credits need to be calculated.
   * @return The total number of ECTS credits in the specified module group.
   */
  static calculateMaxGroupECTS(modulGroup: IModul): number {
    if (!modulGroup.modules || modulGroup.modules.length < 1) {
      return 0;
    }
    return ParseService.flattenModulGroup(modulGroup.modules, [])
      .reduce((ECTSCount, modul) => {
        if (modul.modul?.ects) {
          ECTSCount += modul.modul!.ects!;
        }
        return ECTSCount;
      }, 0);
  }

  static calculateMinGroupECTS(modulGroup: IModul): number {
    return modulGroup.minECTS ?? 0;
  }

  /**
   * Calculates the average grade of the final attempt of all modules that were
   * passed at least once. This calculates the most accurate weighted (by ECTS)
   * average possible.
   * @param modules The modules to be averaged.
   * @returns The display-ready average.
   */
  public static calcAccurateAverage(modules: Array<IModul>): string {
    return ParseService.calcWeightedGradeAverage(modules,
      ParseService.roundGrade);
  }

  /**
   * Calculates the average grade of all modules according to the ToR algorithm.
   * @param modules The modules to be averaged.
   * @returns The display-ready ToR average.
   */
  public static calcTorAverage(modules: Array<IModul>): string {
    return ParseService.calcWeightedGradeAverage(modules,
      ParseService.roundGradeToTenth);
  }

  /**
   * Calculates a weighted average of all modules that have at least one
   * successful attempt.
   * @param modules The modules to be averaged.
   * @param prepRoundingF The rounding function used on every grade before the
   * averages are calculated.
   * @param roundingF The rounding function used on the final average to
   * transform it into a display-ready string.
   * @returns Display ready string of the average.
   * @private
   */
  private static calcWeightedGradeAverage(modules: Array<IModul>,
                                          roundingF: (a: number) => string): string {
    class SuccessfulAttempt {
      grade: number;
      credits: number;

      constructor(grade: number, credits: number) {
        this.grade = grade;
        this.credits = credits;
      }
    }

    const grades = modules.filter(m => m?.anmeldung &&
      m.attempts?.some(a => a.status === Modulstatus.PASSED) &&
      !isNaN(parseFloat(m.anmeldung.noteBezeichnung as string)))
      .map(m => {
        const relevantAttempt = m.attempts?.filter(a => a.status === Modulstatus.PASSED)[0];
        return new SuccessfulAttempt(Number(relevantAttempt.finalGrade), m.modul.ects);
      })
      .filter(sa => sa.grade >= 3.75);

    const gradeSum = grades.reduce((sum, curr) => sum + curr.grade * curr.credits, 0);
    const ects = grades.reduce((sum, curr) => sum + curr.credits, 0);

    const average = (gradeSum / ects);
    return isNaN(Number(average)) ? '-.-' : roundingF(average);
  }

  /**
   * Sorts an array of IModul objects by their 'level' properties and 'position'.
   *
   * @param group - An array of IModul objects to be sorted.
   * @returns A new array of IModul objects sorted by 'level' and 'position'.
   */
  public static sortByNiveau(group: Array<IModul>): Array<IModul> {
    return group
      .sort((a, b) => (a.level?.weight!) - (b.level?.weight!)) // weight non-null, defined in setLevels()
      .sort((a, b) => {
        const levelDescriptionA = a.level?.levelDescription ?? '';
        const levelDescriptionB = b.level?.levelDescription ?? '';

        if (levelDescriptionA === levelDescriptionB) {
          return (a.position ?? 0) - (b.position ?? 0);
        } else {
          return (a.level?.weight!) - (b.level?.weight!);
        }
      });
  }

  /**
   * Sorts an array of IModul objects by their position property and optionally filters the result.
   *
   * @param group - An array of IModul objects to be sorted and filtered.
   * @param options - An optional configuration.
   * @param options.isDataScience - Indicates whether to filter out IModul objects with empty 'modules' arrays.
   * @param options.ascending - Indicates whether to sort the group in ascending or descending order.
   *
   * @returns A sorted and optionally filtered array of IModul objects.
   */
  public static sortByPosition(group: IModul[], {isDataScience = false, ascending = false}: {
    isDataScience?: boolean;
    ascending?: boolean;
  } = {}): IModul[] {
    const comparator = (a: IModul, b: IModul) => ascending ? (a.position ?? 0) - (b.position ?? 0) : (b.position ?? 0) - (a.position ?? 0);
    const sortedGroup = group.sort(comparator);
    const filteredGroup = sortedGroup.filter((m) => m.modules?.length! > 0);

    return isDataScience ? sortedGroup : filteredGroup;
  }

  /**F
   * Sorts an array of IModul objects by their first module's assessment property.
   *
   * @param group - An array of IModul objects to be sorted.
   *
   * @returns A sorted array of IModul objects based on the first module's assessment property.
   */
  public static sortByAssessment(group: Array<IModul>): Array<IModul> {
    return group.sort(a => a.modules && a.modules[0] && a.modules[0].assessment ? 1 : -1);
  }

  public static sortAlphabetically(group: Array<IModul>): Array<IModul> {
    return group.sort((a, b) => a.modules[0] && b.modules[0] && a.modules[0].abbreviation.toLowerCase() > b.modules[0].abbreviation.toLowerCase() ? -1 : 1);
  }

  public static sortAlphabeticallyReverse(group: Array<IModul>): Array<IModul> {
    return group.sort((a, b) => a.modules[0] && b.modules[0] && a.modules[0].abbreviation.toLowerCase() > b.modules[0].abbreviation.toLowerCase() ? 1 : -1);
  }

  public static parseAnmeldungen(anmeldungen: Array<IAnmeldung>): Array<IAnmeldung> {
    return anmeldungen.filter(a => a.statusName && !ignorableAnmeldungStatuses.includes(a.statusName!));
  }

  /**
   * Calculates the ECTS per semester for the given semester and modules.
   *
   * @param semester - The semester for which to calculate the ECTS.
   * @param semesterModules - The modules in the semester.
   * @return numbers ECTS per semester in the order: [ECTS Passed, ECTS Once Failed, ECTS Multiple Failed, ECTS Running].
   */
  static calculateECTSPerSemester(semester: string, semesterModules: Array<IModul>): Array<number> {
    let ectsPassed = 0;
    let ectsOnceFailed = 0;
    let ectsMultipleFailed = 0;
    let ectsRunning = 0;
    semesterModules.forEach(modul => {
      const attempt = modul.attempts?.filter(a => a.semester === semester)[0];
      const ects = Number(modul.modul?.ects ?? 0);
      if (attempt && attempt.status) {
        switch (attempt.status) {
          case Modulstatus.PASSED:
          case Modulstatus.CREDITED:
            ectsPassed += ects;
            break;
          case Modulstatus.FAILED:
            ectsOnceFailed += ects;
            break;
          case Modulstatus.LOCKED:
            ectsMultipleFailed += ects;
            break;
          case Modulstatus.RUNNING:
            ectsRunning += ects;
            break;
          default:
            break;
        }
      }
    });
    return [ectsPassed, ectsOnceFailed, ectsMultipleFailed, ectsRunning];
  }

  /**
   * Retrieves a list of modules that have been reached based on a given list of assessments,
   * registered modules, and credited modules.
   *
   * @param assessments An array of assessment abbreviations to compare against module abbreviations.
   * @param registratedModules An array of registered modules to consider for reaching assessments.
   * @param creditedModules An array of credited modules to consider for reaching assessments.
   * @return An array of modules that have been reached based on assessments and their statuses.
   */
  static getReachedAssessmentModules(assessments: string[], registratedModules: IModul[], creditedModules: IModul[]): Array<IModul> {
    return [...creditedModules, ...registratedModules]
      .map(modul => modul.modules && modul.modules[0] ? modul.modules[0] : modul)
      .filter(modul => assessments.some(am => am === modul.abbreviation))
      .filter(modul => modul.attempts && modul.attempts!.some(a => [Modulstatus.PASSED, Modulstatus.CREDITED].includes(a.status!)));
  }

  /**
   * Parses and retrieves a sorted list of unique semesters from a given array of registered modules.
   *
   * @param registratedModules - An array of registered modules containing attempts with semester information.
   * @return An array of unique semesters in ascending order.
   */
  static parseSemesters(registratedModules: IModul[]): Array<string> {
    const semesterSet: Set<string> = new Set<string>();
    registratedModules.forEach(a => a.attempts?.forEach(att => att.semester && semesterSet.add(att.semester)));
    return Array.from(semesterSet).sort(ParseService.sortSemester);
  }

  /**
   * Rounds number to two decimal places, keeps at least one.
   */
  public static roundGrade(grade: number): string {
    return formatNumber(Number(grade), 'en-US', '1.1-2');
  }

  /**
   * Gets all angemeldete Modules from flattenedModulTree and setAllAngemeldeteModule()
   */
  public static parseAngemeldeteModule(modulgruppen: Array<IModul>): Array<IModul> {
    return modulgruppen
      .filter(modul => modul.anmeldung)
      .filter(modul => !modul.anmeldung.statusName?.includes("Abgemeldet"))
      .filter(modul => modul.modul?.ects !== 0)
      .filter((el, i, a) => i === a.indexOf(el)); // remove duplicates
  }

  /**
   * Rounds the grade to a tenth and always displays one number after the
   * decimal point.
   * @param grade The grade to be rounded.
   * @returns The display ready grade.
   */
  public static roundGradeToTenth(grade: number): string {
    return (Math.round(grade * 10) / 10).toFixed(1);
  }

  /**
   * Rounds grades to the next half and always displays exactly one digit after
   * the decimal point.
   */
  public static roundGradeToHalf(grade: number): string {
    return (Math.round(grade * 2) / 2).toFixed(1);
  }

  /**
   * Replaces bb with Berufsbegleitend and a with Vollzeit
   * @sja Takes the Studienjahrgang
   * @public
   */
  public static parseStudyMode(sja: IStudienjahrgang): IStudienjahrgang {
    sja.bezeichnung = sja.bezeichnung?.replace(' bb', ' Berufsbegleitend');
    sja.bezeichnung = sja.bezeichnung?.replace(' a', ' Vollzeit');
    return sja;
  }

  /**
   * Sorts an array of IModul by its latest Attempt status (index 0 of attempts)
   * @param group - Takes an array of modules to sort
   */
  public static sortByStatus(group: Array<IModul>): Array<IModul> {
    const order = [Modulstatus.PASSED, Modulstatus.CREDITED, Modulstatus.FAILED, Modulstatus.LOCKED, Modulstatus.RUNNING, Modulstatus.EMPTY];
    const haveAttemptStatus: Array<IModul> = group
      .filter(modul => modul && modul.modules![0] && modul.modules![0].attempts && modul.modules![0].attempts.length > 0) // only get modules with an attempt
      .filter(modul => modul.modules![0].attempts![0].status) // only get modules with a status
      .sort((a, b) => order.indexOf(a.modules![0].attempts![0].status!) - order.indexOf(b.modules![0].attempts![0].status!)); // sort them by order
    const haveNoAttemptStatus: Array<IModul> = group
      .filter(modul => !modul || !modul.modules![0] || modul.modules![0].attempts!.length <= 0); // get everything else
    return haveAttemptStatus.concat(ParseService.sortByPosition(haveNoAttemptStatus));
  }

  /**
   * Sorts an array of modules in descending order based on their ECTS credits.
   *
   * @param group An array of modules to be sorted.
   * @return A new array of modules sorted by ECTS credits in descending order.
   */
  public static sortByECTS(group: Array<IModul>): Array<IModul> {
    return group.sort((a, b) => (b.modules![0].modul!.ects ?? 0) - (a.modules![0].modul!.ects ?? 0));
  }

  public static parseAngerechneteModule(flattenedModuleTree: Array<IModul>): Array<IModul> {
    return flattenedModuleTree.filter(modul => modul.angerechnet);
  }

  /**
   * Creates a new module for each Gruppenanrechnung
   *
   * @param {IStudent} student - The student for whom Gruppenanrechnungen are being parsed.
   * @param {Array<IModul>} gruppe - An array of modules to process.
   * @return {Array<IModul>} An array of newly created modules for Gruppenanrechnungen.
   */
  static parseGruppenanrechnungen(student: IStudent, gruppe: Array<IModul>): Array<IModul> {
    const arr: any = [];
    gruppe.forEach(g => {
      student.pauschalAnrechnungen?.forEach(p => {
        if (g.modulStandardGruppeId && p.modulStandardGruppeId && g.modulStandardGruppeId === p.modulStandardGruppeId) {
          const gran = {
            parentModulgruppeId: p.modulStandardGruppeId,
            abbreviation: 'gran',
            bezeichnung: 'Gruppenanrechnung',
            attempts: [{
              finalGrade: 'Angerechnet',
              status: Modulstatus.CREDITED
            }],
            angerechnet: true,
            level: new Level(LevelDescription.none, LevelWeight.heaviest),
            position: g.modules?.reduce((a, b) => (a.position && b.position && a.position > b.position) ? a : b).position! + 1, // TODO why this calculation?
            modul: {
              ects: p.anrechnungEcts ?? 0,
              memo2DE: 'ECTS durch Gruppenanrechnung',
              memo3DE: 'ECTS durch Gruppenanrechnung'
            }
          };
          arr.push(gran);
          g.gruppenanrechnung = p.anrechnungEcts ?? 0;
        }
      });
    });
    return arr;
  }

  /**
   * Sets levels and weights for modules in a given array based on their description.
   *
   * @param modulgruppen A flat list of modules.
   * @param studiengangDS A boolean indicating whether to use 'memo2DE' (true) or
   *                     'memo1DE' (false) for level descriptions and weights.
   * @return An array of modules with updated level descriptions and weights.
   */
  static setLevels(modulgruppen: Array<IModul>, studiengangDS?: boolean): Array<IModul> {
    return modulgruppen.map(m => {
      const memoDE = studiengangDS ? m.modul?.memo2DE?.toLowerCase() : m.modul?.memo1DE?.toLowerCase();
      switch (memoDE) {
        case LevelDescription.basic.toLowerCase():
          return {...m, level: new Level(LevelDescription.basic, LevelWeight.heavy)};
        case LevelDescription.intermediate.toLowerCase():
          return {...m, level: new Level(LevelDescription.intermediate, LevelWeight.medium)};
        case LevelDescription.advanced.toLowerCase():
          return {...m, level: new Level(LevelDescription.advanced, LevelWeight.light)};
        default:
          return {...m, level: new Level(LevelDescription.none, LevelWeight.zero)};
      }
    });
  }

  /**
   * Extracts the status of a module for a registered student.
   *
   * @param modul - The module for which the status should be extracted.
   * @param anmeldung - The registration of the student.
   * @returns The Modulstatus indicating the status of the module for the student.
   */
  static extractStatusForAngemeldeteModule(modul: IModul, anmeldung: IAnmeldung): Modulstatus {
    let modulstatus: Modulstatus;
    if (modul?.angerechnet) {
      modulstatus = Modulstatus.CREDITED;
    } else {
      if (anmeldung?.bestanden) {
        modulstatus = Modulstatus.PASSED;
      } else if (!anmeldung?.bestanden && anmeldung?.noteBezeichnung) {
        modulstatus = Modulstatus.FAILED;
      } else if (anmeldung && !anmeldung?.bestanden && !anmeldung?.noteBezeichnung) {
        modulstatus = Modulstatus.RUNNING;
      } else {
        modulstatus = Modulstatus.EMPTY;
      }
    }
    return modulstatus;
  }

  /**
   * @private
   * Takes and Array and sets the depending modules as attribute
   * @return An Array of modules that have their depending modules now set
   */
  static setDependingModules(modulgruppen: Array<IModul>): Array<IModul> {

    return modulgruppen.map(modul => {
      modul.dependingModules = ParseService.parseDependingModules(modul, modulgruppen);
      return modul;
    });
  }

  /**
   * @private
   * Takes a module and all other modules and removes non abbreviations from the memos and maps the abbreviations to modules
   * @return An Array of depending modules
   */
  static parseDependingModules(modul: IModul, modulgruppen: Array<IModul>): Array<IModul> {
    const extractedMemos = ParseService.extractMemos(modul);
    const parsedMemoAbbreviation = ParseService.parseMemoAbbreviations(extractedMemos);
    const cleanedAbbreviations = parsedMemoAbbreviation.filter(abbreviation => modulgruppen.some(modulgruppe => abbreviation === modulgruppe.abbreviation && modulgruppe.modulId));
    return ParseService.checkForDoubleModules(modulgruppen.filter(mod => cleanedAbbreviations.some(abbr => abbr === mod.abbreviation)));
  }

  /**
   * @private
   * Takes a module and extracts the memo in english and german
   * @return A string of both german and english memo of the module
   */
  static extractMemos(modul: IModul): string {
    let extractedMemos = '';
    if (modul && modul.modul) {
      extractedMemos += modul.modul?.memo4DE ?? '';
      extractedMemos += modul.modul?.memo4EN ?? '';
    }
    return extractedMemos;
  }

  /**
   * @private
   * Removes the clutter around the module abbreviations of memo
   * @return An array of parsed abbreviations from a memo string
   */
  static parseMemoAbbreviations(extractedMemos: string): Array<string> {
    return [...new Set(extractedMemos
      .split('\n')
      .map(line => {
        const lastIndex = line.lastIndexOf(')'); // Find the last ')'
        const firstIndex = line.lastIndexOf('(', lastIndex); // Find the '(' before the last ')'
        return line.slice(firstIndex + 1, lastIndex);
      })
      .filter(line => line !== '')
      .filter(line => !line.includes('<'))
      .filter(line => !line.includes('>'))
      .filter(line => !line.includes('kein'))
      .map(depAbbr => depAbbr.split(','))
      .reduce((acc, value) => acc.concat(value), [])
      .map(el => el.trim())
      .map(el => el.split(' '))
      .reduce((acc, value) => acc.concat(value), [])
      .map(el => el === 'oop2' ? 'oopI2' : el)
      .map(el => el === 'oopl2' ? 'oopI2' : el)
      .map(el => el === 'oopl1' ? 'oopI1' : el)
      .map(el => el === 'oop1' ? 'oopI1' : el))];
  }

  /*
  * Only for DS
  * */
  static checkForOldModules(dsModules: Array<IModul>): Array<IModul> {

    // TODO rework as in checkForDoubleModules
    const removeDuplicatesDsModules = dsModules;
    dsModules.forEach(modul => {
      dsModules.forEach(m => {
        if (modul.parentModulgruppeId === m.parentModulgruppeId) {
          if (modul.abbreviation === m.abbreviation) {
            if (modul.level!.weight > m.level!.weight) {
              m.level = modul.level;
            } else if (modul.level!.weight < m.level!.weight) {
              modul.level = m.level;
            }
            if (modul.modul?.nummer! > m.modul?.nummer!) {
              if (modul.anmeldung && m.anmeldung) {
                // remove older
                removeDuplicatesDsModules.splice(removeDuplicatesDsModules.indexOf(m), 1);
              } else if (modul.anmeldung && !m.anmeldung) {
                // remove the one with no anmeldung
                removeDuplicatesDsModules.splice(removeDuplicatesDsModules.indexOf(m), 1);
              } else if (!modul.anmeldung && m.anmeldung) {
                // remove the one with no anmeldung
                removeDuplicatesDsModules.splice(removeDuplicatesDsModules.indexOf(modul), 1);
              } else {
                // remove the older
                removeDuplicatesDsModules.splice(removeDuplicatesDsModules.indexOf(m), 1);
              }
            }
          }
        }
      });
    });
    return removeDuplicatesDsModules;
  }

  /**
   * @param modules - Takes a flattened array of IModul
   * @return Array of IModul without duplicates and without 0 ECTS modules (without .SP .SE .SN)
   */
  public static checkForDoubleModules(modules: Array<IModul>): Array<IModul> {
    // removes multi-occurring modules
    const wvert = modules.find(m => m.bezeichnung?.toLowerCase().includes('weitere vert')); // Filter the "Weitere Vertiefungen"
    const toRemoveIndices: Set<number> = new Set<number>();
    for (let i = 0; i < modules.length; i++) {
      const modul1 = modules[i];
      for (let j = i + 1; j < modules.length; j++) {
        const modul2 = modules[j];
        if (modul1.abbreviation === modul2.abbreviation && modul1.modulId !== modul2.modulId) {
          // sets correct level to module when more than one with same abbreviation (Originally from data science)
          if (modul1.level) {
            if (modul1.level!.weight > modul2.level!.weight) {
              modul2.level = modul1.level;
            } else if (modul1.level!.weight < modul2.level!.weight) {
              modul1.level = modul2.level;
            }
          }
          if (modul1.attempts?.length! === modul2.attempts?.length!) {
            modul1.attempts?.push(...(modul2.attempts ?? [])); // use empty array if attempts not defined
            modul1.attempts = modul1.attempts ? modul1.attempts.sort((a, b) => b.semester! > a.semester! ? 1 : -1) : modul1.attempts;
            toRemoveIndices.add(j);
          } else if (modul1.attempts?.length! > modul2.attempts?.length!) {
            modul1.attempts?.push(...(modul2.attempts ?? [])); // use empty array if attempts not defined
            modul1.attempts = modul1.attempts ? modul1.attempts.sort((a, b) => b.semester! > a.semester! ? 1 : -1) : modul1.attempts;
            toRemoveIndices.add(j);
          } else if (modul1.attempts?.length! < modul2.attempts?.length!) {
            modul2.attempts?.push(...(modul1.attempts ?? [])); // use empty array if attempts not defined
            modul2.attempts = modul2.attempts ? modul2.attempts.sort((a, b) => b.semester! > a.semester! ? 1 : -1) : modul2.attempts;
            toRemoveIndices.add(i);
          } else {
            modul1.attempts?.push(...(modul2.attempts ?? [])); // use empty array if attempts not defined
            modul1.attempts = modul1.attempts ? modul1.attempts.sort((a, b) => b.semester! > a.semester! ? 1 : -1) : modul1.attempts;
            toRemoveIndices.add(j);
          }
        } else if (modul1.abbreviation && modul2.abbreviation && modul1.abbreviation === modul2.abbreviation && modul1.modulId && modul2.modulId && modul1.modulId === modul2.modulId) {
          // This may break for Data Science Studiengang
          const modulContainer1 = modules.find(mod => mod.modulgruppeId === modul1.parentModulgruppeId); // container for module, has same bezeichnung as child
          const modulContainer2 = modules.find(mod => mod.modulgruppeId === modul2.parentModulgruppeId); // container for module, has same bezeichnung as child
          if (modulContainer1?.parentModulgruppeId === wvert?.modulgruppeId) {
            // remove both modul-wrapper(parent) and module itself
            toRemoveIndices.add(i);
            toRemoveIndices.add(modules.indexOf(modulContainer1!));
          } else {
            toRemoveIndices.add(j);
            toRemoveIndices.add(modules.indexOf(modulContainer2!));
            if (modulContainer2?.parentModulgruppeId !== wvert?.modulgruppeId) {
              console.warn('Modul duplicate found for ' + modul1.abbreviation);
            }
          }
        }
      }
    }
    return modules.filter((mod, index) => !toRemoveIndices.has(index));
  }

  /*
  * Checks if Modulteppich is complete
  * IsPublic = Visible for students and admins when true, when false only for admins
  * IsComplete = Visible for everybody when true, else not visible for anybody
  * */
  static parseCurrentModulteppich(activeStudienJahrgangId: number): Modulteppich {
    // TODO(laniw): Merge this with AvailableModulTeppiche.
    const modulteppich: Modulteppich = ParseService.AVAILABLE_STUDIENGAENGE
        .find(mt => mt
          .getStudiengangIds().includes(activeStudienJahrgangId)) ??
      new Modulteppich(Studiengang.NoMatch, [], true, true, []);
    return modulteppich;
  }

  /*
 * Calculates the total number of passed modules.
 *
 * @param {IModul[]} registratedModules - An array of registered modules to check for passed status.
 * @param {IModul[]} creditedModules - An array of credited modules.
 * @returns {number} The total number of passed modules, including both registered and credited ones.
 */
  static calculatePassedModules(registratedModules: IModul[], creditedModules: IModul[]): number {
    let numberOfPassedModules = 0;
    registratedModules.forEach(modul => {
      if (modul && modul.attempts && modul.attempts?.some(a => a.status && a.status === Modulstatus.PASSED)) {
        numberOfPassedModules++;
      }
    });
    numberOfPassedModules += creditedModules.length;
    return numberOfPassedModules;
  }

  /**
   * Calculates the number of failed and locked modules among the registered modules.
   *
   * @param registratedModules An array of registered modules to analyze.
   * @return The total number of failed and locked modules among the registered modules.
   */
  static calculateFailedModules(registratedModules: IModul[]): number {
    let numberOfFailedModules = 0;
    registratedModules.forEach(modul => {
      if (modul && modul.attempts) {
        numberOfFailedModules += modul.attempts.filter(a => a.status && [Modulstatus.FAILED, Modulstatus.LOCKED].includes(a.status)).length;
      }
    });
    return numberOfFailedModules;
  }

  /**
   * Calculates the number of modules that are currently in a RUNNING status.
   *
   * @param registratedModules An array of registered modules to analyze.
   * @return The total number of modules that are currently in a RUNNING status.
   */
  static calculateRunningModules(registratedModules: IModul[]): number {
    let numberOfRunningModules = 0;
    registratedModules.forEach(modul => {
      if (modul && modul.attempts) {
        numberOfRunningModules += modul.attempts?.filter(a => a.status && a.status === Modulstatus.RUNNING).length;
      }
    });
    return numberOfRunningModules;
  }

  /**
   * Returns all passed and credited ECTS from a modulGroup, independent of depth
   * Also checks for gruppenanrechnungen of children and own gruppenanrechnungen
   *
   * @param {IModul} modulGroup - The module group for which ECTS will be calculated.
   * @returns {number} The total ECTS for the passed modules within the module group.
   */

  static calculatePassedGroupECTSNonDataScience(modulGroup: IModul): number {
    const currentGroupGruppenAnrechnung = modulGroup.gruppenanrechnung ? modulGroup.gruppenanrechnung : 0;
    if (!modulGroup.modules || modulGroup.modules.length < 1) {
      return currentGroupGruppenAnrechnung;
    }
    // sums up all ects of the flattened modularray
    return ParseService.flattenModulGroup(modulGroup.modules, []) // Gets all submodules as flattened list
        .reduce((sum, modul) => {
          if (modul.gruppenanrechnung) {
            sum += modul.gruppenanrechnung;
          }
          if (modul.modul?.ects && modul.attempts?.[0]?.status &&
            [Modulstatus.PASSED, Modulstatus.CREDITED].includes(modul.attempts[0].status)) {
            sum += modul.modul.ects;
          }
          return sum;
        }, 0)
      + currentGroupGruppenAnrechnung;
  }

  /**
   * Returns all passed and credited ECTS from a modulGroup, independent of depth
   *
   * @param {IModul} modulGroup - The module group for which ECTS will be calculated.
   * @returns {number} The total ECTS for the passed data science modules within the module group.
   */
  static calculatePassedGroupECTSDataScience(modulGroup: IModul): number {
    if (!modulGroup.modules || modulGroup.modules.length < 1) {
      return 0;
    }
    return ParseService.flattenModulGroup(modulGroup.modules, [])
      .reduce((passedECTSCount, modul) => {
        if (modul.modul?.ects && modul.attempts?.[0]?.status &&
          [Modulstatus.PASSED, Modulstatus.CREDITED].includes(modul.attempts[0].status)) {
          passedECTSCount += modul.modul.ects; // note, group credits are inserted as pseudo-module and therefore are being counted
        }
        return passedECTSCount;
      }, 0);
  }

  /**
   * return a list of sub-modulegroups and modules
   * @param modulArray array of nested modules
   * @param resultModules array of resulting modules as flat list
   */
  static flattenModulGroup(modulArray: Array<IModul>, resultModules: Array<IModul>): Array<IModul> {
    modulArray.forEach(modul => {
      resultModules.push(modul);
      if (modul.modules && modul.modules.length !== 0) {
        ParseService.flattenModulGroup(modul.modules, resultModules);
      }
    });
    return resultModules;
  }

  /**
   * Initializes all the data for a student
   * @param studentID Optional - Takes the ID of a student
   * @param studienjahrgang Optional - Takes a Studienjahrgang
   * @public
   */
  public initData(studentID?: string, studienjahrgang?: IStudienjahrgang): void {
    this.setLoading(true);
    this.resetAllCalculated();
    this.apiService.fetchStudentAndStudienjahrgaenge(studentID)
      .pipe(map(([student, studienjahrgaenge]) => {
        this.setStudent(student);
        this.initStudienjahrgaenge(studienjahrgaenge, student, studienjahrgang);
        const currentModulteppich = ParseService.parseCurrentModulteppich(this.getActiveStudienJahrgang().studiengangId!);
        this.setStudiengang(currentModulteppich.getStudiengang());
        this.setModulteppichIsComplete(currentModulteppich.getIsComplete());
        this.setModulteppichIsPublic(currentModulteppich.getIsPublic());
        if (currentModulteppich.getStudiengang() === Studiengang.DS) {
          this.setDataScienceAssessment(currentModulteppich.getAssessment() as Array<DataScienceAssessment>);
        } else {
          this.setAssessmentModulesAbbreviations(currentModulteppich.getAssessment() as Array<string>);
        }
      })).subscribe(() => {
      }, (error) => this.snackbarService.openHttpErrorResponse(error),
      () => {
        this.initModulteppich(studentID, this.getActiveStudienJahrgang());
      });
  }

  /**
   * Initializes a Modulteppich
   * @param studentID Optional - Takes the Student-ID as an optional value
   * @param studienjahrgang Optional - Takes a Studienjahrgang as an optional value
   * @private
   * If no parameter is used as an input of initModulteppich, it initializes the Modulteppich of the logged in Student
   */
  private initModulteppich(studentID?: string, studienjahrgang?: IStudienjahrgang): void {
    if (studienjahrgang) {
      this.apiService.fetchModulteppichAndAnmeldungen(studentID, studienjahrgang?.studiengangId?.toString())
        .pipe(map(([modulgruppen, anmeldungen]) => this.initModules(modulgruppen, anmeldungen)))
        .subscribe(({modulgruppen, anmeldungen}) => {
          this.setupModulteppich({modulgruppen, anmeldungen});
          this.calculateAll();
        }, (error) => {
          this.snackbarService.openHttpErrorResponse(error);
        }, () => {
          if (this.hasBetaAccess()) {
            this.setModulteppichIsPublic(true);
          }
          const anmeldungenIds = this.anmeldungen.map(a => a.modulanlassId);
          if (anmeldungenIds) {
            this.apiService.fetchModulanlaesse(anmeldungenIds).subscribe(modulanlaese => {
              this.setModulanlaesse(modulanlaese);
            });
          }

          this.sidenavService.setIsExpanded(false);
          this.filterService.resetNonStudiengangFilters(this.getStudiengang());
          this.setLoading(false);
        });
    } else {
      this.modulTeppichIsComplete = false;
      this.setLoading(false);
    }
  }

  /**
   * Checks whether the currently logged in student is in Beta Testing or not
   */
  hasBetaAccess(): boolean {
    return environment.isTest &&
      (BetaTesting.studiengaenge.includes(this.getStudiengang()) ||
        BetaTesting.studierende.includes(this.getStudent().studierendeId ?? 0));
  }

  /**
   * Initializes modules and parses the corresponding data
   * @param modulgruppen Takes an array of modules
   * @param anmeldungen Takes an array of anmeldungen
   * @return {modulgruppen, anmeldungen} Object of parsed modulgruppen and anmeldungen
   * @private
   */
  private initModules(modulgruppen: Array<IModul>, anmeldungen: Array<IAnmeldung>): {
    modulgruppen: Array<IModul>,
    anmeldungen: Array<IAnmeldung>
  } {
    this.gruppenAnrechnung = ParseService.parseGruppenanrechnungen(this.getStudent(), modulgruppen);

    // filter entries that are inactive, historized etc. unless there is an anmeldung or anrechnung
    modulgruppen = modulgruppen.filter(m =>
      !ignorableModulStatuses.includes(m.modul?.statusName!) ||
      anmeldungen.some(a => a.modulanlass?.modulId === m.modulId) ||
      this.getStudent().modulAnrechnungen?.some(a => a.modulId === m.modulId)
    );


    // remove modulgroups without child modules (removes parents of removed modules)
    modulgruppen = modulgruppen.filter(mod => mod.modul || modulgruppen.find(mod2 => mod2.parentModulgruppeId === mod.modulgruppeId));

    modulgruppen.forEach(modul => { // Add IAnmeldung property to modul if there is one
      modul.anmeldung = anmeldungen.find(a => a.modulanlass?.modulId === modul.modulId);
    });

    //If module with ignorable status has Anmeldung, it's modulID is updated to ID of an active module with the same abbreviation but a non-ignorable status
    ParseService.updateIDsOfAnmeldungenWithIgnorableStatuses(anmeldungen, modulgruppen);

    // Assign MSP and EN grades to HN modul, write hasMSP property
    // A Module is built like the following: modul.modules[modulMSP, moduleEN]
    const MSPs = modulgruppen.filter(modul => modul.modul?.nummer?.split('.').pop()!.includes('SP'));
    anmeldungen = anmeldungen.filter(a => !ignorableAnmeldungStatuses.includes(a.statusName!)); // filter cancelled anmeldungen
    const ENs = modulgruppen.filter(modul => ['SE', 'EN', 'SN'].some(s => modul.modul?.nummer?.split('.').pop()!.includes(s))); // SN is usually being assigned to projects
    const HNs = modulgruppen.filter(modul => modul.modul?.nummer?.split('.').pop()!.includes('HN'));

    const attemptedMSPs = anmeldungen.filter(anm => anm.modulanlass?.nummer?.split('.').pop()!.includes('SP'));
    const attemptedENs = anmeldungen.filter(anm => ['SE', 'EN', 'SN'].some(s => anm.modulanlass?.nummer?.split('.').pop()!.includes(s)));
    const attemptedHNs = anmeldungen.filter(anm => anm.modulanlass?.nummer?.split('.').pop()!.includes('HN'));

    HNs.forEach(modul => { // note that the modul objects are the same as in the modulgruppen array
      const MSP = MSPs.find(m => m.parentModulgruppeId === modul.modulgruppeId);
      if (modul.anmeldung) {
        modul.anmeldung.noteMSP = MSP?.anmeldung?.noteBezeichnung;
        modul.anmeldung.noteEN = ENs.find(m => m.parentModulgruppeId === modul.modulgruppeId)?.anmeldung?.noteBezeichnung;
      }
      if (this.getStudiengang() !== Studiengang.DS) {
        modul.hasMSP = !!MSP;
      }
    });

    modulgruppen = modulgruppen.filter(m => m.modul?.ects !== 0); // filter out 0 ECTS modules (also removes .SP .SE .SN)

    // // Add angerechnet, abbreviation, assessments and attempts properties to modul if there is one
    const currentModulteppich = ParseService.AVAILABLE_STUDIENGAENGE.find(modulteppich => this.getStudiengang() === modulteppich.getStudiengang());
    modulgruppen.forEach(modul => {
      if (this.getStudent().modulAnrechnungen!.some(anrechnung => anrechnung.modulId === modul.modulId)) {
        modul.angerechnet = true;

        modul.attempts = [new Attempt({
          finalGrade: 'Angerechnet',
          status: Modulstatus.CREDITED
        })];
      } else { // when module is not angerechnet
        const isHN = modul.modul?.nummer?.split('.').pop()!.includes('HN');
        const isEN = ['SE', 'EN', 'SN'].some(s => modul.modul?.nummer?.split('.').pop()!.includes(s));
        const attemptArray: Array<IAttempt> = [];
        if (isHN) {
          const msp = MSPs.find(m => m.parentModulgruppeId === modul.modulgruppeId);
          const mspAttempts = msp ? attemptedMSPs.filter(amsp => amsp.modulanlass?.modulId === msp.modulId) : [];
          const en = ENs.find(m => m.parentModulgruppeId === modul.modulgruppeId);
          const enAttempts = en ? attemptedENs.filter(aen => aen.modulanlass?.modulId === en.modulId) : [];
          const hnAttempts = attemptedHNs.filter(ahn => ahn.modulanlass?.modulId === modul.modulId);
          hnAttempts.forEach(hnAttempt => {
            attemptArray.push(
              new Attempt({
                semester: hnAttempt.modulanlass!.nummer?.substring(2, 6),
                finalGrade: hnAttempt.noteBezeichnung,
                ENGrade: enAttempts.find(enAtt => enAtt.modulanlass?.nummer?.substring(2, 6)! === hnAttempt.modulanlass?.nummer?.substring(2, 6)!)?.noteBezeichnung,
                MSPGrade: mspAttempts.find(mspAtt => mspAtt.modulanlass?.nummer?.substring(2, 6)! === hnAttempt.modulanlass?.nummer?.substring(2, 6)!)?.noteBezeichnung,
                status: ParseService.extractStatusForAngemeldeteModule(modul, hnAttempt)
              }));
          });
        } else if (isEN) {
          const enAttempts = attemptedENs.filter(aen => aen.modulanlass?.modulId === modul.modulId);
          enAttempts.forEach(enAttempt => {
            attemptArray.push(
              new Attempt({
                semester: enAttempt.modulanlass!.nummer?.substring(2, 6),
                finalGrade: enAttempt.noteBezeichnung,
                status: ParseService.extractStatusForAngemeldeteModule(modul, enAttempt)
              }));
          });
        } else {
          modul.attempts = [];
        }
        modul.attempts = attemptArray.sort((a, b) => b.semester! > a.semester! ? 1 : -1);
      }

      const failedAttempts = modul.attempts?.filter(a => a.status === Modulstatus.FAILED);
      if (modul.attempts?.length! > 1 && failedAttempts?.length! > 1) {
        failedAttempts![0].status = Modulstatus.LOCKED;
      }

      if (modul.modul?.nummer) { // Add abbreviation property to modul
        modul.abbreviation = modul.modul!.nummer!
          .substring(9, modul.modul?.nummer?.indexOf('.'))
          .replace('-', ''); // special case "0-W-B-BÖK-"
      }

      modul.assessment = currentModulteppich?.getAssessment().includes(modul.abbreviation!);
    });

    return {modulgruppen, anmeldungen};
  }

  static updateIDsOfAnmeldungenWithIgnorableStatuses(anmeldungen: Array<IAnmeldung>, modulgruppen: Array<IModul>): void {
    anmeldungen.forEach(anmeldung => {
      // Find the corresponding module in modulgruppen
      const correspondingModul = modulgruppen.find(m => m.modulId && m.modulId === anmeldung.modulanlass?.modulId);

      // Check if the module has an ignorable status and an existing anmeldung
      if (correspondingModul?.modul?.statusName && ignorableModulStatuses.includes(correspondingModul.modul.statusName) && correspondingModul.anmeldung) {

        // Find an active module with the same abbreviation and a non-ignorable status
        const activeModul = modulgruppen.find(m =>
          m.bezeichnung && m.bezeichnung === correspondingModul.bezeichnung
          && m.modul?.statusName && !ignorableModulStatuses.includes(m.modul?.statusName)
          && m.anmeldung
        );

        // If such a module is found, update the modulId
        if (activeModul?.modulId && anmeldung?.modulanlass?.modulId) {
          anmeldung.modulanlass.modulId = activeModul.modulId;
        }
      }
    });
  }

  /**
   * Initializes studienjahrgaenge
   * @param studienjahrgaenge Takes an array of IStudienjahrgang
   * @param studienjahrgang Optional - Takes a IStudienjahrgang
   * @param student Optional - Takes a IStudent
   * @private
   */
  private initStudienjahrgaenge(studienjahrgaenge: Array<IStudienjahrgang>, student: IStudent, studienjahrgang?: IStudienjahrgang): void {
    studienjahrgaenge = studienjahrgaenge.map(asj => {
      // forces Optometrie 2022 to use the same Modulteppich as Optometrie 2021
      /*if (asj.studiengangId === 9481236) {
        asj.studiengangId = 9329818;
      }*/

      return ParseService.parseStudyMode(asj);
    });
    if (studienjahrgang){
      studienjahrgang = ParseService.parseStudyMode(studienjahrgang);
    }
    if (studienjahrgang && studienjahrgaenge.some(sjg => sjg.bezeichnung === studienjahrgang.bezeichnung)){
      /*if (studienjahrgang.studiengangId === 9481236) {
        studienjahrgang.studiengangId = 9329818;
      }*/
      this.setActiveStudienJahrgang(studienjahrgang);
      const activeStudienjahrgaenge = this.getActiveStudienjahrgaenge(studienjahrgaenge, student);
      this.setStudienJahrgaenge(activeStudienjahrgaenge);
    } else {
      const activeStudienjahrgaenge = this.getActiveStudienjahrgaenge(studienjahrgaenge, student);
      this.setStudienJahrgaenge(activeStudienjahrgaenge);
      let immatriculatedSJ;
      if (!this.authService.getUser().admin) {
        const immatriculatedSJAnmeldungen = student.studienjahrgangAnmeldungen?.filter(sja => !ignorableSJAStatuses.some(status => status === sja.statusName));
        immatriculatedSJ = activeStudienjahrgaenge.find(asj => immatriculatedSJAnmeldungen?.some(b => b.studienjahrgangId === asj.studienjahrgangId));
      } else {
        const newestStudienjahrgangAnmeldung = student.studienjahrgangAnmeldungen
          ?.sort((a, b) => a.anmeldungsDatum! < b.anmeldungsDatum! ? 1 : -1)[0];
        immatriculatedSJ = activeStudienjahrgaenge.find(asj => newestStudienjahrgangAnmeldung!.studienjahrgangId === asj.studienjahrgangId);
      }
      this.setActiveStudienJahrgang(immatriculatedSJ ? immatriculatedSJ : activeStudienjahrgaenge[0]);
    }
  }

  /**
   * Retrieves and filters the active Studienjahrgaenge for a given student.
   *
   * @param {Array<IStudienjahrgang>} studienjahrgaenge - An array of Studienjahrgaenge .
   * @param {IStudent} student - The student for whom the active Studienjahrgaenge are to be retrieved.
   *
   * @return {Array<IStudienjahrgang>} An array of active Studienjahrgang  for the student.
   */
  getActiveStudienjahrgaenge(studienjahrgaenge: Array<IStudienjahrgang>, student: IStudent): Array<IStudienjahrgang> {
    let sja = studienjahrgaenge
      .filter(sj => sj.studienjahrgangId && student.studienjahrgangAnmeldungen?.some(asj => asj.studienjahrgangId && asj.studienjahrgangId === sj.studienjahrgangId)) // only StudienjahrgangAnmeldungen
      .filter(sj => !sj.nummer?.substring(0, 5).toLowerCase().includes('m')); // remove Master program
    if (!this.authService.getUser().admin) {
      sja = sja.filter(sj => sj.statusName == null || !ignorableSJStatuses.includes(sj.statusName)); // remove inactive, historized, and planned when not admin
    }
    return sja;
  }

  /**
   * Replaces bb with Berufsbegleitend and a with Vollzeit
   * @sja Takes the Studienjahrgang
   * @public
   */
  public parseStudyMode(sja: IStudienjahrgang): IStudienjahrgang {
    sja.bezeichnung = sja.bezeichnung?.replace(' bb', ' Berufsbegleitend');
    sja.bezeichnung = sja.bezeichnung?.replace(' a', ' Vollzeit');
    return sja;
  }

  /**
   * Replaces bb with Berufsbegleitend and a with Vollzeit for multiple Studienjahrgaenge
   * @sjas Takes multiple Studienjahrgaenge
   * @public
   */
  public parseMultipleStudyModes(sjas: Array<IStudienjahrgang>): Array<IStudienjahrgang> {
    return sjas.map(sj => {
      sj.bezeichnung = sj.bezeichnung?.replace(' bb', ' Berufsbegleitend');
      sj.bezeichnung = sj.bezeichnung?.replace(' a', ' Vollzeit');
      return sj;
    });
  }

  /**
   * parses and builds module-tree, altering, inserting and removing desired properties and entries
   * @param data object containing anmeldungen and modulgruppen complemented with modul-info (tree-leaves)
   * @private
   */
  private setupModulteppich(data: { anmeldungen: Array<IAnmeldung>, modulgruppen: Array<IModul> }): void {
    if (this.getStudiengang() === Studiengang.DS) {
      data.modulgruppen = ParseService.setLevels(data.modulgruppen);
      this.setFlattenedModuleTree(ParseService.checkForDoubleModules(ParseService.checkForOldModules(data.modulgruppen)));
      this.setModuleTree(this.nestModules(ParseService.checkForDoubleModules(ParseService.checkForOldModules(data.modulgruppen))));
      this.setAnmeldungen(ParseService.parseAnmeldungen(data.anmeldungen));
      this.setAllAngemeldeteModule(ParseService.parseAngemeldeteModule(ParseService.checkForDoubleModules(data.modulgruppen)));
      this.angerechneteModule = ParseService.parseAngerechneteModule(this.getFlattenedModuleTree());
    } else {
      data.modulgruppen = ParseService.setDependingModules(data.modulgruppen);
      data.modulgruppen = ParseService.setLevels(data.modulgruppen);
      data.modulgruppen = ParseService.checkForDoubleModules(data.modulgruppen);
      this.setFlattenedModuleTree(data.modulgruppen);
      this.setModuleTree(this.nestModules(data.modulgruppen));
      this.setAnmeldungen(ParseService.parseAnmeldungen(data.anmeldungen));
      this.setAllAngemeldeteModule(ParseService.parseAngemeldeteModule(data.modulgruppen));
      this.angerechneteModule = ParseService.parseAngerechneteModule(this.getFlattenedModuleTree());
    }
  }


  /**
   * Takes a flat array of modules and returns a nested module tree based on parent-child relationships.
   *
   * @param {Array<IModul>} flatModules - An array of flat modules that will be nested.
   * @returns {Array<IModul>} A nested array of modules forming a module tree.
   */
  nestModules(flatModules: Array<IModul>): Array<IModul> {
    // Find the root module (the one with no parent)
    const rootModul = flatModules.find(modul => modul.parentModulgruppeId === null);

    // Find the immediate children of the root module
    const childrenOfRootModul = flatModules.filter(modul => modul.parentModulgruppeId === rootModul?.modulgruppeId);

    // Find all modules that are not root but could be descendants of children
    const descendantsOfChildren = flatModules.filter(modul => modul.parentModulgruppeId !== null);

    /**
     * Internal function to recursively nest child modules into their parent modules.
     *
     * @param {Array<IModul>} children - An array of child modules.
     * @param {Array<IModul>} descendants - An array of possible descendants for the child modules.
     */
    const nestChildModules = (children: Array<IModul>, descendants: Array<IModul>): void => {
      children.forEach(child => {
        child.modules = descendants
          .filter(childOfNotRootModule => childOfNotRootModule.parentModulgruppeId === child.modulgruppeId)
          .concat(this.gruppenAnrechnung.filter(a => child.modulStandardGruppeId === a.parentModulgruppeId));
        nestChildModules(child.modules, descendants.filter(childOfNotRootModule => childOfNotRootModule.parentModulgruppeId !== child.modulgruppeId));
      });
    };

    // Perform the nesting operation starting from the root
    nestChildModules(childrenOfRootModul, descendantsOfChildren);

    // Return the nested children of the root module
    return childrenOfRootModul;
  }

  calculateReachedAssessment(): void {
    if (this.getStudiengang() === Studiengang.DS) {
      this.calculateReachedDSAssessment();
    } else {
      this.calculateReachedNonDSAssessment();
    }
  }

  /**
   * Calculates and updates  assessment-related properties for assessments.
   * This method computes the total ECTS credits achieved and the number of completed modules
   * for non-Data Science assessments.
   */
  calculateReachedNonDSAssessment(): void {
    const modules = ParseService.getReachedAssessmentModules(this.getAssessmentModulesAbbreviations(), this.getAllAngemeldeteModule(), this.getAngerechneteModule());
    this.assessmentECTS = modules.reduce((totalEcts, modul) => totalEcts + (modul.modul?.ects ?? 0), 0);
    this.assessModules = modules.length;
  }

  /**
   * Calculates and updates  assessment-related properties for Data Science assessments.
   * This method computes the number of completed modules and total ECTS credits achieved
   * for different assessment groups.
   */
  calculateReachedDSAssessment(): void {
    const calculateGroup = (groupIndex: number) => {
      let ectsGroup = 0;
      let modulesGroup = 0;
      if (this.getDataScienceAssessment()) {
        const attemptModules = [...this.getAngerechneteModule(), ...this.getAllAngemeldeteModule()]
          .map(modul => modul.modules && modul.modules[0] ? modul.modules[0] : modul);
        const passedAssessmentModules = attemptModules
          .filter(modul => this.getDataScienceAssessment()[groupIndex].modulgruppen
            .filter(m => m.id && modul.parentModulgruppeId)
            .some(m => m.id === modul.parentModulgruppeId))
          .filter(modul => modul.attempts && modul.attempts!.some(a => a.status && [Modulstatus.PASSED, Modulstatus.CREDITED].includes(a.status)));
        passedAssessmentModules
          .forEach(modul => {
            ectsGroup += modul.modul?.ects ?? 0;
            modulesGroup++;
          });
      }
      return {
        ectsGroup,
        modulesGroup,
      };
    };
    this.assessmentECTSDSGroup1 = calculateGroup(0).ectsGroup;
    this.assessmentECTSDSGroup2 = calculateGroup(1).ectsGroup;
    this.assessmentModulesDSGroup1 = calculateGroup(0).modulesGroup;
    this.assessmentModulesDSGroup2 = calculateGroup(1).modulesGroup;
    this.assessModules = this.assessmentModulesDSGroup1 + this.assessmentModulesDSGroup2;
  }

  /*
  * Check if user can view modulteppich
  * */
  public canViewModulteppich(): boolean {
    if (this.getModulteppichIsComplete()) {
      if (this.authService.getRole() === 'admin') {
        return true;
      } else {
        return this.getModulteppichIsPublic();
      }
    } else {
      return false;
    }
  }

  public resetAllCalculated(): void {
    this.earnedECTS = 0;
    this.passedModules = 0;
    this.failedECTS = 0;
    this.failedModules = 0;
    this.runningECTS = 0;
    this.runningModules = 0;
    this.assessmentECTS = 0;
    this.assessModules = 0;
    this.anrechnungsECTS = 0;
    this.pauschalAnrechnungsECTS = 0;
    this.modulAnrechnungsECTS = 0;
    this.gruppenAnrechnung = [];

    this.assessmentECTSDSGroup1 = 0;
    this.assessmentECTSDSGroup2 = 0;
    this.assessmentModulesDSGroup1 = 0;
    this.assessmentModulesDSGroup2 = 0;

    this.semesters = [];
    this.setAssessmentModulesAbbreviations([]);
  }

  public calculateAll(): void {
    const student = this.getStudent();
    const flattenedModulTree = this.getFlattenedModuleTree();
    const allAngemeldeteModule = this.getAllAngemeldeteModule();
    const creditedModules = this.getAngerechneteModule();
    this.anrechnungsECTS = ParseService.calculateTotalAngerechneteECTS(student, this.getActiveStudienJahrgang(), flattenedModulTree);
    this.pauschalAnrechnungsECTS = ParseService.calculatePauschalanrechnungsECTS(this.gruppenAnrechnung);
    this.modulAnrechnungsECTS = ParseService.calculateModulanrechnungsECTS(student, flattenedModulTree);
    this.earnedECTS = ParseService.calculateTotalEarnedECTS(allAngemeldeteModule, this.anrechnungsECTS);
    this.failedECTS = ParseService.calculateTotalFailedECTS(allAngemeldeteModule);
    this.runningECTS = ParseService.calculateRunningECTS(allAngemeldeteModule);
    this.passedModules = ParseService.calculatePassedModules(allAngemeldeteModule, creditedModules);
    this.failedModules = ParseService.calculateFailedModules(allAngemeldeteModule);
    this.runningModules = ParseService.calculateRunningModules(allAngemeldeteModule);
    this.calculateReachedAssessment();
  }

  public getSemesters(): Array<Semester> {
    return this.semesters;
  }

  public setSemesters(): void {
    this.semesters = ParseService.calculateModulesPerSemester(this.getAllAngemeldeteModule());
  }

  public getLoading(): boolean {
    return this.loading;
  }

  public setLoading(isLoading: boolean): void {
    this.loading = isLoading;
  }

  public getStudiengang(): Studiengang {
    return this.studiengang;
  }

  public setStudiengang(studiengang: Studiengang): void {
    this.studiengang = studiengang;
  }

  public getAssessmentModulesAbbreviations(): Array<string> {
    return this.assessmentModules;
  }

  public setAssessmentModulesAbbreviations(assessmentModulesAbbreviations: Array<string>): void {
    this.assessmentModules = assessmentModulesAbbreviations;
  }

  public getProfileModules(): Array<IModul> {
    return this.profileModules;
  }

  public setProfileModules(profileModules: Array<IModul>): void {
    this.profileModules = profileModules;
  }

  public getDataScienceAssessment(): Array<DataScienceAssessment> {
    return this.dataScienceAssessment;
  }

  public setDataScienceAssessment(dataScienceAssessment: Array<DataScienceAssessment>): void {
    this.dataScienceAssessment = dataScienceAssessment;
  }

  public getModulteppichIsComplete(): boolean {
    return this.modulTeppichIsComplete;
  }

  public setModulteppichIsComplete(modulteppichIsComplete: boolean): void {
    this.modulTeppichIsComplete = modulteppichIsComplete;
  }

  public getModulteppichIsPublic(): boolean {
    return this.modulTeppichIsPublic;
  }

  public setModulteppichIsPublic(isPublic: boolean): void {
    this.modulTeppichIsPublic = isPublic;
  }

  public getStudent(): IStudent {
    return this.student;
  }

  public setStudent(student: IStudent): void {
    this.student = student;
  }

  public getStudierende(): Array<StudierendeWithAnmeldungen> {
    return this.studierende;
  }

  public setStudierende(studierende: Array<StudierendeWithAnmeldungen>): void {
    this.studierende = studierende;
  }

  public getStudentStudienJahrgaenge(): Array<IStudienjahrgang> {
    return this.studienJahrgaenge;
  }

  public setStudienJahrgaenge(studienJahrgang: Array<IStudienjahrgang>): void {
    this.studienJahrgaenge = studienJahrgang;
  }

  public getActiveStudienJahrgang(): IStudienjahrgang {
    return this.activeStudienJahrgang;
  }

  public setActiveStudienJahrgang(studienJahrgang: IStudienjahrgang): void {
    this.activeStudienJahrgang = studienJahrgang;
  }

  public getFlattenedModuleTree(): Array<IModul> {
    return this.flattenedModuleTree;
  }

  public setFlattenedModuleTree(flattenedModuleTree: Array<IModul>): void {
    this.flattenedModuleTree = flattenedModuleTree;
  }

  public getModuleTree(): Array<IModul> {
    return this.moduleTree;
  }

  public setModuleTree(moduleTree: Array<IModul>): void {
    this.moduleTree = moduleTree;
  }

  public getAnmeldungen(): Array<IAnmeldung> {
    return this.anmeldungen;
  }

  public setAnmeldungen(anmeldungen: Array<IAnmeldung>): void {
    this.anmeldungen = anmeldungen;
  }

  public getModulanlaesse(): Array<IModulanlass> {
    return this.modulanlaesse;
  }

  public setModulanlaesse(modulanlaesse: Array<IModulanlass>): void {
    this.modulanlaesse = modulanlaesse;
  }

  public getAngerechneteModule(): Array<IModul> {
    return this.angerechneteModule;
  }

  public getAllAngemeldeteModule(): Array<IModul> {
    return this.allAngemeldeteModule;
  }

  public setAllAngemeldeteModule(angemeldeteModule: Array<IModul>): void {
    this.allAngemeldeteModule = angemeldeteModule;
  }

  public calculatePassedGroupECTS(modulGroup: IModul): number {
    if (this.getStudiengang() === Studiengang.DS) {
      return ParseService.calculatePassedGroupECTSDataScience(modulGroup);
    } else {
      return ParseService.calculatePassedGroupECTSNonDataScience(modulGroup);
    }
  }
}
