import {IStudent} from "./Student";
import {IAnmeldung} from "./Anmeldung";
import {IStudienjahrgang} from "./Studienjahrgang";

export interface IStudierendeWithAnmeldungen {
  student?: IStudent;
  anmeldungen?: IAnmeldung[];
  studienjahrgangInfo?: IStudienjahrgang;
  modulteppichIsPublic?: boolean;
  modulteppichIsComplete?: boolean;
  totalFailedEcts?: number;
  assessmentECTS?: number;
}
export class StudierendeWithAnmeldungen implements IStudierendeWithAnmeldungen {
  student?: IStudent;
  anmeldungen?: IAnmeldung[];
  studienjahrgangInfo?: IStudienjahrgang;
  modulteppichIsPublic?: boolean;
  modulteppichIsComplete?: boolean;
  totalFailedEcts?: number;
  assessmentECTS?: number;

  constructor(student?: IStudent, anmeldungen?: IAnmeldung[], studienjahrgangInfo?: IStudienjahrgang, modulteppichIsPublic?: boolean, modulteppichIsComplete?: boolean, totalFailedEcts?: number, assessmentECTS?: number) {
    this.student = student;
    this.anmeldungen = anmeldungen;
    this.studienjahrgangInfo = studienjahrgangInfo;
    this.modulteppichIsPublic = modulteppichIsPublic;
    this.modulteppichIsComplete = modulteppichIsComplete;
    this.totalFailedEcts = totalFailedEcts;
    this.assessmentECTS = assessmentECTS;
  }
}

