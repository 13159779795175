import {Component, OnInit} from '@angular/core';
import {ThemeService} from '../services/theme.service';

declare const particlesJS: any;

@Component({
  selector: 'app-snow',
  templateUrl: './snow.component.html'
})
export class SnowComponent implements OnInit {

  constructor(
    public themeService: ThemeService
  ) {}

  ngOnInit(): void {
    this.configureSnow();
  }


  /**
   * Configures the snow for the winter theme
   */
  configureSnow(): void {
    particlesJS('particles-js', {
      particles: {
        number: {
          value: 200,
          density: {
            enable: true,
            value_area: 800
          }
        },
        color: {
          value: '#ffffff'
        },
        opacity: {
          value: 0.5,
          random: false,
        },
        size: {
          value: 3,
          random: true,
        },
        line_linked: {
          enable: false,
        },
        move: {
          enable: true,
          speed: 2,
          direction: 'bottom',
          straight: false
        }
      },
      // retina_detect is disabled due to performance
      // https://github.com/VincentGarreau/particles.js/issues/306#issuecomment-392914538
      retina_detect: false
    });
  }
}
