import {AuthConfig} from 'angular-oauth2-oidc';
import {environment} from '../../environments/environment';

export class AuthenticationConfig {
  authConfig: AuthConfig = {
    issuer:
      -1 < window.location.origin.indexOf('studenthub-test') ||
      -1 < window.location.origin.indexOf('localhost')
        ? 'https://oidc.test.fhnw.ch/identity'
        : 'https://oidc.fhnw.ch/identity',
    redirectUri: environment.redirectUri,
    silentRefreshRedirectUri: environment.redirectUri,
    useSilentRefresh: true,
    clientId: 'ht-studenthub',
    scope: 'openid ht-studenthub email profile read basic assert_fhnw_id_person',
    responseType: 'id_token token',
  };
}
