import {Component, Input, OnInit} from '@angular/core';
import {IModul} from '../../models/Modul';

@Component({
  selector: 'app-ects',
  templateUrl: './ects.component.html',
  styleUrls: ['./ects.component.scss']
})
export class ECTSComponent implements OnInit {

  constructor() {
  }

  @Input() modulGroup!: IModul;
  @Input() passedGroupECTS!: number;
  @Input() maxGroupECTS!: number | string;
  @Input() minGroupECTS!: number | string;
  @Input() positionAbsolute?: boolean;
  @Input() displayMinECTS?: boolean;
  @Input() padding = true;
  @Input() bold = false;
  @Input() showMin = false;
  @Input() showMax = false;

  ngOnInit(): void {
  }
}
