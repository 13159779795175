import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import {NavigationService} from './services/navigation.service';
import {NavigationEnd, Router} from '@angular/router';
import {SidenavService} from './services/sidenav.service';
import {SidenavRightService} from './services/sidenav-right.service';
import {ThemeService} from './services/theme.service';
import {ParseService} from './services/parse.service';
import {ModulService} from './services/modul.service';
import {AuthService} from './services/auth.service';
import {environment} from '../environments/environment';
import {SnackbarService} from './services/snackbar.service';
import {Constants} from './models/Constants';
import {View} from './views/views';
import { VERSION, BUILD_TIMESTAMP } from '../environments/version';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements AfterViewChecked, OnInit {

  @ViewChild('modal', {read: ViewContainerRef}) modal!: ViewContainerRef;

  public env = environment;

  hideInfoModal = true;
  hideOnboardingModal = true;

  constructor(
    public naviService: NavigationService,
    public parseService: ParseService,
    public themeService: ThemeService,
    public authService: AuthService,
    public router: Router,
    private modulService: ModulService,
    private sidenavService: SidenavService,
    private sidenavRightService: SidenavRightService,
    private cdr: ChangeDetectorRef,
    public snackbarService: SnackbarService,
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.modulService.modulSearchArray.next([]);
      }
    });
  }

  private static hasSeenInfoModal = () => JSON.parse(localStorage.getItem(Constants.START_MODAL_KEY) ?? 'false');
  public static setHasSeenInfoModal = () => localStorage.setItem(Constants.START_MODAL_KEY, JSON.stringify(true));

  ngOnInit(): void {
    console.log('Current Git Commit Hash:', VERSION);
    console.log('Build Timestamp:', BUILD_TIMESTAMP);
    this.hideInfoModal = AppComponent.hasSeenInfoModal();
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  closeNavigations(event: MouseEvent): void {
    if (this.parseService.currentView.getValue() !== View.TREE && !this.naviService.getCurrentNavigation().includes('admin')) {
      // Let timeline view handle the closing of the left sidenav itself.
      this.sidenavService.clearSelectedModule()
    }
    this.naviService.closeFilterSettings();
    this.sidenavRightService.setIsExpanded(false);
    this.closeDropdowns();
    event.stopPropagation();
  }

  closeDropdowns(): void {
    this.naviService.closeStudiengaenge();
    this.naviService.closeUserSettings();
  }

  handleScroll(): void {
    this.naviService.setCurrentScroll(window.scrollY);
    this.closeDropdowns();
  }

  @HostListener('window:scroll', ['$event'])
   onWindowScroll(): void {
    this.handleScroll();
  }
}
