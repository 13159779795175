import {Component, Input, OnChanges, OnInit, SimpleChanges} from "@angular/core";
import {IModul} from "../../../../models/Modul";
import {Studiengang} from "../../../../models/Studiengaenge";
import {ThemeService} from "../../../../services/theme.service";
import {View} from "../../../../views/views";
import {v2ApiService} from "../../../services/v2api.service";
import {V2Module} from "../../../models/V2Module";
import {V2Student} from "../../../models/V2Student";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {ParseService} from "../../../../services/parse.service";
import {ModulService} from "../../../../services/modul.service";
import {V2Attempt} from "../../../models/V2Attempt";
import {calculateCurrentModuleStatus} from "../../../v2utils";

@Component({
  selector: 'app-v2depending-modules',
  templateUrl: './v2depending-modules.component.html',
  styleUrls: ['./v2depending-modules.component.scss']
})

export class V2dependingModulesComponent implements OnInit, OnChanges{

  //TODO Get rid of IModule, once the entire v2SideNav is being implemented!
  @Input() module!: IModul;
  v2module: V2Module;
  dependingV2Modules: Array<V2Module> = [];
  student: V2Student;
  studiengang = Studiengang;

  constructor(
    public v2ApiService: v2ApiService,
    public parseService: ParseService,
    public themeService: ThemeService,
    public moduleService: ModulService
  ) {
  }

  // Will not only contain depending modules once Modulteppich runs on V2
  moduleAttemptMap: Map<V2Module, Array<V2Attempt>> = new Map();

  ngOnInit() {
    this.getV2Module();
  }

  // This will also not be necessary once the V2 Modulteppich is implemented
  ngOnChanges(changes: SimpleChanges) {
    this.getV2Module();
  }

  fetchStudentData(): Observable<V2Student> {
    return this.v2ApiService.fetchStudent().pipe(
      tap((data) => {
        if (data) {
          this.student = data;
        } else {
          console.error('Received null student data');
        }
      })
    );
  }

  getV2Module(): void {
    this.fetchStudentData().subscribe(
      () => {
        this.assignV2Modules();
        this.mapModulesToAttempts();
      },
      (error) => console.error('Error fetching student', error)
    );
  }

  assignV2Modules(): void {
    this.v2module = this.student.modules.find(v2mod => v2mod.abbreviation === this.module.abbreviation) || null;
    this.dependingV2Modules = this.student.modules.filter(module =>
      this.module.dependingModules.some(dep => dep.abbreviation === module.abbreviation)
    );
  }

  mapModulesToAttempts(): void {
    this.dependingV2Modules.forEach(dep => {
      const moduleAttempts = this.student.attempts.filter(att => att.moduleAbbreviation === dep.abbreviation);

      moduleAttempts.sort((a, b) => this.compareSemesters(b.semesterAbbreviation, a.semesterAbbreviation));

      this.moduleAttemptMap.set(dep, moduleAttempts);
    });
  }

  compareSemesters(semA: string, semB: string) {
    const parseSemester = (sem: string) => {
      const [season, year] = [sem.slice(0, 2), parseInt(sem.slice(2), 10)];
      const seasonValue = season === 'FS' ? 0 : 1;
      //Creates a unique numeric value for each semester.
      return year * 2 + seasonValue;
    }

    const aValue = parseSemester(semA);
    const bValue = parseSemester(semB);

    // Sorting in descending order.
    return aValue - bValue;
  }

  public openInModuleTree(): void {
    if (this.module?.abbreviation){
      this.moduleService.searchInput = this.v2module.abbreviation;
      this.moduleService.modulSearchArray.next([this.module]);
    }
    this.parseService.currentView.next(View.TREE);
  }
  public readonly View = View;
  protected readonly calculateCurrentModuleStatus = calculateCurrentModuleStatus;
}
