import {Component, OnInit} from '@angular/core';
import {ThemeService} from '../../services/theme.service';
import {ParseService} from '../../services/parse.service';
import {Studiengang} from '../../models/Studiengaenge';

@Component({
  selector: 'app-legend',
  templateUrl: './legend.component.html',
  styleUrls: ['./legend.component.scss']
})
export class LegendComponent implements OnInit {

  constructor(
    public themeService: ThemeService,
    public parseService: ParseService
  ) {
  }

  public studiengang = Studiengang;

  ngOnInit(): void {
  }

}
