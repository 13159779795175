export const ignorableStatuses = [
  'aA.Abgemeldet',
  'aA.Hist.Abgemeldet',
  'aA.Abgemeldet nicht verrechnbar',
  'aA.Hist.Abgemeldet nicht verrechenbar',
  'aA.Abgesagt',
  'aA.Hist.Abgesagt',
  'aA.Provisorisch', // view in the future similar to active?
  'aA.Warteliste',
  'aA.Warteliste Internet',
];

export const activeStatuses = [
  'aA.Angemeldet',
  'aA.Angemeldet Internet',
  'aA.Angemeldet nicht verrechenbar',
  'aA.Bestätigt',
];

export const passedStatuses = [
  'aA.Erfolgreich teilgenommen',
  'aA.Hist.Erfolgreich teilgenommen',
  'aA.Erfolgreich teilgenommen nicht verrechenbar',
  'aA.Hist.Erfolgreich teilgenommen nicht verrechenbar',
  'mA.Erlassen',
];

export const failedStatuses = [
  'aA.Nicht erfolgreich teilgenommen',
  'aA.Hist.Nicht erfolgreich teilgenommen',
  'aA.Nicht erfolgreich teilgenommen nicht verrechenbar',
  'aA.Hist.Nicht erfolgreich teilgenommen nicht verrechenbar',
];
