import {Component} from '@angular/core';
import {ModulteppichDirective} from '../modulteppich.directive';
import {SidenavRightService} from '../../../services/sidenav-right.service';
import {ParseService} from '../../../services/parse.service';
import {ModulService} from '../../../services/modul.service';
import {FilterService} from '../../../services/filter.service';

@Component({
  selector: 'app-no-match',
  templateUrl: './no-match.component.html',
  styleUrls: ['./no-match.component.scss'],
})
export class NoMatchComponent extends ModulteppichDirective {

  constructor(
    sideNavRightService: SidenavRightService,
    parseService: ParseService,
    modulService: ModulService,
    filterService: FilterService
  ) {
    super(sideNavRightService, parseService, modulService, filterService);
  }
}
