import {Component} from '@angular/core';
import {ModulteppichDirective} from '../modulteppich.directive';
import {SidenavRightService} from 'src/app/services/sidenav-right.service';
import {ModulService} from '../../../services/modul.service';
import {FilterService} from '../../../services/filter.service';
import {ParseService} from '../../../services/parse.service';
import {ModulgroupService} from '../../../services/modulgroup.service';
import {IModul} from '../../../models/Modul';

@Component({
  selector: 'app-modulteppich-optometrie-2022',
  templateUrl: './modulteppich-optometrie-2022.component.html',
  styleUrls: ['../modulteppich.component.scss', './modulteppich-optometrie-2021.component.scss'],
})
export class ModulteppichOptometrie2022Component extends ModulteppichDirective {

  sortByPosition = ParseService.sortByPosition;
  calculateMinGroupECTS = ParseService.calculateMinGroupECTS;

  constructor(
    sideNavRightService: SidenavRightService,
    parseService: ParseService,
    modulService: ModulService,
    filterService: FilterService,
    public modulgroupService: ModulgroupService
  ) {
    super(sideNavRightService, parseService, modulService, filterService);
    this.manipulateKontextData();
    this.modulTree = this.parseService.getModuleTree();
  }

  public static readonly KOMMUNIKATION_ECTS = 2;

  public modulTree: Array<IModul> = [];

  protected readonly ParseService = ParseService;

  // TODO: Maybe has to be removed once updated Evento Data is delivered
  private manipulateKontextData(): void {
    const flattenedModulGroup = this.parseService.getFlattenedModuleTree();

    const kontextGroup = this.findGroupByName(flattenedModulGroup, 'kontext');
    const kommunikationGroup = this.findGroupByName(flattenedModulGroup, 'kommunikation');
    const fachausbildungGroup = this.findGroupByName(flattenedModulGroup, 'fachausbildung');
    let betriebswirtschaftGroup = this.findGroupByName(flattenedModulGroup, 'betriebswirtschaftslehre');

    betriebswirtschaftGroup = this.updateBezeichnung(betriebswirtschaftGroup!, 'Betriebswirtschaftslehre');

    if (kontextGroup && kommunikationGroup && fachausbildungGroup && kommunikationGroup.minECTS) {
      this.updateMinECTSValues(kontextGroup, kommunikationGroup, fachausbildungGroup);
      this.updateFlattenedModuleTree(flattenedModulGroup, kontextGroup, kommunikationGroup, fachausbildungGroup, betriebswirtschaftGroup);
    }
  }

  private findGroupByName(flattenedModulGroup: Array<IModul>, groupName: string): IModul | undefined {
    return flattenedModulGroup.find(modul => modul.bezeichnung?.toLocaleLowerCase().includes(groupName));
  }

  private updateBezeichnung(group: IModul, newBezeichnung: string): IModul {
    group.bezeichnung = newBezeichnung;
    return group;
  }

  private updateMinECTSValues(kontextGroup: IModul, kommunikationGroup: IModul, fachausbildungGroup: IModul): void {
    const differenceToMinECTS = Math.abs(ModulteppichOptometrie2022Component.KOMMUNIKATION_ECTS - kommunikationGroup.minECTS!);
    kontextGroup.minECTS = (kontextGroup.minECTS || 0) - differenceToMinECTS;
    kommunikationGroup.minECTS = ModulteppichOptometrie2022Component.KOMMUNIKATION_ECTS;
  }

  private updateFlattenedModuleTree(flattenedModulGroup: Array<IModul>, kontextGroup: IModul, kommunikationGroup: IModul, fachausbildungGroup: IModul, betriebswirtschaftGroup: IModul): void {
    const indexToBeReplacedKontext = flattenedModulGroup.indexOf(kontextGroup);
    const indexToBeReplacedKommunikation = flattenedModulGroup.indexOf(kommunikationGroup);
    const indexToBeReplacedFachausbildung = flattenedModulGroup.indexOf(fachausbildungGroup);
    const indexToBeReplacedBetriebswirtschaftslehre = flattenedModulGroup.indexOf(betriebswirtschaftGroup);

    if (indexToBeReplacedKontext !== -1 && indexToBeReplacedKommunikation !== -1 && indexToBeReplacedFachausbildung !== -1 && indexToBeReplacedBetriebswirtschaftslehre) {
      flattenedModulGroup[indexToBeReplacedKontext] = kontextGroup;
      flattenedModulGroup[indexToBeReplacedKommunikation] = kommunikationGroup;
      flattenedModulGroup[indexToBeReplacedFachausbildung] = fachausbildungGroup;
      flattenedModulGroup[indexToBeReplacedBetriebswirtschaftslehre] = betriebswirtschaftGroup;
      this.parseService.setFlattenedModuleTree(flattenedModulGroup);
    }
  }
}
