import {Modulteppich} from './Modulteppich';
import {DataScienceAssessment} from './DataScienceAssessment';
import {CompetenceGroup} from './CompetenceGroup';

// TODO: is this even useful anymore?
export enum Studiengang {
  NoMatch = 'NOMATCH',
  DS = 'DS',
  I2013 = 'I2013',
  I2016 = 'I2016',
  I2018 = 'I2018',
  IC2016 = 'IC2016',
  IC2018 = 'IC2018',
  WING2019 = 'WING2019',
  STE = 'STE',
  M18 = 'M18',
  M20 = 'M20',
  EIT = 'EIT',
  EUT = 'EUT',
  O21 = 'O21',
  O22 = 'O22'
}

export class Studiengaenge {
  // TODO(laniw): Merge this with isModulteppichComplete.
  static readonly availableModulteppiche: Array<Modulteppich> = [
    new Modulteppich(Studiengang.DS, [9382681], true, true, [
      new DataScienceAssessment([
          new CompetenceGroup('Mathematische Kompetenzen', 6736330),
          new CompetenceGroup('Datenanalyse', 6736331)
        ], 15
      ),
      new DataScienceAssessment([
          new CompetenceGroup('Informatik und Programmierung', 6736332),
          new CompetenceGroup('Datenverarbeitung und Infrastruktur', 6736333),
          new CompetenceGroup('Machine Learning', 6736334),
          new CompetenceGroup('Angewandte Data Science', 6736335),
          new CompetenceGroup('Datenvisualisierung', 6736336)
        ], 12
      )
    ]),
    new Modulteppich(Studiengang.I2013, [9118568], false, true, [
      'algd1', 'oopI1', 'oopI2',
      'swc', 'uuid', 'req',
      'bsys', 'chp', 'sysad',
      'eana', 'lag', 'mgli'
    ]),
    new Modulteppich(Studiengang.I2016, [9217717], true, true, [
      'algd1', 'oopI1', 'oopI2',
      'swc', 'uuid', 'req',
      'bsys', 'chp', 'sysad',
      'eana', 'lag', 'mgli'
    ]),
    new Modulteppich(Studiengang.I2018, [9286375], true, true, [
      'algd1', 'oopI1', 'oopI2',
      'swc', 'edbs', 'req',
      'bsys', 'dnet1', 'sysad',
      'eana', 'lag', 'mgli'
    ]),
    new Modulteppich(Studiengang.IC2016, [9116658], true, true, [
      'eipr', 'oopI1', 'oopI2', 'ipg',
      'mgli', 'lag', 'mada', 'sweGL', 'req',
      'dtpC', 'uidC', 'pmC'
    ]),
    new Modulteppich(Studiengang.IC2018, [9285763], true, true, [
      'eipr', 'oopI1', 'oopI2', 'ipg',
      'mgli', 'lag', 'mada', 'sweGL', 'req',
      'dtpC', 'uidC', 'pmC'
    ]),
    new Modulteppich(Studiengang.EIT, [9102154, 9337695, 9447275], true, true, [
      'lalg1', 'alg', 'an1', 'an2',
      'wus', 'mech', 'aet1', 'aet2', 'dt1',
      'oop1', 'oop2',
      'glaL2'
    ]),
    new Modulteppich(Studiengang.EUT, [9329308], true, true, [
      'enru', 'eltU', 'tfd1',
      'oeks',
      'algU', 'an1', 'an2U', 'de1',
      'wus', 'mechU', 'goek', 'ch1'
    ]),
    new Modulteppich(Studiengang.WING2019, [9305322], true, true, [
      'krw', 'kbwl', 'krecht', 'kmat', 'kph',
      'magl', 'mechW', 'eltW', 'beor', 'mkt', 'kosr',
      'prgm', 'pro1'
    ]),
    new Modulteppich(Studiengang.STE, [6001024, 9286673], true, true, [
      'an1', 'an2', 'lalg1', 'alg', 'mech',
      'edbs', 'oop1', 'oop2',
      'etgl', 'ant1', 'fertv', 'stt',
    ]),
    new Modulteppich(Studiengang.M18, [9242770], true, true, [
      'lalg1', 'lalg2', 'infM',
      'an1', 'an2',
      'wus', 'mechM', 'ch1', 'wch',
      'werk2', 'mel',
      'stk', 'thdM'
    ]),
    new Modulteppich(Studiengang.M20, [9331844], true, true, [
      'lalg1', 'lalg2', 'infM',
      'an1', 'an2',
      'wus', 'mechM', 'ch1', 'wch',
      'werk2', 'mel',
      'stk', 'thdM'
    ]),
    new Modulteppich(Studiengang.O21, [9102337, 9102338, 9329818, 9468551], true, true, []), // opto FR 2014-2020
    new Modulteppich(Studiengang.O22, [9481236], true, true, []), // opto 2022 - 2023
    new Modulteppich(Studiengang.NoMatch, [0], true, true, [])
  ];
}
