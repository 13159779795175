<div class="data-science-container">
  <div class="header-container-3-6">
    <app-modulteppich-header></app-modulteppich-header>
  </div>
  <ng-container *ngFor="let group of modulTree">
    <div
      style="grid-column-start: 3; grid-column-end: 4; grid-row-start: 4; grid-row-end: 5; background-color: var(--primary-background-color); position: relative;">
      <div *ngIf="group.bezeichnung!.toLowerCase().includes('projekt')"
           class="container-title-data-science" style="z-index: 1; position: absolute; left: 20px;"
           [title]="group.bezeichnung">{{ group.bezeichnung }}</div>
    </div>
    <div class="data-science-callenge-projekt-container" *ngIf="group.bezeichnung!.toLowerCase().includes('projekt')">
      <app-status-bubble [positionRight]="false"
                         [positionTop]="false"
                         [left]="0"
                         [bottom]="30"
                         [hidden]="!(filterService.getShowFilter('Bestanden') || filterService.getShowFilter('Fehlgeschlagen'))"
                         [modulGroup]="group"
                         [status]="modulgroupService.calcBubbleStatus(group, '#00D0CD')">
      </app-status-bubble>
      <div class="data-science-callenge-and-projekt-container">
        <div class="data-science-projekt-container">
          <ng-container *ngFor="let subgroup of sortByPosition(group.modules!, {isDataScience: true})">
            <div *ngIf="!subgroup.bezeichnung!.toLowerCase().includes('challenge')" class="projekt">
              <app-modul [timeline]="false"
                         [module]="subgroup">
              </app-modul>
            </div>
          </ng-container>
        </div>
        <div class="data-science-callenge-container">
          <ng-container *ngFor="let subgroup of sortByNiveau(group.modules!)">
            <div *ngIf="!subgroup.bezeichnung!.toLowerCase().includes('projekt') &&
             !subgroup.bezeichnung!.toLowerCase().includes('bachelor')" class="projekt">
              <app-modul [timeline]="false"
                         [module]="subgroup">
              </app-modul>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="data-science-ects">
        <app-ects [bold]="true"
                  [padding]="false"
                  [displayMinECTS]="false"
                  [passedGroupECTS]="parseService.calculatePassedGroupECTS(group)"
                  [minGroupECTS]="calculateMinGroupECTS(group)"
                  [modulGroup]="group">
        </app-ects>
        <app-ects [padding]="false"
                  [modulGroup]="group"
                  [displayMinECTS]="true"
                  [showMax]="true"
                  [maxGroupECTS]="calculateMaxGroupECTS(group)">
        </app-ects>
      </div>
    </div>
  </ng-container>
  <div class="data-science-modulteppich-spacer"></div>
  <div class="data-science-fachausbildung-container">
    <ng-container *ngFor="let group of sortByPosition(modulTree, {isDataScience: true, ascending: true})">
      <div class="data-science-group-container" *ngIf="!group.bezeichnung!.toLowerCase().includes('projekt')">
        <app-status-bubble [positionRight]="false"
                           [positionTop]="false"
                           [hidden]="!(filterService.getShowFilter('Bestanden') || filterService.getShowFilter('Fehlgeschlagen'))"
                           [modulGroup]="group"
                           [left]="-8"
                           [bottom]="30"
                           [status]="modulgroupService.calcBubbleStatus(group, '#00D0CD')">
        </app-status-bubble>
        <div class="container-title-data-science" [title]="group.bezeichnung">{{ group.bezeichnung }}</div>
        <div class="projekt-grid-data-science">
          <ng-container *ngFor="let subgroup of sortByNiveau(group.modules!)">
            <div class="projekt" *ngIf="
            !group.bezeichnung!.toLowerCase().includes('freie module') ||
             subgroup.angerechnet ||
             subgroup.attempts!.length > 0 ||
              subgroup.modules![0]! && subgroup.modules![0]!.attempts!.length > 0">
              <app-modul
                [timeline]="false"
                [module]="subgroup">
              </app-modul>
            </div>
          </ng-container>
        </div>
        <div class="data-science-ects">
          <app-ects [bold]="true"
                    [padding]="false"
                    [displayMinECTS]="false"
                    [passedGroupECTS]="parseService.calculatePassedGroupECTS(group)"
                    [minGroupECTS]="calculateMinGroupECTS(group)"
                    [modulGroup]="group">
          </app-ects>
          <app-ects [padding]="false"
                    [modulGroup]="group"
                    [displayMinECTS]="true"
                    [showMax]="true"
                    [maxGroupECTS]="calculateMaxGroupECTS(group)">
          </app-ects>
        </div>
      </div>
    </ng-container>
  </div>
</div>
