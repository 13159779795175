
import {DataScienceAssessment} from './DataScienceAssessment';
import {Studiengang} from './Studiengaenge';

export class Modulteppich {
  private studiengang: Studiengang;
  private studiengangIds: Array<number>;
  private isPublic: boolean;
  private isComplete: boolean;
  private assessment: Array<string | DataScienceAssessment>;

  constructor(studiengang: Studiengang, studiengangIds: Array<number>, isPublic: boolean, isComplete: boolean, assessment: Array<string | DataScienceAssessment>) {
    this.studiengang = studiengang;
    this.studiengangIds = studiengangIds;
    this.isPublic = isPublic;
    this.isComplete = isComplete;
    this.assessment = assessment;
  }

  getStudiengang(): Studiengang {
    return this.studiengang;
  }

  setStudiengang(value: Studiengang): void {
    this.studiengang = value;
  }

  getStudiengangIds(): Array<number> {
    return this.studiengangIds;
  }

  setStudiengangIds(value: Array<number>): void {
    this.studiengangIds = value;
  }

  getIsPublic(): boolean {
    return this.isPublic;
  }

  setIsPublic(value: boolean): void {
    this.isPublic = value;
  }

  getIsComplete(): boolean {
    return this.isComplete;
  }

  setIsComplete(value: boolean): void {
    this.isComplete = value;
  }

  getAssessment(): Array<string | DataScienceAssessment> {
    return this.assessment;
  }

  setAssessment(value: Array<string>): void {
    this.assessment = value;
  }
}
