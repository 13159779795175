import {Component, ElementRef, Input, OnChanges, ViewChild} from '@angular/core';
import {ThemeService} from '../../services/theme.service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnChanges {

  @Input() title!: string;
  @Input() float?: string;
  @Input() displayMax = true;
  @Input() marginTop?: string;
  @Input() textMarginTop?: string;
  @Input() marginBottom?: string;
  @Input() textMarginBottom?: string;
  @Input() isSubBar = false;
  @Input() width?: number;
  @Input() label = 'Label';
  @Input() max = 100;
  @Input() value = 50;
  @Input() color = 'var(--accent-blue)';
  @Input() isProfilierung = false;
  @ViewChild('progressbar') progressbar!: ElementRef;
  public percentage!: number;

  constructor(
    public themeService: ThemeService
  ) {
  }

  ngOnChanges(): void {
    this.setPercentage(this.max, this.value);
  }

  getProgressWidth(totalWidth: number): number {
    return totalWidth * this.percentage / 100 > totalWidth ? totalWidth : totalWidth * this.percentage / 100;
  }

  setPercentage(max: number, value: number): void {
    const result = Math.ceil(100 / max * value);
    if (result < 100) {
      Number.isFinite(result) ? this.percentage = result : this.percentage = 0;
    } else {
      this.percentage = 100;
    }
  }
}

