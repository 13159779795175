<app-status-bubble *ngIf="showStatusBubble"
                   [hidden]="!(filterService.getShowFilter('Bestanden') || filterService.getShowFilter('Fehlgeschlagen'))"
                   [modulGroup]="modulGroup"
                   [status]="modulgroupService.calcBubbleStatus(modulGroup, '#00D0CD')">
</app-status-bubble>
<div class="modulgroup-container height-full">
  <div>
    <app-modulgroup-title *ngIf="showTitle" [modulGroup]="modulGroup"></app-modulgroup-title>
    <div class="credits-section">
      <app-ects *ngIf="showECTS"
                [displayMinECTS]="false"
                [passedGroupECTS]="parseService.calculatePassedGroupECTS(modulGroup)"
                [minGroupECTS]="calculateMinGroupECTS(modulGroup)"
                [modulGroup]="modulGroup">
      </app-ects>
      <app-gruppenanrechnung [group]="modulGroup"></app-gruppenanrechnung>
    </div>
  </div>
  <div #projectGrid *ngIf="showModules" class="projekt-grid"
       [ngClass]="[
        isProject ? 'project-spacing' : 'default-spacing',
        spaceBetween ? 'project-spacing' : '',
        numberOfColumns ? 'grid-template' : '',
        aligningBottom ? 'bottom-aligning' : 'default-aligning',
        usePadding ? 'modulgroup-padding' : '',
        orderColWise ? 'column-ordering' : ''
       ]"
       [style.flex-wrap]="wrap ? 'wrap' : ''"
       [style.flex-direction]="orderRowWise ? 'row' : 'column'"
       [style.grid-template-columns]="numberOfColumns ? 'repeat(' + numberOfColumns + ', 1fr)' : ''"
       [style.height]="heightPixel + 'px'">
    <ng-container *ngIf="!optometrie">
      <ng-container *ngFor="let subgroup of modulGroup.modules; let i = index;">
        <div class="projekt"
             *ngIf="(maxElements ? i < maxElements : true) && subgroup.modules && subgroup.modules![0]!">
          <app-modul [timeline]="false"
                     [module]="subgroup"
                     [isProject]="isProject">
          </app-modul>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="optometrie">
      <ng-container *ngFor="let subgroup of modulGroup.modules">
        <ng-container *ngFor="let subsubgroup of subgroup.modules; let i = index;">
          <div class="projekt" *ngIf="(maxElements ? i < maxElements : true) && subsubgroup.modules![0]!">
            <app-modul [timeline]="false"
                       [module]="subsubgroup"
                       [isProject]="isProject">
            </app-modul>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="maxElements && maxElements < modulGroup!.modules!.length">
      <app-show-more-modules [modulGroup]="modulGroup"
                             [showMoreModuleHeight]="showMoreModuleHeight"
                             [maxElements]="maxElements">
      </app-show-more-modules>
    </ng-container>
  </div>
</div>
