<div class="depending-modules-wrapper" *ngIf="module.dependingModules?.length! > 0">
  <div class="depending-modules-title display5 tertiary-color">
    <span>Empfohlene Vorkenntnisse</span>
    <span *ngIf="parseService.currentView.getValue() !== View.TREE" class="open-in-modul-tree-label"
          (click)="openInModuleTree()">Im Modulbaum öffnen</span>
  </div>
  <div class="depending-modules-container">
    <div class="parent-module-container">
      <app-modul [module]="module" [timeline]="false"></app-modul>
    </div>
    <div class="arrow-container mt-15">
      <app-arrow></app-arrow>
    </div>
    <div class="child-modules-container mt-15">
      <ng-container *ngFor="let mod of module.dependingModules">
        <app-modul [module]="mod" [timeline]="false"></app-modul>
      </ng-container>
    </div>
  </div>
</div>
