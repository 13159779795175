export class User {
  id: string;
  admin: boolean;
  vorname: string;
  nachname: string;

  constructor(id: string, admin: boolean, vorname: string, nachname: string) {
    this.id = id;
    this.admin = admin;
    this.vorname = vorname;
    this.nachname = nachname;
  }
}
