import {Component, DoCheck, OnInit} from "@angular/core";
import { ThemeService } from "src/app/services/theme.service";
import { FilterService } from "../../../services/filter.service";
import {ProfilierungService} from "../../../services/profilierung.service";

@Component({
  selector: "app-modulteppich-profilierung-header",
  templateUrl: "./modulteppich-profilierung-header.component.html",
  styleUrls: ["./modulteppich-profilierung-header.component.scss"],
})
export class ProfilierungHeaderComponent implements OnInit, DoCheck {

  constructor(
    public themeService: ThemeService,
    public profilierungService: ProfilierungService,
    public filterService: FilterService
  ) {
  }

  ngOnInit(): void {
    this.profilierungService.updateProfilierungData();
  }

  ngDoCheck(): void {
    this.profilierungService.updateProfilierungData();
  }

}
