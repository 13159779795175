import {Component, Input} from '@angular/core';
import {ModulService} from '../services/modul.service';
import {SidenavRightService} from '../services/sidenav-right.service';
import {IModul, Modul} from '../models/Modul';
import {SidenavService} from '../services/sidenav.service';

@Component({
  selector: 'app-show-more-modules',
  templateUrl: './show-more-modules.component.html',
  styleUrls: ['./show-more-modules.component.scss']
})
export class ShowMoreModulesComponent {

  @Input() modulGroup!: IModul;
  @Input() showMoreModuleHeight!: string;
  @Input() maxElements!: number;

  constructor(
    public modulService: ModulService,
    public sidenavService: SidenavService,
    public sideNavRightService: SidenavRightService
  ) {
  }

  calcOpacity(): string {
    const moreModulesArray = this.modulGroup.modules!.slice(this.maxElements);
    let opacity = '1';
    if (this.sidenavService.getSelectedModules().length !== 0 && !this.dependingModulesOfSelectedModuleInArray(moreModulesArray)) {
      opacity = '0.2';
    }
    if (!this.modulService.minorModulesInArray(moreModulesArray)) {
      opacity = '0.2';
    }
    if (this.modulService.searchInput && !this.modulService.searchedModuleInArray(moreModulesArray)) {
      opacity = '0.2';
    }
    return opacity;
  }

  dependingModulesOfSelectedModuleInArray(array: Modul[]): boolean {
    return this.sidenavService.getSelectedModules().some(selectedModule => {
      return array.map(m => m.modules![0]).includes(selectedModule);
    });
  }
}
