import {Component, Input, OnInit} from '@angular/core';
import {ParseService} from '../../../../services/parse.service';
import {V2Semester} from "../../../models/V2Semester";

@Component({
  selector: 'app-v2semester-ects-status',
  templateUrl: './v2semester-ects-status.component.html',
  styleUrls: ['./v2semester-ects-status.component.scss']
})
export class V2semesterEctsStatusComponent implements OnInit {

  @Input() semester?: V2Semester;
  @Input() showOnlyCredited = false;

  constructor(
    public parseService: ParseService
  ) {
  }

  ngOnInit(): void {
  }

}
