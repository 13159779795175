import {Component, ElementRef, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {ThemeService} from '../../services/theme.service';
import {ParseService} from '../../services/parse.service';
import {AuthService} from '../../services/auth.service';
import {FilterService} from '../../services/filter.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit, OnChanges {

  @Input() isExpanded = false;
  @ViewChild('systemDefaultThemeSwitch') systemDefaultThemeSwitch!: ElementRef;

  constructor(
    public themeService: ThemeService,
    public authService: AuthService,
    public parseService: ParseService,
    public filterService: FilterService
  ) {
  }

  getTheme = this.themeService.isDark;
  getSystemDefaultTheme = this.themeService.isSystemDefaultThemeChosen;

  ngOnInit(): void {
  }

  ngOnChanges(changes: any): void {
    if (changes.isExpanded) {
      this.isExpanded = changes.isExpanded.currentValue;
    }
  }

  stopEventPropagation(event: any): void {
    if (event instanceof MouseEvent) {
      event.stopPropagation();
    }
  }
}
