import {Studiengang} from '../models/Studiengaenge';


export class BetaTesting {
  static studierende: Array<number> = [];

  static studiengaenge: Array<Studiengang> = [
    Studiengang.I2013,
    Studiengang.I2016,
    Studiengang.I2018,
    Studiengang.IC2016,
    Studiengang.IC2018,
    Studiengang.M18,
    Studiengang.M20,
    Studiengang.STE,
    Studiengang.WING2019,
    Studiengang.O21,
    Studiengang.EUT,
    Studiengang.EIT
  ];
}
