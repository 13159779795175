<div class="systemtechnik-container">
  <div class="header-container-3-8">
    <app-modulteppich-header></app-modulteppich-header>
  </div>
  <ng-container *ngFor="let group of modulTree">
    <div *ngIf="group.bezeichnung === 'Projekte'"
         [style.border]="modulgroupService.defineModulgroupBorder(group, '#00D0CD')"
         class="informatik-modulteppich-projekte-container">
      <app-modulgroup class="modulgroup"
                      [modulGroup]="group"
                      [isProject]="true">
      </app-modulgroup>
    </div>
    <div *ngIf="group.bezeichnung === 'Fachausbildung'"
         [style.border]="modulgroupService.defineModulgroupBorder(group, '#2094EC')"
         class="fachausbildung-container">
      <app-status-bubble [hidden]="!(filterService.getShowFilter('Bestanden') || filterService.getShowFilter('Fehlgeschlagen'))"
                         [modulGroup]="group"
                         [status]="modulgroupService.calcBubbleStatus(group, '#2094EC')">
      </app-status-bubble>
      <app-modulgroup-title [modulGroup]="group"></app-modulgroup-title>
      <app-ects [displayMinECTS]="false"
                [passedGroupECTS]="parseService.calculatePassedGroupECTS(group)"
                [minGroupECTS]="calculateMinGroupECTS(group)"
                [modulGroup]="group">
      </app-ects>
      <div #fachausbildung class="systemtechnik-modulteppich-container">
        <ng-container *ngFor="let subgroup of group.modules">
          <div *ngIf="subgroup.bezeichnung === 'Fachvertiefung'"
               class="systemtechnik-vertiefung-container">
            <div style="display: flex; flex-direction: column; justify-content: space-between;" class="height-full" [style.border]="modulgroupService.defineModulgroupBorder(subgroup, '#2094EC')">
              <div>
                <app-status-bubble [hidden]="!(filterService.getShowFilter('Bestanden') || filterService.getShowFilter('Fehlgeschlagen'))"
                                   [modulGroup]="subgroup"
                                   [status]="modulgroupService.calcBubbleStatus(subgroup, '#2094EC')">
                </app-status-bubble>
                <app-modulgroup-title [modulGroup]="subgroup"></app-modulgroup-title>
                <app-gruppenanrechnung [group]="subgroup"></app-gruppenanrechnung>
                <app-ects [displayMinECTS]="false"
                          [passedGroupECTS]="parseService.calculatePassedGroupECTS(subgroup)"
                          [minGroupECTS]="calculateMinGroupECTS(subgroup)"
                          [modulGroup]="subgroup">
                </app-ects>
              </div>
              <div class="icompetence-vertiefung-container" style="flex-wrap: wrap-reverse; padding: 0 0 10px 0">
                <ng-container *ngFor="let subsubgroup of sortByPosition(subgroup.modules!)">
                  <app-modulgroup [modulGroup]="subsubgroup"
                                  [sortByStatus]="true"
                                  [aligningBottom]="true"
                                  [sortByPosition]="true"
                                  [showTitle]="false"
                                  [showECTS]="false"
                                  [usePadding]="false"
                                  [showStatusBubble]="false"
                                  [showBorder]="false">
                  </app-modulgroup>
                </ng-container>
              </div>
            </div>
          </div>
          <div *ngIf="subgroup.bezeichnung === 'Fachergänzung'"
               class="ergaenzung-container-systemtechnik">
            <div style="height: 100%;" [style.border]="modulgroupService.defineModulgroupBorder(subgroup, '#2094EC')">
              <app-modulgroup class="modulgroup"
                              [modulGroup]="subgroup"
                              [sortByECTS]="true"
                              [numberOfColumns]="4">
              </app-modulgroup>
            </div>
          </div>
          <div *ngIf="subgroup.bezeichnung === 'Fachgrundlagen'"
               class="grundlagen-container-systemtechnik">
            <div *ngFor="let subsubgroup of sortByPosition(subgroup.modules!, {ascending: true})"
                 [style.border]="modulgroupService.defineModulgroupBorder(subsubgroup, '#2094EC')"
                 class="grundlagen-group-container"
                 [style.grid-column]="subsubgroup.bezeichnung!.toLowerCase().includes('mathematik') ? 'span 2' : ''">
              <app-modulgroup *ngIf="subsubgroup.bezeichnung!.toLowerCase().includes('mathematik')" class="modulgroup"
                              [modulGroup]="subsubgroup"
                              [orderColWise]="true"
                              [heightPixel]="384">
              </app-modulgroup>
              <app-modulgroup *ngIf="!subsubgroup.bezeichnung!.toLowerCase().includes('mathematik')" class="modulgroup"
                              [modulGroup]="subsubgroup">
              </app-modulgroup>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="group.bezeichnung === 'Kontext'"
         [style.height]="contHeight + 84 + 'px'"
         class="informatik-modulteppich-kontext-container">
      <ng-container *ngFor="let subgroup of sortByPosition(group.modules!, {ascending: true})">
        <div [style.border]="modulgroupService.defineModulgroupBorder(subgroup, '#8E4FD0')"
             class="kontext-group flex-1-1-auto">
          <app-modulgroup class="modulgroup"
                          [maxElements]="3"
                          [maxHeightPixel]="225"
                          [spaceBetween]="true"
                          [sortByStatus]="true"
                          [modulGroup]="subgroup">
          </app-modulgroup>
        </div>
      </ng-container>
    </div>
    <div class="freie-module-container"
         *ngIf="group.bezeichnung === 'Freie Module' && parseService.calculatePassedGroupECTS(group) > 0">
      <div [style.border]="modulgroupService.defineModulgroupBorder(group, '#8E4FD0')"
           class="kontext-group freie-module">
        <app-modulgroup class="modulgroup"
                        [maxElements]="4"
                        [spaceBetween]="true"
                        [sortByStatus]="true"
                        [showModules]="false"
                        [modulGroup]="group">
        </app-modulgroup>
      </div>
    </div>
  </ng-container>
</div>
