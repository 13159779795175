export const Minor = {
  EUT_EG: 'EG',
  EUT_ES: 'ESYS',
  EUT_NWC: 'NWC',
  MB_TFEC: 'TFEC',
  MB_TFEE: 'TFEE',
  MB_PT: 'PT',
  MB_PD: 'PD',
  MB_PE: 'PE',
  I_DS: 'Data Science',
  I_SM: 'System Management',
  I_SC: 'Spatial Computing',
  I_WE: 'Web Engineering',
  EIT_EAS: 'EA',
  EIT_ESD: 'ES',
};

export const minorModuleMap = new Map<string, Array<string>>([
  [Minor.EUT_EG,
    ['gkl', 'gbp', 'bpad', 'thpr', 'ent', 'slt', 'uwms', 'awr', 'sproc', 'gbs', 'geg', 'thea',
     'leem', 'res', 'niu']],
  [Minor.EUT_ES,
    ['gkl', 'gbp', 'bpad', 'thpr', 'ent', 'slt', 'uwms', 'awr', 'sproc', 'gbs', 'geg', 'thea',
      'leem', 'res', 'niu']],
  [Minor.EUT_NWC,
    ['gkl', 'gbp', 'bpad', 'thpr', 'ent', 'slt', 'uwms', 'awr', 'sproc', 'gbs', 'geg', 'thea',
      'leem', 'res', 'niu']],
  [Minor.MB_TFEC,
    ['wueb', 'aem', 'thd3', 'flm3', 'thd4', 'flm4', 'c1d', 'c1m', 'dpev1', 'kt1', 'kt2', 'dpev2',
      'mant1', 'amat', 'am', 'mea', 'apd', 'mant2']],
  [Minor.MB_TFEE,
    ['wueb', 'aem', 'thd3', 'flm3', 'thd4', 'flm4', 'c1d', 'c1m', 'dpev1', 'kt1', 'kt2', 'dpev2',
      'mant1', 'amat', 'am', 'mea', 'apd', 'mant2']],
  [Minor.MB_PT,
    ['wueb', 'aem', 'thd3', 'flm3', 'thd4', 'flm4', 'c1d', 'c1m', 'dpev1', 'kt1', 'kt2', 'dpev2',
      'mant1', 'amat', 'am', 'mea', 'apd', 'mant2']],
  [Minor.MB_PD,
    ['wueb', 'aem', 'thd3', 'flm3', 'thd4', 'flm4', 'c1d', 'c1m', 'dpev1', 'kt1', 'kt2', 'dpev2',
      'mant1', 'amat', 'am', 'mea', 'apd', 'mant2']],
  [Minor.MB_PE,
    ['wueb', 'aem', 'thd3', 'flm3', 'thd4', 'flm4', 'c1d', 'c1m', 'dpev1', 'kt1', 'kt2', 'dpev2',
      'mant1', 'amat', 'am', 'mea', 'apd', 'mant2']],
  [Minor.I_DS,
    ['wods', 'ml', 'pac', 'bverI', 'efalg', 'nlp', 'stads', 'dawr', 'ivis', 'dsp', 'sna']],
  [Minor.I_SM, ['wosm', 'itfs', 'netsi', 'cpnet', 'cysL', 'pcls', 'devops']],
  [Minor.I_SC, ['wosc', 'simag', 'exr', 'bverI', 'efalg', 'comgr', 'uied', 'sdent', 'pfcs']],
  [Minor.I_WE,
    ['woweb', 'ddm', 'apsi', 'apm', 'eaf', 'webfr', 'webcl', 'fprod', 'webpr', 'stqm', 'webeC',
     'vesys']],
  [Minor.EIT_EAS,
    ['mev', 'spe', 'ele', 'dya', 'rads', 'mst', 'enau', 'aces']],
  [Minor.EIT_ESD,
    ['dsv', 'kom', 'dcom', 'dst', 'mspr', 'ebssd', 'enau', 'aces']],
]);
