import {AfterViewChecked, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ModulteppichDirective} from '../modulteppich.directive';
import {SidenavRightService} from 'src/app/services/sidenav-right.service';
import {ModulService} from '../../../services/modul.service';
import {FilterService} from '../../../services/filter.service';
import {ParseService} from '../../../services/parse.service';
import {ModulgroupService} from '../../../services/modulgroup.service';
import {IModul} from '../../../models/Modul';

@Component({
  selector: 'app-modulteppich-systemtechnik',
  templateUrl: './modulteppich-systemtechnik.component.html',
  styleUrls: ['../modulteppich.component.scss'],
})
export class ModulteppichSystemtechnikComponent extends ModulteppichDirective implements OnInit, AfterViewChecked {

  @ViewChild('fachausbildung') fachausbildung?: ElementRef;
  public contHeight = '';
  public modulTree: Array<IModul> = [];
  sortByPosition = ParseService.sortByPosition;
  calculateMinGroupECTS = ParseService.calculateMinGroupECTS;

  constructor(
    sideNavRightService: SidenavRightService,
    parseService: ParseService,
    modulService: ModulService,
    filterService: FilterService,
    public modulgroupService: ModulgroupService
  ) {
    super(sideNavRightService, parseService, modulService, filterService);
  }

  ngOnInit(): void {
    this.modulTree = this.parseService.getModuleTree();
  }

  ngAfterViewChecked(): void {
    this.defineHeight();
  }

  private defineHeight(): void {
    this.contHeight = this.fachausbildung?.nativeElement.offsetHeight;
  }
}
