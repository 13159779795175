<app-loader *ngIf="parseService.getLoading()"></app-loader>
<div class="student-container" *ngIf="!parseService.getLoading()">
  <app-modulteppich [hidden]="parseService.currentView.getValue() !== View.CARPET"></app-modulteppich>
  <app-timeline [hidden]="parseService.currentView.getValue() !== View.TIMELINE"></app-timeline>
<!--
  <app-v2timeline [student]="student" [hidden]="parseService.currentView.getValue() !== View.TIMELINE"></app-v2timeline>
-->
    <app-modul-tree [hidden]="parseService.currentView.getValue() !== View.TREE"></app-modul-tree>
  <ng-container *ngIf="parseService.canViewModulteppich()">
    <app-sidenav [fixed]="true"></app-sidenav>
    <app-sidenav-right class="sideNav"
    ></app-sidenav-right>
  </ng-container>
</div>
