import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {V2Attempt} from "../../models/V2Attempt";
import {V2Module} from "../../models/V2Module";
import {calculateWeightedGrade, roundGrade, roundGradeToTenth} from "../../v2utils";
import {formatNumber} from "@angular/common";

@Component({
  selector: 'app-v2notenschnitt',
  templateUrl: './v2notenschnitt.component.html',
  styleUrls: ['./v2notenschnitt.component.scss']
})

export class v2notenschnittComponent implements OnChanges {
  @Input() passedAttempts: V2Attempt[] = [];
  @Input() modules: V2Module[] = [];
  accurateAvg = '-.-';
  torAvg = '-.-';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['passedAttempts']) {
      this.calculateAverages();
    }
  }

  private calculateAverages(): void {
    this.accurateAvg = calculateWeightedGrade(this.passedAttempts, this.modules, roundGrade);
    this.torAvg = calculateWeightedGrade(this.passedAttempts, this.modules, roundGradeToTenth);
  }
}
