<app-snow></app-snow>
<div (click)="closeNavigations($event)" class="student-hub">
  <ng-container *ngIf="!parseService.getLoading()">
    <div class="header-container">
      <div *ngIf="env.isTest" class="test-version">Testversion</div>
      <app-navigation></app-navigation>
      <div title="Bug Report" class="warning-button" (click)="hideInfoModal = false">
        <img class="sort-icon" *ngIf="themeService.isDark()"
             alt="bug-icon"
             src="assets/icons/bug-dark.svg">
        <img class="sort-icon" *ngIf="!themeService.isDark()"
             alt="bug-icon"
             src="assets/icons/bug-light.svg">
      </div>
    </div>
    <app-filter></app-filter>
  </ng-container>
  <app-snackbar *ngIf="snackbarService.getShowSnackbar()"></app-snackbar>
  <router-outlet></router-outlet>
</div>
<app-modal (hiddenChange)="hideInfoModal = $event"
           [hidden]="hideInfoModal"></app-modal>
