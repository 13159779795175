import {AfterViewChecked, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ModulteppichDirective} from '../modulteppich.directive';
import {SidenavRightService} from 'src/app/services/sidenav-right.service';
import {ModulService} from '../../../services/modul.service';
import {FilterService} from '../../../services/filter.service';
import {ParseService} from '../../../services/parse.service';
import {IModul, Modul} from '../../../models/Modul';
import {ModulgroupService} from '../../../services/modulgroup.service';

@Component({
  selector: 'app-modulteppich-icompetence-2016',
  templateUrl: './modulteppich-icompetence-2016.component.html',
  styleUrls: ['../modulteppich.component.scss'],
})
export class ModulteppichIcompetence2016Component extends ModulteppichDirective implements OnInit, AfterViewChecked {

  @ViewChild('fachausbildung') fachausbildung?: ElementRef;
  public contHeight = '';
  public modulTree: Array<IModul> = [];
  sortByPosition = ParseService.sortByPosition;
  calculateMinGroupECTS = ParseService.calculateMinGroupECTS;

  constructor(
    sideNavRightService: SidenavRightService,
    parseService: ParseService,
    modulService: ModulService,
    filterService: FilterService,
    public modulgroupService: ModulgroupService
  ) {
    super(sideNavRightService, parseService, modulService, filterService);
  }

  ngOnInit(): void {
    this.modulTree = this.parseService.getModuleTree();
  }

  ngAfterViewChecked(): void {
    this.defineHeight();
  }

  private defineHeight(): void {
    this.contHeight = this.fachausbildung?.nativeElement.offsetHeight;
  }

  columnSpan(module: Modul): string {
    const abb = module.modules![0]?.abbreviation!;
    let span = '';
    if (abb !== '' && abb) {
      if (abb.includes('dtpc')) {
        span = '1 / span 2';
      } else if (abb.includes('uidc')) {
        span = '1 / span 2';
      } else if (abb.includes('meco')) {
        span = '1 / span 2';
      } else if (abb.includes('adxd')) {
        span = '1 / span 2';
      } else if (abb.includes('ivis')) {
        span = '1 / span 2';
      } else if (abb.includes('pmc')) {
        span = '3 / span 2';
      } else if (abb.includes('bwlc')) {
        span = '3 / span 2';
      } else if (abb.includes('cmm')) {
        span = '3 / span 2';
      } else if (abb.includes('mpm')) {
        span = '3 / span 2';
      } else if (abb.includes('pefu')) {
        span = '3 / span 2';
      } else {
        span = '';
      }
    }
    return span;
  }

  rowSpan(module: Modul): string {
    const abb = module.modules![0]?.abbreviation!;
    let span = '';
    if (abb !== '') {
      if (abb.includes('dtpc')) {
        span = '1 / 2';
      } else if (abb.includes('uidc')) {
        span = '2 / 3';
      } else if (abb.includes('meco')) {
        span = '3 / 4';
      } else if (abb.includes('adxd')) {
        span = '4 / 5';
      } else if (abb.includes('ivis')) {
        span = '5 / 6';
      } else if (abb.includes('pmc')) {
        span = '1 / 2';
      } else if (abb.includes('bwlc')) {
        span = '2 / 3';
      } else if (abb.includes('cmm')) {
        span = '3 / 4';
      } else if (abb.includes('mpm')) {
        span = '4 / 5';
      } else if (abb.includes('pefu')) {
        span = '5 / 6';
      } else {
        span = '';
      }
    }
    return span;
  }
}
