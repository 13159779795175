<div class="view-toggle-container">
  <div class="view-switch">
    <input name="displayMode"
           id="modulteppich"
           type="radio"
           (click)="setView(View.CARPET)"
           [checked]="parseService.currentView.getValue() === View.CARPET">
    <label class="toggle-view-icon-container" for="modulteppich" title="Modulteppich">
      <img class="toggle-view-icon" *ngIf="themeService.isDark()"
           src="assets/icons/carpet-view-dark.svg" alt="modulteppich">
      <img class="toggle-view-icon" *ngIf="!themeService.isDark()"
           src="assets/icons/carpet-view.svg" alt="modulteppich">
    </label>
    <input name="displayMode"
           class="view-switch-input-timeline"
           id="timeline"
           type="radio"
           (click)="setView(View.TIMELINE)"
           [checked]="parseService.currentView.getValue() === View.TIMELINE">
    <label class="toggle-view-icon-container" for="timeline" title="Timeline">
      <img *ngIf="themeService.isDark()" class="toggle-view-icon"
           src="assets/icons/timeline-view-dark.svg"
           alt="timeline">
      <img *ngIf="!themeService.isDark()" class="toggle-view-icon"
           src="assets/icons/timeline-view.svg" alt="timeline">
    </label>

    <input name="displayMode"
           class="view-switch-input-modul-tree"
           id="modul-tree"
           type="radio"
           (click)="setView(View.TREE)"
           [checked]="parseService.currentView.getValue() === View.TREE">
    <label class="toggle-view-icon-container" for="modul-tree" title="Modulbaum">
      <img *ngIf="themeService.isDark()" class="toggle-view-icon" style="width: 20px;"
           src="assets/icons/module-tree-view-dark.svg"
           alt="modul-tree">
      <img *ngIf="!themeService.isDark()" class="toggle-view-icon" style="width: 20px;"
           src="assets/icons/module-tree-view.svg" alt="modul-tree">
    </label>
  </div>
  <div class="current-view-label">
    <div [ngClass]="getClassForViewLabel('modulteppich')">Modulteppich</div>
    <div [ngClass]="getClassForViewLabel('timeline')">Timeline</div>
    <div [ngClass]="getClassForViewLabel('modul-tree')">Modulbaum</div>
  </div>
</div>
