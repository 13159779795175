export enum LevelDescription {
  none = 'None',
  basic = 'Basic',
  intermediate = 'Intermediate',
  advanced = 'Advanced'}

export enum LevelWeight {
  zero = 0,
  light = 1,
  medium = 2,
  heavy = 3,
  heaviest = 4
}

export class Level {
  public levelDescription: LevelDescription;
  public weight: LevelWeight;

  constructor(levelDescription: LevelDescription, weight: LevelWeight) {
    this.levelDescription = levelDescription;
    this.weight = weight;
  }
}
