import {Component, Input} from '@angular/core';
import {AbstractControl, AbstractControlDirective} from '@angular/forms';

@Component({
  selector: 'app-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent {

  @Input() controlName!: AbstractControl | AbstractControlDirective;
  public errorMsgList: any = [];
  public errorMessages: { [key: string]: any } = {
    required: (params: any) => `This field is required`,
    maxlength: (params: any) => `Maximum ${params.requiredLength} characters are allowed`,
    minlength: (params: any) => `Minimum ${params.requiredLength} characters are required`
  };

  public listErrors(): Array<any> {
    if (!this.controlName) { return []; }
    if (this.controlName.errors) {
      this.errorMsgList = [];
      Object.keys(this.controlName.errors).map(error => {
        if (this.controlName.touched || this.controlName.dirty) {
          this.errorMsgList.push(this.errorMessages[error](this.controlName.errors![error]));
        }
      });
      return this.errorMsgList;
    } else {
      return [];
    }
  }
}
