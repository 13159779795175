<ng-container *ngIf="parseService.anrechnungsECTS !== 0">
  <div class="section-space">
    <div class="semester">Angerechnet</div>
    <app-semester-ects-status [showOnlyCredited]="true"></app-semester-ects-status>
  </div>
  <div class="angerechnet-bar" [style.grid-template-columns]="amountSemesterECTS">
    <ng-container>
      <ng-container *ngFor="let modul of parseService.getAngerechneteModule()">
        <app-modul
          [style.grid-column-end]="'span ' + modul.modul!.ects!"
          [module]="modul"
          [timeline]="true">
        </app-modul>
      </ng-container>
      <ng-container *ngFor="let gran of parseService.gruppenAnrechnung">
        <app-modul
          [style.grid-column-end]="'span ' + gran.modul!.ects!"
          [module]="gran"
          [title]="gran.gruppe ? gran.modul!.ects + ' ECTS angerechnet in ' + gran.gruppe : gran.modul!.ects + ' ECTS aus Gruppenanrechnungen'"
          [timeline]="true"></app-modul>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
