import {Component, Input, OnInit} from '@angular/core';
import {ParseService} from '../../../../services/parse.service';
import {FilterService} from '../../../../services/filter.service';
import {V2Semester} from "../../../models/V2Semester";
import {V2Module} from "../../../models/V2Module";
import {V2Attempt} from "../../../models/V2Attempt";
import {v2ModulService} from "../../../services/v2modul.service";
import {V2Student} from "../../../models/V2Student";
import {calculateCurrentModuleStatus, calculateWeightedGrade, roundGrade} from "../../../v2utils";
import {V2ModuleStatus} from "../../../models/V2ModuleStatus";

@Component({
  selector: 'app-v2moduluebersicht',
  templateUrl: './v2moduluebersicht.component.html'
})
export class V2moduluebersichtComponent implements OnInit {
  calculateCurrentModuleStatus = calculateCurrentModuleStatus;
  calculateWeightedGrade = calculateWeightedGrade;
  roundGrade = roundGrade;
  calcModuleWidth = v2ModulService.calcModuleWidth;

  constructor(
    public modulService: v2ModulService,
    public parseService: ParseService,
    public filterService: FilterService
  ) {
  }

  @Input() gridSize!: number;
  @Input() semesters: V2Semester[];
  @Input() student: V2Student;

  semesterModuleData: Array<{ semester: V2Semester, semesterModuleMap: Array<{ module: V2Module, attempt: V2Attempt }> }> = [];

  ngOnInit(): void {
    this.semesters = this.sortSemestersAndAttempts(this.semesters);
    this.semesterModuleData = this.semesters.map(semester => {
      const semesterModuleMap = semester.attempts.map(attempt => {
        const module = semester.modules.find(m => m.abbreviation === attempt.moduleAbbreviation);
        return {module, attempt};
      });
      return {semester, semesterModuleMap};
    });

  }

  public sortSemestersAndAttempts(semesters: V2Semester[]): V2Semester[] {
    const sortedSemesters =  semesters.sort((a, b) => this.sortSemesters(a.abbreviation, b.abbreviation));

    sortedSemesters.forEach(semester => {
      semester.attempts = this.sortAttempts(semester.attempts, semester.abbreviation);
    });

    return sortedSemesters;
  }

  sortAttempts(attempts: V2Attempt[], semesterAbbr: string): V2Attempt[] {
    const order = [V2ModuleStatus.PASSED, V2ModuleStatus.CREDITED, V2ModuleStatus.FAILED, V2ModuleStatus.LOCKED, V2ModuleStatus.RUNNING, V2ModuleStatus.UNKNOWN];
    return attempts.sort((a, b) => order.indexOf(calculateCurrentModuleStatus(a.moduleAbbreviation, semesterAbbr, this.student.attempts)) - order.indexOf(calculateCurrentModuleStatus(b.moduleAbbreviation, semesterAbbr, this.student.attempts)));
  }

  sortSemesters(semesterA: string, semesterB: string): number {
    if (!semesterA && !semesterB) {
      return 0;
    }
    if (!semesterA) {
      return -1;
    }
    if (!semesterB) {
      return 1;
    }

    const [aN, aS] = semesterA.split(/(H|F)/);
    const [bN, bS] = semesterB.split(/(H|F)/);

    if (aN === bN) {
      return bS === 'F' ? -1 : 1;
    } // F stands for Frühlingssemester which should come first

    return bN > aN ? 1 : -1;
  }
}
