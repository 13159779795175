import {Component, Input, OnInit} from '@angular/core';
import {ThemeService} from '../services/theme.service';

@Component({
  selector: 'app-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss']
})
export class OnboardingComponent {

  @Input() slides: any[] = [];
  selectedSlideIndex = 0;

  constructor(
    public themeService: ThemeService
  ) {
  }

  nextSlide(): void {
    if (this.selectedSlideIndex !== this.slides.length - 1) {
      this.selectedSlideIndex++;
    } else {
      this.selectedSlideIndex = 0;
    }
  }

  prevSlide(): void {
    this.selectedSlideIndex--;
  }
}
