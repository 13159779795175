<div class="admin-layout">
  <app-sidenav [fixed]="false"
               [isInAdmin]="true"
               [currentStudentCount] = "originalStudierendeWithAnmeldungen.length"
               (filterChanged)="applyFilters($event)"
               (formSubmitted) = "detectFormSubmitted($event)"
               (filtersDisabledOutput) = "detectFiltersDisabled($event)"
  ></app-sidenav>
  <div class="students-overview">
    <app-student-list [errorMessage]="errorMessage"
                      [hasError]="hasError"
                      [loading]="loading"
                      [studentsWithAnmeldungen]="studierendeWithAnmeldungen"
                      [filterChanged] = "filterChanged"
                      [filtersDisabled] = "filtersDisabled">
    </app-student-list>
  </div>
</div>
