import {V2Attempt} from "./V2Attempt";
import {V2PauschalAnrechnung} from "./V2PauschalAnrechnung"
import {V2Module} from "./V2Module";

export class V2Semester {
    abbreviation: string;
    passedECTS: number;
    failedOnceECTS: number;
    failedMultipleTimesECTS: number;
    runningECTS: number;
    attempts: Array<V2Attempt>;
    modules: Array<V2Module>;
    pauschalAnrehcnungen: Array<V2PauschalAnrechnung>;

    constructor(abbreviation: string, passedECTS: number, failedOnceECTS: number, failedMultipleTimesECTS: number, runningECTS: number, attempts: Array<V2Attempt>, modules: Array<V2Module>, pauschalAnrehcnungen: Array<V2PauschalAnrechnung>) {
        this.abbreviation = abbreviation;
        this.passedECTS = passedECTS;
        this.failedOnceECTS = failedOnceECTS;
        this.failedMultipleTimesECTS = failedMultipleTimesECTS;
        this.runningECTS = runningECTS;
        this.attempts = attempts;
        this.modules = modules;
        this.pauschalAnrehcnungen = pauschalAnrehcnungen;
    }
}
