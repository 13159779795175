import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {ModulService} from '../../../services/modul.service';
import {ParseService} from '../../../services/parse.service';
import {Semester} from '../../../models/Semester';

@Component({
  selector: 'app-angerechnet-timeline',
  templateUrl: './angerechnet-timeline.component.html',
  styleUrls: ['./angerechnet-timeline.component.scss']
})
export class AngerechnetTimelineComponent implements OnInit, OnChanges {

  angerechnetSemester: Semester = {} as Semester;

  constructor(
    public parseService: ParseService,
    public modulService: ModulService
  ) {
  }

  @Input() maxECTSInSemester?: number;
  public amountSemesterECTS = '';

  ngOnInit(): void {
    this.angerechnetSemester = new Semester(
      '',
      this.parseService.anrechnungsECTS,
      0,
      0,
      0,
      this.parseService.getAngerechneteModule().concat(this.parseService.gruppenAnrechnung)
    );
  }

  ngOnChanges(): void {
    this.setAmountSemesterECTS(this.maxECTSInSemester!);
  }

  public setAmountSemesterECTS(amountSemesterECTS: number): void {
    this.amountSemesterECTS = 'repeat(' + amountSemesterECTS.toString() + ', 33.5px)';
  }
}
