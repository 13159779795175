import {Component, OnInit} from '@angular/core';
import {ParseService} from '../../services/parse.service';
import {ModulteppichDirective} from './modulteppich.directive';
import {ModulService} from '../../services/modul.service';
import {FilterService} from '../../services/filter.service';
import {SidenavRightService} from '../../services/sidenav-right.service';

@Component({
  selector: 'app-modulteppich',
  templateUrl: './modulteppich.component.html',
  styleUrls: ['./modulteppich.component.scss']
})
export class ModulteppichComponent extends ModulteppichDirective implements OnInit {

  constructor(
    sideNavRightService: SidenavRightService,
    parseService: ParseService,
    modulService: ModulService,
    filterService: FilterService
  ) {
    super(sideNavRightService, parseService, modulService, filterService);
  }

  ngOnInit(): void {
  }
}
