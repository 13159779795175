<div class="sticky"
    *ngIf="parseService.canViewModulteppich() && !navigationService.getCurrentNavigation().includes('/admin')">
    <div class="navigation-container">
      <app-light-chain *ngIf="themeService.isCurrentDateDuringAdventTime()"></app-light-chain>
      <div class="navigation-filter">
        <div class="filter">
          <div title="Filter öffnen" (click)="toggleFilterSettingDropdown($event)">
            <img alt="filter" src="assets/icons/filter.svg"/>
            <span id="filter">Details</span>
          </div>
          <div class="resetAllFilters" title="Filter zurücksetzen" *ngIf="filterService.getEnabledFilters().length> 0"
               (click)="filterService.resetAllFilters()">
            <img alt="reset-filters" src="assets/icons/layers-clear-dark.svg"/>
            <span>Filter zurücksetzen</span>
          </div>
        </div>
        <div [ngClass]="[navigationService.scrollbarThemeClass(), parseService.getStudent() && authService.getUser().admin ? 'horScroll-long-admin' : 'horScroll-long']">
          <div class="filter-breadcrumb-list">
            <ng-container *ngFor="let filter of filterService.getEnabledFilters()">
              <div class="filter-breadcrumb">
                <span>{{ filter[0] }}</span>
                <span (click)="filterService.resetFilter(filter[0])" id="x">x</span>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="student-name" *ngIf="parseService.getStudent() && authService.getUser().admin"
             (click)="toggleStudiengangDropdown($event)"
             [ngClass]="parseService.getStudentStudienJahrgaenge() && parseService.getStudentStudienJahrgaenge().length > 1 ? 'pointer' : ''">
          <span>Ansicht von {{parseService.getStudent().vorname + ' ' + parseService.getStudent().nachname}}</span>
          <img
            *ngIf="parseService.getStudentStudienJahrgaenge() && parseService.getStudentStudienJahrgaenge().length > 1"
            alt="dropdown-closed"
            src="assets/icons/dropdown/closed.svg"/>
          <app-dropdown-studiengaenge [isExpanded]="isStudiengaengeExpanded"></app-dropdown-studiengaenge>
        </div>

        <div [style.margin-left]="parseService.getStudent() && authService.getUser().admin ? '15px' : 'auto'" *ngIf="parseService.canViewModulteppich() && parseService.getStudent()">
          <app-view-toggle></app-view-toggle>
        </div>
      </div>
    </div>
<div [ngClass]="isFilterDropdownExpanded ? 'expand-preferences' : 'collapse-preferences'"
       class="preferences-menu"
       #filterMenu>
    <div class="triangle-left">
      <div class="inner-triangle"></div>
    </div>
    <div (click)="stopEventPropagation($event)" class="filter-menu">
      <ng-container *ngFor="let category of filterCategories">
        <div *ngIf="isInCurrentStudiengang(category)" class="general-preferences">
          <div class="filter-group">{{category.category}}</div>
          <ng-container *ngFor="let filter of this.filterService.config.filters | keyvalue:asIsOrder ">
            <app-filter-attribute *ngIf="filter.value.category === category.category"
                                  [filterName]="filter.key"
                                  [legend]="filter.value.legend"
                                  [showCirclePassed]="filter.value.showCirclePassed"
                                  [showCircleFailed]="filter.value.showCircleFailed"
                                  [showCircleRunning]="filter.value.showCircleRunning">
            </app-filter-attribute>
          </ng-container>
        </div>
      </ng-container>
    </div>
</div>
<div>
