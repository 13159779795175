<div *ngIf="filterService.getShowFilter('MSP') && displayModuleMitMSP(modul)"
     class="msp-assessment-module"
     [style.top]="isTimeline ? '1px' : '3px'"
     [ngClass]="this.attributeColorModuleRunning(this.modul!, this.attempt)">
  {{displayModuleMitMSP(modul)}}
</div>
<div *ngIf="filterService.getShowFilter('Assessment') && displayAssessmentModule(modul)"
     class="msp-assessment-module"
     [style.top]="isTimeline ? '1px' : '3px'"
     [ngClass]="this.attributeColorModuleRunning(this.modul!, this.attempt)">
  {{displayAssessmentModule(modul)}}
</div>
<div *ngIf="!!modulService.displayDataScienceTypes(modul)"
     class="module-type-icon"
     [ngClass]="this.attributeColorModuleRunning(this.modul!, this.attempt)">
  {{modulService.displayDataScienceTypes(modul)}}
</div>
<div *ngIf="filterService.getShowFilter('Noten')"
     class="grade-module"
     [style.bottom]="isTimeline ? '1px' : '3px'">
  {{showGrades(modul, attempt)}}
</div>
<div *ngIf="filterService.getShowFilter('Angerechnet')"
     class="grade-module"
     [style.bottom]="isTimeline ? '1px' : '3px'">
  {{displayModuleCredited(modul)}}
</div>
<div *ngIf="filterService.getShowFilter('ECTS')"
     class="modul-ECTS"
     [style.bottom]="isTimeline ? '1px' : '3px'"
     [ngClass]="this.attributeColorModuleRunning(this.modul!, this.attempt)">
  {{showECTS(modul)}}
</div>
