import {Injectable} from '@angular/core';
import {IStudierendeWithAnmeldungen, StudierendeWithAnmeldungen} from "../models/StudierendeWithAnmeldungen";
import {IStudienjahrgang} from "../models/Studienjahrgang";
import {ParseService} from "./parse.service";
import {StudienjahrgangAnmeldung} from "../models/StudienjahrgangAnmeldung";
import {
  ignorableStatuses as ignorableSJAStatuses,
  ignorableStatuses as ignorableSJSAtatuses
} from "../models/StudienjahrgangAnmeldungStatuses";
import {Modulteppich} from "../models/Modulteppich";
import {Studiengaenge} from "../models/Studiengaenge";
import {IModul} from "../models/Modul";
import {failedStatuses} from "../models/AnmeldungStatuses";

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  public static readonly AVAILABLE_STUDIENGAENGE: Array<Modulteppich> = Studiengaenge.availableModulteppiche;


  constructor() {}


  public matchStudentsWithStudienjahrgangInfo(students: Array<StudierendeWithAnmeldungen>, studienjahrgangInfos: Array<IStudienjahrgang>): StudierendeWithAnmeldungen[] {
    students.forEach(student => {
      const actives = this.removeStudienjahrgangAnmeldungenWithIgnorableStatus(student.student.studienjahrgangAnmeldungen || []);
      const inActives = this.removeStudienjahrgangAnmeldungenWithNonIgnorableStatus(student.student.studienjahrgangAnmeldungen || []);

      const studienjahrgangAnmeldungen = actives.concat(inActives);

      const matched = studienjahrgangAnmeldungen.map(sja => {
        const info = studienjahrgangInfos.find(info => info.studienjahrgangId === sja.studienjahrgangId);
        return { anmeldung: sja, info: ParseService.parseStudyMode(info) };
      }).filter(m => m.info && !m.info.nummer.substring(0, 5).toLowerCase().includes('m'));

      const matchedStudienjahrgang = matched[0] || { anmeldung: null, info: null };

      student.studienjahrgangInfo = matchedStudienjahrgang.info;
      student.modulteppichIsPublic = this.checkIfModulteppichIsPublic(matchedStudienjahrgang.info);
      student.modulteppichIsComplete = this.checkIfModulteppichIsAvailable(matchedStudienjahrgang.info);
    });

    return students.filter(s => s.studienjahrgangInfo);
  }

  removeStudienjahrgangAnmeldungenWithIgnorableStatus(studienjahrgangAnmeldungen: Array<StudienjahrgangAnmeldung>): Array<StudienjahrgangAnmeldung> {
    return studienjahrgangAnmeldungen.filter(sja => !ignorableSJSAtatuses.includes(sja.statusName!)).sort((a, b) => a.anmeldungsDatum! > b.anmeldungsDatum! ? -1 : 1);
  }

  removeStudienjahrgangAnmeldungenWithNonIgnorableStatus(studienjahrgangAnmeldungen: Array<StudienjahrgangAnmeldung>): Array<StudienjahrgangAnmeldung> {
    return studienjahrgangAnmeldungen.filter(sja => ignorableSJSAtatuses.includes(sja.statusName!)).sort((a, b) => a.anmeldungsDatum! < b.anmeldungsDatum! ? 1 : -1);
  }

  checkIfModulteppichIsPublic(studienjahrgangInfo: IStudienjahrgang): boolean {
    if (!studienjahrgangInfo) {
      return false;
    }
    const matchedModulteppich = AdminService.AVAILABLE_STUDIENGAENGE
      .find(m => m.getStudiengangIds().includes(studienjahrgangInfo.studiengangId!));
    return matchedModulteppich ? matchedModulteppich.getIsPublic() : false;
  }

  checkIfModulteppichIsAvailable(studienjahrgangInfo: IStudienjahrgang): boolean {
    if (!studienjahrgangInfo) {
      return false;
    }
    const matchedModulteppich = AdminService.AVAILABLE_STUDIENGAENGE
      .find(m => m.getStudiengangIds().includes(studienjahrgangInfo.studiengangId!));
    return matchedModulteppich ? matchedModulteppich.getIsComplete() : false;
  }


  public calculateTotalFailedEctsStudents(studierendeAnmeldungen: StudierendeWithAnmeldungen[], modulgruppen: IModul[]
  ): StudierendeWithAnmeldungen[] {
    const students: StudierendeWithAnmeldungen[] = [];
    studierendeAnmeldungen.forEach(student => {
      const criticalAnmeldungen = student.anmeldungen.filter(a =>
        failedStatuses.includes(a.statusName) &&
        a.modulanlass.nummer?.split('.').pop()?.includes('HN')
      );

      let totalEcts = criticalAnmeldungen.reduce((total, anmeldung) => {
        const modul = modulgruppen.find(m => m.modulId === anmeldung.modulanlass.modulId);
        return modul ? total + modul.modul.ects : total;
      }, 0);
        const clonedStudent = JSON.parse(JSON.stringify(student));
        clonedStudent.totalFailedEcts = totalEcts;
        students.push(clonedStudent);
    });
    return students;
  }
  public filterCriticalStudents(studierendeAnmeldungen: StudierendeWithAnmeldungen[], maxFailedECTS: number
  ): StudierendeWithAnmeldungen[] {
    const criticalStudents = [];
    studierendeAnmeldungen.forEach(student => {
      if (student.totalFailedEcts >= maxFailedECTS){
        const clonedStudent = JSON.parse(JSON.stringify(student));
        criticalStudents.push(clonedStudent);
      }
    })
    return criticalStudents;
  }

  public calculateStudentsWithRunningAssessment(students: StudierendeWithAnmeldungen[],sgId: number, modulgruppen: Array<IModul>): StudierendeWithAnmeldungen[] {
    students = this.calculateStudentsAssessment(students,sgId,modulgruppen);
    return students.filter(s => s.assessmentECTS < 27)
  }

  public calculateStudentsWithPassedAssessment(students: StudierendeWithAnmeldungen[],sgId: number, modulgruppen: Array<IModul>): StudierendeWithAnmeldungen[] {
    students = this.calculateStudentsAssessment(students,sgId,modulgruppen);
    return students.filter(s => s.assessmentECTS >= 27)
  }
  public calculateStudentsAssessment(students: StudierendeWithAnmeldungen[], sgId: number, modulgruppen: Array<IModul>): StudierendeWithAnmeldungen[] {
    const studentsWithCalculatedAssessment: StudierendeWithAnmeldungen[] = [];

    const studiengang = ParseService.AVAILABLE_STUDIENGAENGE.find(modulteppich => modulteppich.getStudiengangIds().includes(sgId));
    const assessments = studiengang?.getAssessment() as Array<string> || [];

    students.filter(student => {
      const creditedModulIds = student.student.modulAnrechnungen.map(a => a.modulId);
      const creditedModules = modulgruppen.filter(m => creditedModulIds.includes(m.modulId) && assessments.some(a => m.modul?.nummer.split('.')[0].includes(a)));

      const passedAssesmentsIds = student.anmeldungen
        .filter(a => a.bestanden && a.modulanlass.nummer?.split('.').pop()?.includes('HN') && assessments.some(assessment => a.modulanlass.nummer?.split('.')[1].includes(assessment)))
        .map(a => a.modulanlass.modulId);

      const passedAssesmentsModules = modulgruppen.filter(m => passedAssesmentsIds.includes(m.modulId));
      const assessmentEcts = [...creditedModules, ...passedAssesmentsModules].reduce((totalEcts, modul) => totalEcts + (modul.modul?.ects ?? 0), 0);
      const clonedStudent = JSON.parse(JSON.stringify(student));
      clonedStudent.assessmentECTS = assessmentEcts;
      studentsWithCalculatedAssessment.push(clonedStudent);
    });
    return studentsWithCalculatedAssessment;

  }

  public calculateActiveStudents(students: StudierendeWithAnmeldungen[]): StudierendeWithAnmeldungen[] {
    return students.filter(s =>
      s.student.studienjahrgangAnmeldungen.some(a =>
        !ignorableSJAStatuses.includes(a.statusName)
      )
    );
  }
}
