import {Component, Input, OnInit} from '@angular/core';
import {IModul} from '../../../models/Modul';
import {ParseService} from '../../../services/parse.service';

@Component({
  selector: 'app-notenschnitt',
  templateUrl: './notenschnitt.component.html',
  styleUrls: ['./notenschnitt.component.scss']
})
export class NotenschnittComponent implements OnInit {

  @Input() modules: Array<IModul> = [];

  accurateAvg = '-.-';
  torAvg = '-.-';

  ngOnInit(): void {
    this.accurateAvg = ParseService.calcAccurateAverage(this.modules);
    this.torAvg = ParseService.calcTorAverage(this.modules);
  }
}
