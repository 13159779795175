<div class="optometrie-22-container">
  <div style="grid-column: span 4; grid-row: 1/2;">
    <app-modulteppich-header></app-modulteppich-header>
  </div>
  <ng-container *ngFor="let group of modulTree">
    <div *ngIf="group.bezeichnung === 'Klinik'"
         [style.border]="modulgroupService.defineModulgroupBorder(group, '#2094EC')"
         class="optometrie-22-klinik-container">
      <app-status-bubble
        [hidden]="!(filterService.getShowFilter('Bestanden') || filterService.getShowFilter('Fehlgeschlagen'))"
        [modulGroup]="group"
        [status]="modulgroupService.calcBubbleStatus(group, '#2094EC')">
      </app-status-bubble>
      <app-modulgroup-title [modulGroup]="group"></app-modulgroup-title>
      <app-ects [displayMinECTS]="false"
                [passedGroupECTS]="parseService.calculatePassedGroupECTS(group)"
                [minGroupECTS]="calculateMinGroupECTS(group)"
                [modulGroup]="group">
      </app-ects>
      <div class="optometrie-modulteppich-container">
        <ng-container *ngFor="let subgroup of group.modules">
          <app-modulgroup [modulGroup]="subgroup"
                          [sortAlphabetically]="true"
                          [showTitle]="false"
                          [showECTS]="false"
                          [usePadding]="false"
                          [showStatusBubble]="false">
          </app-modulgroup>
        </ng-container>
      </div>
    </div>
    <div *ngIf="group.bezeichnung === 'Wissenschaftliche Ausbildung'"
         [style.border]="modulgroupService.defineModulgroupBorder(group, '#2094EC')"
         class="optometrie-22-wissenschaftliche-ausbildung-container">
      <app-status-bubble
        [hidden]="!(filterService.getShowFilter('Bestanden') || filterService.getShowFilter('Fehlgeschlagen'))"
        [modulGroup]="group"
        [status]="modulgroupService.calcBubbleStatus(group, '#2094EC')">
      </app-status-bubble>
      <app-modulgroup-title [modulGroup]="group"></app-modulgroup-title>
      <app-ects [displayMinECTS]="false"
                [passedGroupECTS]="parseService.calculatePassedGroupECTS(group)"
                [minGroupECTS]="calculateMinGroupECTS(group)"
                [modulGroup]="group">
      </app-ects>
      <div #fachausbildung class="optometrie-modulteppich-container">
        <ng-container *ngFor="let subgroup of group.modules">
          <app-modulgroup [modulGroup]="subgroup"
                          [sortAlphabetically]="true"
                          [showTitle]="false"
                          [showECTS]="false"
                          [usePadding]="false"
                          [showStatusBubble]="false">
          </app-modulgroup>
        </ng-container>
      </div>
    </div>
    <div *ngIf="group.bezeichnung === 'Fachausbildung'"
         [style.border]="modulgroupService.defineModulgroupBorder(group, '#2094EC')"
         class="optometrie-22-fachausbildung-container">
      <app-status-bubble
        [hidden]="!(filterService.getShowFilter('Bestanden') || filterService.getShowFilter('Fehlgeschlagen'))"
        [modulGroup]="group"
        [status]="modulgroupService.calcBubbleStatus(group, '#2094EC')">
      </app-status-bubble>
      <app-modulgroup-title [modulGroup]="group"></app-modulgroup-title>
      <app-ects [displayMinECTS]="false"
                [passedGroupECTS]="parseService.calculatePassedGroupECTS(group)"
                [minGroupECTS]="calculateMinGroupECTS(group)"
                [modulGroup]="group">
      </app-ects>
      <div class="optometrie-22-modulteppich-container" style="height: 100%;">
        <ng-container *ngFor="let subgroup of group.modules">
          <div [style.border]="modulgroupService.defineModulgroupBorder(subgroup, '#2094EC')"
               style="display: flex; flex-direction: column; position: relative;">
            <app-status-bubble
              [hidden]="!(filterService.getShowFilter('Bestanden') || filterService.getShowFilter('Fehlgeschlagen'))"
              [modulGroup]="subgroup"
              [status]="modulgroupService.calcBubbleStatus(subgroup, '#2094EC')">
            </app-status-bubble>
            <app-modulgroup-title [modulGroup]="subgroup"></app-modulgroup-title>
            <app-ects [displayMinECTS]="false"
                      [passedGroupECTS]="parseService.calculatePassedGroupECTS(subgroup)"
                      [minGroupECTS]="calculateMinGroupECTS(subgroup)"
                      [modulGroup]="subgroup">
            </app-ects>
            <div style="padding: 0 10px 10px 10px; height: 100%;">
              <app-modulgroup [modulGroup]="subgroup"
                              [showTitle]="false"
                              [showECTS]="false"
                              [sortAlphabetically]="true"
                              [usePadding]="false">
              </app-modulgroup>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div *ngIf="group.bezeichnung === 'Fachergänzung'"
         [style.border]="modulgroupService.defineModulgroupBorder(group, '#2094EC')"
         class="optometrie-22-fachergaenzung-container">
      <app-status-bubble
        [hidden]="!(filterService.getShowFilter('Bestanden') || filterService.getShowFilter('Fehlgeschlagen'))"
        [modulGroup]="group"
        [status]="modulgroupService.calcBubbleStatus(group, '#2094EC')">
      </app-status-bubble>
      <app-modulgroup-title  [modulGroup]="group"></app-modulgroup-title>
      <app-ects [displayMinECTS]="false"
                [passedGroupECTS]="parseService.calculatePassedGroupECTS(group)"
                [minGroupECTS]="calculateMinGroupECTS(group)"
                [modulGroup]="group">
      </app-ects>
      <div style="padding: 0 10px 10px 10px; height: 100%;">
      <app-modulgroup [modulGroup]="group"
                      [showTitle]="false"
                      [showECTS]="false"
                      [aligningBottom]="false"
                      [sortAlphabeticallyReverse]="true"
                      [usePadding]="false"></app-modulgroup>
      </div>
    </div>
    <div *ngIf="group.bezeichnung === 'Kontext'" class="optometrie-22-modulteppich-kontext-container"
         [style.border]="modulgroupService.defineModulgroupBorder(group, '#8E4FD0')">
      <app-status-bubble
        [hidden]="!(filterService.getShowFilter('Bestanden') || filterService.getShowFilter('Fehlgeschlagen'))"
        [modulGroup]="group"
        [status]="modulgroupService.calcBubbleStatus(group, '#2094EC')">
      </app-status-bubble>
      <app-modulgroup-title [modulGroup]="group"></app-modulgroup-title>
      <app-ects [displayMinECTS]="false"
                [passedGroupECTS]="parseService.calculatePassedGroupECTS(group)"
                [minGroupECTS]="calculateMinGroupECTS(group)"
                [modulGroup]="group">
      </app-ects>
      <div style="display: flex; flex-direction: column; row-gap: 16px;">
        <ng-container *ngFor="let subgroup of sortByPosition(group.modules!, {isDataScience: false, ascending: true})">
          <div [style.border]="modulgroupService.defineModulgroupBorder(subgroup, '#8E4FD0')"
               class="kontext-group flex-1-1-auto">
            <app-modulgroup class="modulgroup"
                            [maxHeightPixel]="200"
                            [sortAlphabeticallyReverse]="true"
                            [modulGroup]="subgroup">
            </app-modulgroup>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="optometrie-22-freie-module-container"
         style="display: flex; flex-direction: column; height: 100%; justify-content: flex-end;"
         *ngIf="group.bezeichnung === 'Freie Module' && parseService.calculatePassedGroupECTS(group) > 0">
      <div [style.border]="modulgroupService.defineModulgroupBorder(group, '#8E4FD0')" class="kontext-group">
        <app-modulgroup class="modulgroup"
                        [optometrie]="true"
                        [spaceBetween]="true"
                        [sortByStatus]="true"
                        [modulGroup]="group">
        </app-modulgroup>
      </div>
    </div>
  </ng-container>
</div>
