import {Component, Input, OnChanges} from '@angular/core';
import {FilterService} from '../services/filter.service';
import {LocalStorageService} from '../services/local-storage.service';
import {FilterConfigEntry} from '../models/FilterConfigEntry';
import {ParseService} from '../services/parse.service';
import {Studiengang} from '../models/Studiengaenge';

@Component({
  selector: 'app-filter-attribute',
  templateUrl: './filter-attribute.component.html',
  styleUrls: ['./filter-attribute.component.scss']
})
export class FilterAttributeComponent implements OnChanges {

  @Input() filterName!: string;
  @Input() legend?: string;
  @Input() showCircleRunning?: boolean;
  @Input() showCircleFailed?: boolean;
  @Input() showCirclePassed?: boolean;

  studiengang = Studiengang;
  currentFilter?: FilterConfigEntry;

  constructor(
    public parseService: ParseService,
    public filterService: FilterService,
    private localStorageService: LocalStorageService
  ) {
  }

  ngOnChanges(): void {
    this.currentFilter = this.filterService.config.filters.get(this.filterName);
  }

  displayFilterForStudiengang(): boolean {
    return (this.currentFilter?.type.includes(this.studiengang.NoMatch) || this.currentFilter?.type.includes(this.parseService.getStudiengang())) ?? false;
  }

  toggleFilter(): void {
    const filterState = this.currentFilter?.toggle();
    // Turns off other mutual exclusive filters
    if (this.currentFilter?.isMutualExclusive && filterState) {
      Array.from(this.filterService.config.filters)
        .filter(filter => filter[1].isMutualExclusive && filter[0] !== this.filterName)
        .forEach(filter => this.filterService.config.filters.get(filter[0])?.turnOff());
      this.localStorageService.config.next(this.filterService.config);
    }
    // For DS only
    if (this.filterName === 'Noten Runden' && filterState) {
      this.filterService.config.filters.get('Noten')?.turnOn();
    } else if (this.filterName === 'Noten' && !filterState) {
      this.filterService.config.filters.get('Noten Runden')?.turnOff();
    }
    this.localStorageService.config.next(this.filterService.config);
  }
}
