<img class="onboarding-slide noselect" alt="slide" *ngFor="let image of slides; let i = index"
     [src]="image.src"
     [ngClass]="{'slide-selected': selectedSlideIndex === i}">
<div class="dot-container">
    <span class="dot" *ngFor="let dot of slides;let i = index"
          [ngClass]="{'dot-selected': selectedSlideIndex === i}">
    </span>
</div>
<div class="button-carousel button-next"
     [ngClass]="{
        'icon-expand-dark': themeService.isDark(),
        'icon-expand-light': !themeService.isDark()}"
     (click)="nextSlide()">
</div>
<div *ngIf="selectedSlideIndex > 0" class="button-carousel button-prev"
     [ngClass]="{
        'icon-expand-dark': themeService.isDark(),
        'icon-expand-light': !themeService.isDark()}"
     (click)="prevSlide()">
</div>
