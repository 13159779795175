<div class="side-nav" (click)="stop($event)" [style.top.px]="topOffset" [ngClass]="{
    'side-nav-collapsed': !isSideNavigationExpanded,
    'side-nav-expanded': isSideNavigationExpanded}">
  <div #sideNavContent #stickyMenu class="sticky">
      <div class="contentWrapper" *ngIf="groupDetails">
        <div class="sidenav-right-header">
          <div class="groupType" [style.color]="typeColor">{{ groupType }}</div>
          <div class="groupName">{{ groupDetails.bezeichnung }}</div>
          <div class="nModules">{{ sortByStatus(groupDetails.modules!).length}} Module</div>
          <input placeholder="Filter" class="search sidenav-right-search" #search [(ngModel)]="filter" type="search"/>
        </div>
        <div class="moduleWrapper">
          <div
            [ngClass]="[shouldDisplayMinorModule(module) ? 'opacity-1':'opacity-02']"
            [style.background-color]="setSelectedSidenavRightBackgroundColor(module)"
            *ngFor="let module of sortStuff(sortByStatus(groupDetails.modules!)) ; let i = index"
            class="forWrapper">
            <div class="module sidenav-right-module"
                 *ngIf="module.bezeichnung!.toLowerCase().includes(filter.toLowerCase()) ||
                 (module.modules![0] && module.modules![0].abbreviation!.toLowerCase().includes(filter.toLowerCase()))"
                 (click)="showModuleDetails($event, module)">
              <div class="moduleTitle">{{ module.bezeichnung }}</div>
              <div class="statusWrapper">
                <ng-container *ngFor="let attempt of module.modules![0].attempts">
                  <div *ngIf="modulService.getStatusClass(undefined, attempt) !== ''"
                       class="statusDot"
                       [ngClass]="[modulService.getStatusClass(undefined, attempt)]"
                  ></div>
                </ng-container>
                <div *ngIf="filterService.getShowFilter('ECTS')">
                  <span>{{module.modules![0] && module!.modules![0]!.modul!.ects}}</span>
                  <span>&nbsp;ECTS</span>
                </div>
                <div *ngIf="filterService.getShowFilter('Noten')">{{showGradeOrCredited(module)}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</div>
