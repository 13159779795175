import { Component } from '@angular/core';
import {Studiengang} from "../../models/Studiengaenge";
import {ParseService} from "../../services/parse.service";

@Component({
  selector: 'app-sidenav-study-progress',
  templateUrl: './sidenav-study-progress.component.html',
  styleUrl: './sidenav-study-progress.component.scss'
})
export class SidenavStudyProgressComponent {

  public minBachelorECTS = 180;
  public minAssessmentECTS = 27;
  public maxFailedECTS = 60;
  public angerechneteECTS = this.parseService.getAngerechneteModule().reduce((sum, a) => sum + a.modul?.ects!, 0);
  public angerechneteModule = this.parseService.getAngerechneteModule().length;
  public pauschalAnrechnungen = this.parseService.pauschalAnrechnungsECTS;


  constructor(
    public parseService: ParseService
  ) {
  }

  protected readonly studiengang = Studiengang;
}
