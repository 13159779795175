
import {DefaultEnabledFilters} from '../config/DefaultEnabledFilters';
import {Studiengang} from './Studiengaenge';

export class FilterConfigEntry {

  name = '';
  type = [Studiengang.NoMatch];
  on = false;
  category = '';
  showCirclePassed = false;
  showCircleFailed = false;
  showCircleRunning = false;
  legend = '';
  isMutualExclusive: boolean;
  defaultEnabledFilters = DefaultEnabledFilters;

  constructor(name: string, type: Array<Studiengang>, category: string, showCirclePassed: boolean, showCircleFailed: boolean,
              showCircleRunning: boolean, legend: string, isMutualExclusive: boolean) {
    this.name = name;
    this.type = type;
    this.category = category;
    this.showCirclePassed = showCirclePassed;
    this.showCircleFailed = showCircleFailed;
    this.showCircleRunning = showCircleRunning;
    this.legend = legend;
    this.isMutualExclusive = isMutualExclusive;
    this.on = this.defaultEnabledFilters.includes(name);
  }

  toggle = () => {
    this.on = !this.on;
    return this.on;
  }

  turnOff = () => {
    this.on = false;
  }

  turnOn = () => {
    this.on = true;
  }
}
