<div class="header-search">
  <img alt="search" src="../../../../assets/icons/search.svg">
  <input #input
         placeholder="Suche nach Modul ..."
         class="search"
         [style.background-color]="themeService.isDark() ?
           'var(--primary-background-color)' :
            'var(--secondary-background-color)'"
         [value]="v2moduleService.searchInput"
         (input)="searchModule($event)"
         type="search">
  <span *ngIf="input.value.length > 0"
        class="search-clear-button pointer"
        (click)="clearSearch()">x</span>
</div>
