import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-ruler',
  templateUrl: './ruler.component.html',
  styleUrls: ['./ruler.component.scss']
})
export class RulerComponent implements OnInit {

  constructor() {
  }

  @Input() maxSemesterECTS!: number;
  @Output() gridSize: EventEmitter<number> = new EventEmitter<number>();
  @Input() height!: number;

  ngOnInit(): void {
  }

  getMaxSemesterECTS(amountSemesterECTS: number): Array<number> {
    if (amountSemesterECTS % 3 === 1) {
      amountSemesterECTS += 2;
    } else if (amountSemesterECTS % 3 === 2) {
      amountSemesterECTS += 1;
    }
    if (amountSemesterECTS <= 33) {
      amountSemesterECTS = 33;
    }
    this.gridSize.emit(amountSemesterECTS);
    return Array(amountSemesterECTS + 1).fill(0).map((x, i) => i);
  }

  calculateGridSize(): Array<number> {
    return Array(this.maxSemesterECTS <= 33 ? 33 : this.maxSemesterECTS).fill(0).map((x, i) => i);
  }

  calculateGrid(amountSemesterECTS: number): string {
    if (amountSemesterECTS % 3 === 1) {
      amountSemesterECTS += 2;
    } else if (amountSemesterECTS % 3 === 2) {
      amountSemesterECTS += 1;
    }
    if (amountSemesterECTS <= 33) {
      amountSemesterECTS = 33;
    }
    amountSemesterECTS += 1;
    return 'repeat(' + amountSemesterECTS + ', 33.5px)';
  }
}
