<div [ngClass]="isExpanded ? 'expand-dropdown' : 'collapse-dropdown'">
  <div class="outer-triangle"  style="top: 8px">
    <div class="inner-triangle"></div>
  </div>
  <div class="dropdown-container" style="top: 20px" (click)="stopEventPropagation($event)">
    <ng-container *ngFor="let jahrgang of parseService.getStudentStudienJahrgaenge(); let i = index">
      <div class="studiengang dropdown-padding" (click)="selectStudienJahrgang(jahrgang)">
        {{jahrgang.bezeichnung}}
      </div>
    </ng-container>
  </div>
</div>
