<ng-container *ngFor="let semester of semesterModuleArray">
  <div class="section-space">
    <span>{{semester.semester.abbreviation | semesterPipe}}</span>
    <app-semester-ects-status [semester]="semester.semester"></app-semester-ects-status>
    <span *ngIf="filterService.getShowFilter('Noten')" class="average-semster-grade">
      Semesterschnitt: {{calcAccurateAverage(semester.semester.modules)}}
    </span>
  </div>
  <div class="semester-modules" [style.grid-template-columns]="'repeat(' + gridSize + ', 33.5px)'">
    <ng-container *ngFor="let attempt of semester.semesterArray">
      <app-modul [ngClass]="calcModuleWidth(parseService.currentView.getValue(), attempt[0])"
                 [attempt]="attempt[1]"
                 [module]="attempt[0]"
                 [timeline]="true">
      </app-modul>
    </ng-container>
  </div>
</ng-container>
