import {Component, Input, OnInit} from '@angular/core';
import {IModul} from '../models/Modul';

@Component({
  selector: 'app-gruppenanrechnung',
  templateUrl: './gruppenanrechnung.component.html',
  styleUrls: ['./gruppenanrechnung.component.scss']
})
export class GruppenanrechnungComponent implements OnInit {

  constructor() {
  }

  @Input() group!: IModul;

  ngOnInit(): void {
  }
}
