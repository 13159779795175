import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {IModul} from '../models/Modul';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  public currentSelectedModule!: BehaviorSubject<Array<IModul>>;
  public isExpanded!: BehaviorSubject<boolean>;
  public topOffset!: BehaviorSubject<number>;

  constructor() {
    this.currentSelectedModule = new BehaviorSubject<Array<IModul>>([]);
    this.isExpanded = new BehaviorSubject<boolean>(false);
    this.topOffset = new BehaviorSubject<number>(141);
  }

  getSubjectSelectedModule(): BehaviorSubject<Array<IModul>> {
    return this.currentSelectedModule;
  }

  getSelectedModules(): Array<IModul> {
    return this.currentSelectedModule.getValue();
  }

  setSelectedModule(value: Array<IModul>): void {
    this.currentSelectedModule.next(value);
  }

  clearSelectedModule(): void {
    this.currentSelectedModule.next([]);
  }

  getSubjectExpanded(): BehaviorSubject<boolean> {
    return this.isExpanded;
  }

  setIsExpanded(value: boolean): void {
    this.isExpanded.next(value);
  }
}
