import {Component} from '@angular/core';
import {ThemeService} from '../services/theme.service';

@Component({
  selector: 'app-light-chain',
  templateUrl: './light-chain.component.html',
  styleUrls: ['./light-chain.component.scss']
})
export class LightChainComponent {

  constructor(
    // ThemeService is being used in the view
    public themeService: ThemeService
  ) {}
}
