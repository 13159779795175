import {AfterViewChecked, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ModulteppichDirective} from '../modulteppich.directive';
import {IModul, Modul} from '../../../models/Modul';
import {SidenavRightService} from 'src/app/services/sidenav-right.service';
import {ModulService} from '../../../services/modul.service';
import {FilterService} from '../../../services/filter.service';
import {ParseService} from '../../../services/parse.service';
import {ModulgroupService} from '../../../services/modulgroup.service';
import {ProfilierungService} from "../../../services/profilierung.service";

@Component({
  selector: 'app-modulteppich-informatik-2018',
  templateUrl: './modulteppich-informatik-2018.component.html',
  styleUrls: ['../modulteppich.component.scss'],
})
export class ModulteppichInformatik2018Component extends ModulteppichDirective implements OnInit, AfterViewChecked {

  @ViewChild('fachausbildung') fachausbildung?: ElementRef;
  public contHeight = '';
  public modulTree: Array<IModul> = [];
  sortByPosition = ParseService.sortByPosition;
  calculateMinGroupECTS = ParseService.calculateMinGroupECTS;

  constructor(
    sideNavRightService: SidenavRightService,
    parseService: ParseService,
    modulService: ModulService,
    filterService: FilterService,
    profilierungService: ProfilierungService,
    public modulgroupService: ModulgroupService
  ) {
    super(sideNavRightService, parseService, modulService, filterService, profilierungService);
  }

  ngOnInit(): void {
    this.modulTree = this.parseService.getModuleTree();
  }

  ngAfterViewChecked(): void {
    this.defineHeight();
  }

  private defineHeight(): void {
    this.contHeight = this.fachausbildung?.nativeElement.offsetHeight;
  }

  columnSpan(module: Modul): string {
    if (module.modules![0]?.abbreviation?.includes('wosm')) {
      return '1 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('itfs')) {
      return '1 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('netsi')) {
      return '1 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('cpnet')) {
      return '1 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('cysL')) {
      return '1 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('pcls')) {
      return '1 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('devops')) {
      return '1 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('woweb')) {
      return '3 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('ddm')) {
      return '3 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('apsi')) {
      return '3 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('apm')) {
      return '3 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('eaf')) {
      return '3 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('webfr')) {
      return '3 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('webcl')) {
      return '3 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('wods')) {
      return '5 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('ml')) {
      return '5 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('pac')) {
      return '5 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('nlp')) {
      return '5 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('wosc')) {
      return '7 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('simag')) {
      return '7 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('exr')) {
      return '7 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('comgr')) {
      return '7 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('bverI')) {
      return '6 / span 2';
    } else if (module.modules![0]?.abbreviation?.includes('efalg')) {
      return '6 / span 2';
    } else {
      return '';
    }
  }

  rowSpan(module: Modul): string {
    if (module.modules![0]?.abbreviation?.includes('wosm')) {
      return '1 / 2';
    } else if (module.modules![0]?.abbreviation?.includes('itfs')) {
      return '2 / 3';
    } else if (module.modules![0]?.abbreviation?.includes('netsi')) {
      return '3 / 4';
    } else if (module.modules![0]?.abbreviation?.includes('cpnet')) {
      return '4 / 5';
    } else if (module.modules![0]?.abbreviation?.includes('cysL')) {
      return '5 / 6';
    } else if (module.modules![0]?.abbreviation?.includes('pcls')) {
      return '6 / 7';
    } else if (module.modules![0]?.abbreviation?.includes('devops')) {
      return '7 / 8';
    } else if (module.modules![0]?.abbreviation?.includes('woweb')) {
      return '1 / 1';
    } else if (module.modules![0]?.abbreviation?.includes('ddm')) {
      return '2 / 3';
    } else if (module.modules![0]?.abbreviation?.includes('apsi')) {
      return '3 / 4';
    } else if (module.modules![0]?.abbreviation?.includes('apm')) {
      return '4 / 5';
    } else if (module.modules![0]?.abbreviation?.includes('eaf')) {
      return '5 / 6';
    } else if (module.modules![0]?.abbreviation?.includes('webfr')) {
      return '6 / 7';
    } else if (module.modules![0]?.abbreviation?.includes('webcl')) {
      return '7 / 8';
    } else if (module.modules![0]?.abbreviation?.includes('wods')) {
      return '1 / 2';
    } else if (module.modules![0]?.abbreviation?.includes('ml')) {
      return '2 / 3';
    } else if (module.modules![0]?.abbreviation?.includes('pac')) {
      return '3 / 4';
    } else if (module.modules![0]?.abbreviation?.includes('nlp')) {
      return '6 / 6';
    } else if (module.modules![0]?.abbreviation?.includes('wosc')) {
      return '1 / 2';
    } else if (module.modules![0]?.abbreviation?.includes('simag')) {
      return '2 / 3';
    } else if (module.modules![0]?.abbreviation?.includes('exr')) {
      return '3 / 4';
    } else if (module.modules![0]?.abbreviation?.includes('comgr')) {
      return '6 / 6';
    } else if (module.modules![0]?.abbreviation?.includes('bverI')) {
      return '4 / 5';
    } else if (module.modules![0]?.abbreviation?.includes('efalg')) {
      return '5 / 6';
    } else {
      return '';
    }
  }
}
