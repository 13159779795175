import {AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {IModul} from '../models/Modul';
import {ModulService} from '../services/modul.service';
import {FilterService} from '../services/filter.service';
import {SidenavRightService} from '../services/sidenav-right.service';
import {ParseService} from '../services/parse.service';
import {ModulgroupService} from '../services/modulgroup.service';

@Component({
  selector: 'app-modulgroup',
  templateUrl: './modulgroup.component.html',
  styleUrls: ['./modulgroup.component.scss']
})
export class ModulgroupComponent implements OnChanges, AfterViewInit {

  @Input() modulGroup!: IModul;
  @Input() maxElements?: number;
  @Input() maxHeightPixel?: number;
  @Input() heightPixel?: number;
  @Input() isProject?: boolean;
  @Input() sortByPosition?: boolean;
  @Input() sortByAssessment?: boolean;
  @Input() sortByStatus?: boolean;
  @Input() sortByECTS?: boolean;
  @Input() sortAlphabetically?: boolean;
  @Input() sortAlphabeticallyReverse?: boolean;
  @Input() spaceBetween?: boolean;
  @Input() aligningBottom?: boolean;
  @Input() orderColWise?: boolean;
  @Input() orderRowWise ? = false;
  @Input() usePadding = true;
  @Input() numberOfColumns?: number;
  @Input() showModules = true;
  @Input() showMoreModulesButtonHeightECTS?: number;
  @Input() showTitle = true;
  @Input() showECTS = true;
  @Input() showStatusBubble = true;
  @Input() showBorder = true;
  @Input() wrap ? = false;

  @Input() optometrie = false;

  public showMoreModuleHeight = '';

  @ViewChild('projectGrid') projectGrid?: ElementRef;

  calculateMinGroupECTS = ParseService.calculateMinGroupECTS;

  constructor(
    public parseService: ParseService,
    public filterService: FilterService,
    public modulService: ModulService,
    public sideNavRightService: SidenavRightService,
    public modulgroupService: ModulgroupService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isProject && this.isProject) {
      this.modulGroup.modules = this.modulgroupService.mapProject(this.modulGroup.modules!);
    } else if (changes.sortByPosition && this.sortByPosition) {
      if (changes.sortByAssessment && this.sortByAssessment) {
        this.modulGroup.modules = ParseService.sortByAssessment(ParseService.sortByPosition(this.modulGroup.modules!));
      } else {
        this.modulGroup.modules = ParseService.sortByPosition(this.modulGroup.modules!);
      }
    } else if (changes.sortAlphabetically && this.sortAlphabetically) {
      this.modulGroup.modules = ParseService.sortAlphabetically(this.modulGroup.modules!);
    } else if (changes.sortAlphabeticallyReverse && this.sortAlphabeticallyReverse) {
      this.modulGroup.modules = ParseService.sortAlphabeticallyReverse(this.modulGroup.modules!);
    }  else if (changes.sortByAssessment && this.sortByAssessment) {
      this.modulGroup.modules = ParseService.sortByAssessment(this.modulGroup.modules!);
    } else if (changes.sortByStatus && this.sortByStatus) {
      this.modulGroup.modules = ParseService.sortByStatus(this.modulGroup.modules!);
    } else if (changes.sortByECTS && this.sortByECTS) {
      this.modulGroup.modules = ParseService.sortByECTS(this.modulGroup.modules!);
    }
    this.showMoreModuleHeight = '36px';
  }

  ngAfterViewInit(): void {
    if (this.maxElements && this.maxHeightPixel && this.projectGrid?.nativeElement.offsetHeight > this.maxHeightPixel) {
      this.maxElements--;
    }
  }
}
