import {Injectable} from '@angular/core';
import { Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {V2Student} from '../models/V2Student'
import {environment} from "../../../environments/environment";
import {convertToAttemptStatus} from "../v2utils";
import {map} from "rxjs/internal/operators";

@Injectable({
  providedIn: 'root'
})
export class v2ApiService {

  private BaseURL = (window as any).Cypress ? 'http://localhost:3000/' :environment.apiUrlV2;

  constructor(
    private http: HttpClient
  ) { }

  fetchStudent(): Observable<V2Student> {
    const url = `${this.BaseURL}students/me`;
    return this.http.get<V2Student>(url).pipe(
      map(student => {
        student.attempts = student.attempts.map(attempt => ({
          ...attempt,
          status: convertToAttemptStatus(attempt.status  as unknown as  string)
        }));
        return student;
      })

    );
  }
}
