import {ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SidenavService} from '../../services/sidenav.service';
import {ParseService} from '../../services/parse.service';
import {Subscription} from 'rxjs';
import {FilterService} from '../../services/filter.service';
import * as FilterConfig from '../../config/FilterConfig.json';
import {FilterCategory} from '../../config/FilterCategory';
import {Studiengang} from '../../models/Studiengaenge';
import {NavigationService} from "../../services/navigation.service";
import {ThemeService} from "../../services/theme.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnChanges, OnDestroy {

  private subscriptions: Subscription[] = [];
  public studiengang = Studiengang;

  public filterCategories: Set<FilterCategory> = new Set<FilterCategory>();

  @ViewChild('filterMenu') filterMenu!: ElementRef;

  public isFilterDropdownExpanded = false;
  public isStudiengaengeExpanded = false;

  constructor(
    public parseService: ParseService,
    public sidenavService: SidenavService,
    private changeDetectorRef: ChangeDetectorRef,
    public filterService: FilterService,
    public navigationService: NavigationService,
    public themeService: ThemeService,
    public authService: AuthService
  ) {
  }
  toggleFilterSettingDropdown(event: MouseEvent): void {
    this.navigationService.setIsFilterSettingsExpanded(
      !this.isFilterDropdownExpanded
    );
    event.stopPropagation();
  }

  toggleStudiengangDropdown(event: MouseEvent): void {
    if (this.parseService.getStudentStudienJahrgaenge() && this.parseService.getStudentStudienJahrgaenge().length > 1) {
      this.navigationService.setIsStudiengaengeExpanded(
        !this.isStudiengaengeExpanded
      );
      event.stopPropagation();
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.navigationService
        .getSubjectFilterSettingsExpanded()
        .subscribe((value: boolean) => {
          this.isFilterDropdownExpanded = value;
        })
    );
    this.subscriptions.push(
      this.navigationService
        .getSubjectStudiengaengeExpanded()
        .subscribe((value: boolean) => {
          this.isStudiengaengeExpanded = value;
        })
    );
    (FilterConfig as any).default.forEach((category: FilterCategory) => this.filterCategories.add(category));
  }

  asIsOrder = (a: any, b: any) => {
    return 1;
  }

  isInCurrentStudiengang = (category: FilterCategory) => {
    return category.filters.some(filter => filter.studiengaenge.includes(Studiengang.NoMatch) || filter.studiengaenge.includes(this.parseService.getStudiengang()));
  }

  ngOnChanges(changes: any): void {
    if (changes.isFilterDropdownExpanded) {
      this.isFilterDropdownExpanded = changes.isFilterDropdownExpanded.currentValue;
    }
    if (changes.isFilterDropdownExpanded) {
      this.isStudiengaengeExpanded = changes.isStudiengaengeExpanded.currentValue;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  stopEventPropagation(event: any): void {
    if (event instanceof MouseEvent) {
      event.stopPropagation();
    }
  }
}
