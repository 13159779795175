import {AfterViewChecked, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ModulteppichDirective} from '../modulteppich.directive';
import {SidenavRightService} from 'src/app/services/sidenav-right.service';
import {ModulService} from '../../../services/modul.service';
import {FilterService} from '../../../services/filter.service';
import {ParseService} from '../../../services/parse.service';
import {ModulgroupService} from '../../../services/modulgroup.service';
import {IModul, Modul} from '../../../models/Modul';

@Component({
  selector: 'app-modulteppich-optometrie-2021',
  templateUrl: './modulteppich-optometrie-2021.component.html',
  styleUrls: ['../modulteppich.component.scss', './modulteppich-optometrie-2021.component.scss'],
})
export class ModulteppichOptometrie2021Component extends ModulteppichDirective implements OnInit, AfterViewChecked {

  @ViewChild('fachausbildung') fachausbildung?: ElementRef;
  public contHeight = '';
  public modulTree: Array<IModul> = [];
  sortByPosition = ParseService.sortByPosition;
  calculateMinGroupECTS = ParseService.calculateMinGroupECTS;

  static flattenModuleGroup(modules: Modul[]): Modul[] {
    return modules.flatMap((g: Modul) => g.modules ?? []);
  }

  static sortDualGroupByAbbr(module: Modul): Modul {
    return {
      ...module,
      modules: module.modules?.sort((a, b) => a.modules![0].abbreviation!.localeCompare(b.modules![0].abbreviation!))
    };
  }

  constructor(
    sideNavRightService: SidenavRightService,
    parseService: ParseService,
    modulService: ModulService,
    filterService: FilterService,
    public modulgroupService: ModulgroupService
  ) {
    super(sideNavRightService, parseService, modulService, filterService);

    this.modulTree = this.parseService.getModuleTree();
    const technicalTraining = this.modulTree.find(cat => 'Fachausbildung' === cat.bezeichnung);
    if (technicalTraining) {
      technicalTraining.modules = [
        technicalTraining.modules?.find(subcat => 'Fachergänzung' === subcat.bezeichnung),
        technicalTraining.modules?.find(subcat => 'Fachgrundlagen' === subcat.bezeichnung)
      ].filter((m): m is Modul => !!m);
    }
  }

  ngOnInit(): void {
    const baseGroup = this.modulTree
      .find(cat => 'Fachausbildung' === cat.bezeichnung)?.modules
      ?.find(subcat => 'Fachgrundlagen' === subcat.bezeichnung);
    if (undefined !== baseGroup) {
      baseGroup.modules?.forEach(m => {
        m.modules = m.modules?.map(ModulteppichOptometrie2021Component.sortDualGroupByAbbr) ?? [];
        m.modules = ParseService.sortByStatus(m.modules ?? []);
        m.modules = m.modules ?
          ModulteppichOptometrie2021Component.flattenModuleGroup(m.modules) :
          [];
      });
    }
    const supplementGroup = this.modulTree
      .find(cat => 'Fachausbildung' === cat.bezeichnung)?.modules
      ?.find(subcat => 'Fachergänzung' === subcat.bezeichnung);
    if (undefined !== supplementGroup) {
      supplementGroup.modules = supplementGroup.modules ?
        ModulteppichOptometrie2021Component.flattenModuleGroup(supplementGroup.modules) :
        [];
    }
  }

  ngAfterViewChecked(): void {
    this.defineHeight();
  }

  private defineHeight(): void {
    this.contHeight = this.fachausbildung?.nativeElement.offsetHeight;
  }
}
