import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {IModul} from '../models/Modul';
import {FilterService} from '../services/filter.service';
import {ModulService} from '../services/modul.service';
import {Anmeldung} from '../models/Anmeldung';
import {NavigationService} from '../services/navigation.service';
import {ParseService} from '../services/parse.service';
import {Attempt} from '../models/Attempt';
import {Modulstatus} from '../models/Modulstatus';
import {SidenavService} from '../services/sidenav.service';
import {Studiengang} from '../models/Studiengaenge';
import {V2Module} from "../v2/models/V2Module";

@Component({
  selector: 'app-modul',
  templateUrl: './modul.component.html',
  styleUrls: ['./modul.component.scss']
})
export class ModulComponent implements OnChanges {

  @Input() module?: IModul;
  @Input() anmeldung?: Anmeldung;
  @Input() timeline?: boolean;
  @Input() attempt?: Attempt;
  @Input() title?: string;
  @Input() isProject?: boolean;
  @Input() isInSidenav = false;
  public moduleHeight = '';

  constructor(
    public parseService: ParseService,
    public filterService: FilterService,
    public moduleService: ModulService,
    public naviService: NavigationService,
    public sidenavService: SidenavService
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.module) {
      this.moduleHeight = ModulService.calcModuleHeight(this.module!, this.timeline, this.isProject) + 'px';
      this.module = this.parseModul(this.module!);
    }
  }

  private parseModul(modul: IModul): IModul {
    if (this.parseService.getStudiengang() === Studiengang.DS) {
      return modul.modules && modul!.modules![0] ? modul!.modules![0] : modul!;
    } else {
      if (this.naviService.getCurrentNavigation().includes('timeline')) {
        return modul!;
      } else {
        if (modul.modules && modul!.modules![0]) {
          return modul!.modules![0];
        } else {
          return modul;
        }
      }
    }
  }

  public displayAbbreviation(modul: IModul): string {
    if (modul!.abbreviation) {
      return modul!.abbreviation;
    } else if (modul.modules?.length! > 0 && modul.modules![0].abbreviation) {
      return modul!.modules![0].abbreviation!;
    } else {
      return '';
    }
  }

  public displayTriangle(modul: IModul): boolean {
    if (!this.naviService.getCurrentNavigation().includes('timeline')) {
      if (this.filterService.getShowFilter('Fehlgeschlagen')) {
        return (modul.attempts?.length! > 1 && modul.attempts?.some(a => a.status === Modulstatus.FAILED) && [Modulstatus.RUNNING, Modulstatus.PASSED].includes(modul.attempts![0].status!))!;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  highlightModule(): boolean {
    let val = true;
    if (this.sidenavService.getSelectedModules().length > 0) {
      val = this.isDependingModuleOfSelectedModule();
    } else {
      if (this.moduleService.searchInput.length > 0) {
        val = this.modulIsInSearch();
      } else {
        val = this.moduleService.displayTypes(this.module!);
      }
    }
    return val;
  }

  isDependingModuleOfSelectedModule(): boolean {
    return this.sidenavService.getSelectedModules().includes(this.module!);
  }

  modulIsInSearch(): boolean {
    return this.moduleService.searchInput.length > 0 ? this.moduleService.modulSearchArray.getValue().map(m => m.abbreviation).includes(this.module.abbreviation!) : true;
  }
}
